import React, { useRef, useState } from "react"
import styled from "styled-components"
import { SideRailResizer } from "./SideRailResizer"
import {
    InitialSideRailWidth,
    StyledOverlay,
    StyledSideRailFooter,
    StyledSideRail,
    StyledSideRailHeader,
    StyledSideRailContent,
    StyledSideRailInner,
} from "./style"
import { SideRailProps } from "./types"

const SideRail = (props: SideRailProps): JSX.Element => {
    const {
        applyButtonOnClick,
        applyButtonText,
        cancelButtonOnClick,
        cancelButtonText,
        content,
        disableApplyButton,
        disableCancelButton,
        headerComponent,
        headerIcon,
        headerIconClassName,
        headerText,
        hideApplyButton,
        isWide,
        resizeable,
    } = props

    const SideRailHeader = (
        <StyledSideRailHeader>
            {headerComponent ?? (
                <div className="default-header" id="side-rail-header-text">
                    {headerIcon ? (
                        <HeaderIconContainer>{headerIcon}</HeaderIconContainer>
                    ) : (
                        <i className={headerIconClassName} style={{ paddingRight: "5px" }} />
                    )}
                    {headerText}
                </div>
            )}
        </StyledSideRailHeader>
    )

    const sideRailRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState<number>(0)

    return (
        <>
            <StyledOverlay />
            <StyledSideRail
                ref={sideRailRef}
                initialWidthPercentage={isWide ? InitialSideRailWidth.Wide : InitialSideRailWidth.Normal}
                width={width}
                id="side-rail-wrapper"
            >
                {resizeable && <SideRailResizer setWidth={setWidth} sideRailRef={sideRailRef} />}
                <StyledSideRailInner>
                    {SideRailHeader}
                    <StyledSideRailContent>{content}</StyledSideRailContent>
                    <StyledSideRailFooter>
                        {!hideApplyButton && (
                            <button
                                className="apply"
                                id="apply-button"
                                onClick={applyButtonOnClick}
                                disabled={disableApplyButton}
                                type="button"
                            >
                                {applyButtonText ?? "Apply"}
                            </button>
                        )}
                        <button
                            id="cancel-button"
                            onClick={cancelButtonOnClick}
                            type="button"
                            disabled={disableCancelButton}
                        >
                            {cancelButtonText ?? "Cancel"}
                        </button>
                    </StyledSideRailFooter>
                </StyledSideRailInner>
            </StyledSideRail>
        </>
    )
}

const HeaderIconContainer = styled.div`
    width: 20px;
    margin-right: 8px;
    display: flex;
`

export default SideRail
