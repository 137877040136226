import {
    employeeRoleMap,
    EMPLOYEE_MAIN_GROUP_ASSIGNMENT,
    EMPLOYEE_ROLES,
    EMPLOYEE_ROLE_DEFAULT_OPTION,
    notificationPreferences,
} from "./constants"
import { JsonPointer as jsonpointer } from "json-ptr"
import { setHours, add } from "date-fns"
import { getAsDate } from "./ts-utils"

export const dateAndDefaultTimeValueSetter = params => {
    if (params.oldValue === params.newValue) return false
    else if (!params.newValue) return true

    jsonPointerValueSetter(params)
    // hack to add the start time and end time for now.
    const newTime = getAsDate(params.newValue)
    const localTime = setHours(newTime, 7)
    params.data["shift_start_time"] = localTime.toISOString()
    params.data["shift_end_time"] = add(localTime, { hours: 8 }).toISOString()
    return true
}

export const schemaFromProjectValueSetter = params => {
    if (params.oldValue === params.newValue) return false

    // if the newValue is actually a schema, just set to that. This is required since
    // we don't have params.context when the default is set.
    params.data.schema = params.newValue.schema
        ? params.newValue
        : Object.values(params.context.referenceableData.employeeSchemas).find(
              ele => ele.project.id === params.newValue.id && ele.name === params.colDef.cellEditorParams.schemaName
          )

    return true
}

export const phoneValueSetter = params => {
    const cleanedPhone = params.newValue?.replace(/[^0-9]/g, "").replace(/^1/, "")
    jsonPointerValueSetter({ ...params, newValue: cleanedPhone })
}

export const employeeRoleValueSetter = params => {
    const { newValue: intendedValue } = params

    /**
     * AG Grid will pass in the previous cell value if the user opens the select menu and then clicks or tabs away.
     * The previous value will have already been mapped by this setter; we don't want to attempt to map it again.
     * e.g. 'WORKER' is the selected role, user opens the select and tabs away,
     *      AG Grid attempts to reset 'WORKER' as the value, 'WORKER' maps to 'undefined'
     */
    const newValue = EMPLOYEE_ROLES.has(intendedValue)
        ? intendedValue
        : employeeRoleMap.get(intendedValue) ?? employeeRoleMap.get(EMPLOYEE_ROLE_DEFAULT_OPTION)

    jsonPointerValueSetter({ ...params, newValue })
}

// For non-GLO customers. Take the enums used in the dropdown and translate them to the Groups
// referenceables that can be assigned to this employee
export const employeeMainGroupAvailableValueSetter = params => {
    let group = []

    // Right now, employees can only be assigned to Main, or to no groups
    if (params["newValue"] === EMPLOYEE_MAIN_GROUP_ASSIGNMENT) {
        const mainGroup = params.context?.referenceableData?.companyGroups
            ? Object.entries(params.context.referenceableData.companyGroups).find(
                  ([, value]) => value.name === "Main"
              )
            : null
        if (mainGroup) {
            // Pare this down to the ID and Name fields, to match what's available in oldValue.
            // This way, if the user ends up selecting the option they already had, the oldValue
            // and newValue will match and the grid won't trigger a useless save
            group = [{ id: mainGroup[1].id, name: mainGroup[1].name }]
        }
    }

    jsonPointerValueSetter({ ...params, newValue: group })

    return true
}

export const jsonPointerValueSetter = params => {
    if (params.oldValue === params.newValue) return false

    const forceCreate = true
    jsonpointer.set(params.data, params.colDef.field, params.newValue, forceCreate)
    return true
}

export const tmEmailPreferenceValueSetter = params => {
    if (
        params.oldValue === params.newValue ||
        (params.oldValue == false && params.newValue === notificationPreferences.OPT_OUT) ||
        (params.oldValue == true && params.newValue === notificationPreferences.OPT_IN)
    ) {
        return false
    }

    const forceCreate = true
    const value = params.newValue === notificationPreferences.OPT_IN
    jsonpointer.set(params.data, params.colDef.field, value, forceCreate)
    return true
}

export const subcontractorValueSetter = params => {
    const oldValueSubs = params.oldValue
        ? new Set(
              params.oldValue.map(ci => {
                  return ci.sub_company_id
              })
          )
        : new Set()
    const newValueSubs = params.newValue
        ? new Set(
              params.newValue.map(ci => {
                  return ci.sub_company_id
              })
          )
        : new Set()
    const difference = new Set([...oldValueSubs].filter(x => !newValueSubs.has(x)))
    if (oldValueSubs.size === newValueSubs.size && difference.size === 0) {
        return false
    }
    params.data.subcontractors = Array.from(newValueSubs)
    return jsonPointerValueSetter(params)
}

export const emailValueSetter = params => {
    const cleanedEmail = params.newValue?.toLowerCase()
    jsonPointerValueSetter({ ...params, newValue: cleanedEmail })
}
