import React from "react"
import SelectorV1 from "../../../Selector"
import SelectorV3 from "../../../SelectorV3"
import SelectorWrapper from "../../../SelectorWrapper"

import { referenceableToRelatedFilters } from "../../../filters/constants"
import { isApplicableFilterValue } from "../../../filters/utils"
import { getFlagEnabled } from "../../../getFlagValue"
import { eToolbarMode } from "../../../toolbar/types"
import { getEventHandlers } from "../../../toolbar/Toolbar/utils"

import { openAddPicklistItemModal } from "../../modals/actions"

export default class ReferenceableSelectorCellEditor extends React.Component {
    constructor(props) {
        super(props)
        this.select = React.createRef()
        this.state = {
            selectedValue: props.value,
        }
        if (getFlagEnabled("WA-8168-field-added-picklist-items")) {
            this.userCanFieldAddItems = props.colDef.cellEditorParams.canFieldAddItems?.includes(
                props.context.currentUser.user_role
            )
        }
    }

    getRelatedQueryFilters = () => {
        const queryFilters = this.props.context.filters
        const refToRelated = referenceableToRelatedFilters.get(this.props.column.colDef.resourceName)
        const globalRelatedFilterList = refToRelated ? refToRelated.relatedFilters : []

        const relatedFieldExclusions = this.props.column.colDef.ignoreRelatedFilters || []
        const relatedFilterList = globalRelatedFilterList.filter(
            ele => !relatedFieldExclusions.includes(ele.filterKey)
        )

        return relatedFilterList.reduce((queryParams, relatedFilter) => {
            const invertQueryParam =
                this.props.column.colDef.invertRelatedFilters &&
                this.props.column.colDef.invertRelatedFilters.includes(relatedFilter.filterKey)
            const queryParamKey = invertQueryParam
                ? `exclude_${relatedFilter.queryParam}`
                : relatedFilter.queryParam
            return {
                ...queryParams,
                [queryParamKey]: queryFilters[relatedFilter.filterKey],
            }
        }, {})
    }

    getQueryFilters = () => {
        const queryFilters = this.props.context.filters
        if (this.props?.skipProjectFilter && getFlagEnabled("WA-8149-skip-proj-filter-ff-selectors")) {
            delete queryFilters?.projectId
        }
        const filterKey = referenceableToRelatedFilters.get(this.props.column.colDef.resourceName)?.defaultFilterKey
        const filterValue = queryFilters[filterKey]

        return isApplicableFilterValue(filterValue)
            ? { [this.props.column.colDef.filterQueryParam]: filterValue }
            : {}
    }

    getCellFiltersFromRowValue = () =>
        this.props.columnApi
            .getAllColumns()
            .filter(column => {
                const relatedFilterInfo = referenceableToRelatedFilters
                    .get(this.props.column.colDef.resourceName)
                    ?.relatedFilters.find(
                        relatedFilter => relatedFilter.queryParam === column.colDef.filterQueryParam
                    )
                const relatedFieldExclusions = this.props.column.colDef.ignoreRelatedFilters || []
                return relatedFilterInfo && !relatedFieldExclusions.includes(relatedFilterInfo.filterKey)
            })
            .reduce((filters, column) => {
                const valueGetter = column.colDef.valueGetter
                const value = valueGetter
                    ? valueGetter({
                          data: this.props.node.data,
                          colDef: column.colDef,
                          context: this.props.context,
                      })
                    : value
                const keyCreator = column.colDef.keyCreator
                const key = keyCreator ? keyCreator({ value }) : value
                return {
                    ...filters,
                    ...(key !== "undefined" ? { [column.colDef.filterQueryParam]: key } : {}),
                }
            }, {})

    getExtraCellFiltersFromRowValue = () => {
        const { column, context, node } = this.props
        const { colDef } = column
        return (
            referenceableToRelatedFilters
                .get(colDef.resourceName)
                ?.extraFilters?.filter(
                    extraFilter =>
                        colDef.extraFilterQueryParams.includes(extraFilter.queryParam) &&
                        context.groupKeyInfo.find(col => col.colDef.field === extraFilter.field)
                )
                .reduce((filters, extraFilterInfo) => {
                    const valueGetter = colDef.valueGetter
                    const value = valueGetter
                        ? valueGetter({
                              data: node.data,
                              colDef: { field: extraFilterInfo.field, resourceName: extraFilterInfo.resourceName },
                              context: context,
                          })
                        : value
                    return {
                        ...filters,
                        ...(value !== "undefined"
                            ? {
                                  [extraFilterInfo.queryParam]:
                                      typeof value === "object" && value != null ? value.id : value,
                              }
                            : {}),
                    }
                }, {}) ?? {}
        )
    }

    getGroupKeyFilters = () => {
        const groupKeyInfo = this.props.context.groupKeyInfo || []
        const field = this.props.column.colDef.field
        return groupKeyInfo
            .filter(ele => ele.colDef.filterColumnOf === field)
            .reduce((filters, keyInfoInstance) => {
                return {
                    ...filters,
                    [keyInfoInstance.colDef.filterQueryParam]: keyInfoInstance.colDef.keyCreator({
                        value: keyInfoInstance.value,
                    }),
                }
            }, {})
    }

    getSearchFiltersForCell = () => ({
        ...this.getQueryFilters(),
        ...this.getRelatedQueryFilters(),
        ...this.getCellFiltersFromRowValue(),
        ...this.getGroupKeyFilters(),
        ...this.props.column.colDef.extraSearchFilters,
        ...this.getExtraCellFiltersFromRowValue(),
    })

    stopEditing = () => {
        this.props.api.stopEditing()
        this.props.api.setFocusedCell(this.props.rowIndex, this.props.column.colId, null)
    }

    onAction = option => {
        const params = {
            ...this.props,
            value: option,
            colDef: this.props.column.colDef,
        }
        const eventHandlers = getEventHandlers(eToolbarMode.CELL, params)
        const action = eventHandlers[option.value]
        if (action) {
            if (getFlagEnabled("WA-8130-stop-editing-when-performing-selector-action")) {
                this.stopEditing()
            }
            action()
        }
    }

    onChange = selectedValue => {
        this.setState({ selectedValue }, () => {
            if (!this.props.multiselect && !this.props.column.colDef.multiselect) {
                this.stopEditing()
            }
        })
    }

    onCreateOption = value => {
        if (value && this.props.context.dispatch) {
            const costCode = this.props.data.cost_code
            const costCodeId = typeof costCode === "object" ? costCode.id : costCode
            this.props.context.dispatch(
                openAddPicklistItemModal(
                    this.props.context,
                    value,
                    this.props.colDef.extraSearchFilters.picklist,
                    this.props.colDef.headerName,
                    costCodeId,
                    this.props.node
                )
            )
        }
    }

    getValue() {
        return this.state.selectedValue ? this.state.selectedValue : this.props.default
    }

    componentDidMount() {
        this.select.current.focus()
    }

    render() {
        const colDef = this.props.column.colDef
        const cellRendererParams = colDef.cellRendererParams || {}
        const selectorToRender = () => {
            if (colDef.useSelectV3 && this.props.isDesignSystem) {
                return (
                    <SelectorWrapper
                        actionOptions={colDef.actionOptions}
                        isClearable={true}
                        isCreatable={
                            getFlagEnabled("WA-8168-field-added-picklist-items")
                                ? this.props.isCreatable && this.userCanFieldAddItems
                                : undefined
                        }
                        context={this.props.context}
                        filters={this.getSearchFiltersForCell()}
                        idsToExclude={this.props.idsToExclude}
                        innerRef={this.select}
                        isMulti={colDef.multiselect}
                        onAction={this.onAction}
                        onChange={this.onChange}
                        onCreateOption={this.onCreateOption}
                        openMenuOnFocus={this.props.openMenuOnFocus}
                        optionList={this.props.options}
                        prefixFormatter={cellRendererParams.prefixFormatter}
                        primarySubtitleFormatter={cellRendererParams.primarySubtitleFormatter}
                        resourceName={colDef.resourceName}
                        secondarySubtitleFormatter={cellRendererParams.secondarySubtitleFormatter}
                        titleFormatter={cellRendererParams.titleFormatter}
                        value={this.state.selectedValue}
                        disableInactive={this.props.disableInactive}
                        valueFormatter={colDef.valueFormatter}
                        groupHeaderFormatter={colDef.groupHeaderFormatter}
                    />
                )
            } else if (colDef.useSelectV3) {
                return (
                    <SelectorV3
                        clearable={true}
                        context={this.props.context}
                        disableInactive={this.props.disableInactive}
                        filters={this.getSearchFiltersForCell()}
                        getOptionValue={cellRendererParams.getOptionValue}
                        idsToExclude={this.props.idsToExclude}
                        innerRef={this.select}
                        isCreatable={
                            getFlagEnabled("WA-8168-field-added-picklist-items")
                                ? this.props.isCreatable && this.userCanFieldAddItems
                                : undefined
                        }
                        isDesignSystem={this.props.isDesignSystem}
                        isMulti={colDef.multiselect}
                        onChange={this.onChange}
                        onCreateOption={this.onCreateOption}
                        optionList={this.props.options}
                        prefixFormatter={cellRendererParams.prefixFormatter}
                        primarySubtitleFormatter={cellRendererParams.primarySubtitleFormatter}
                        resourceName={colDef.resourceName}
                        secondarySubtitleFormatter={cellRendererParams.secondarySubtitleFormatter}
                        titleFormatter={cellRendererParams.titleFormatter}
                        value={this.state.selectedValue}
                        valueFormatter={colDef.valueFormatter}
                    />
                )
            } else {
                return (
                    <SelectorV1
                        clearable={true}
                        openOnFocus={true}
                        ref={this.select}
                        context={this.props.context}
                        multiselect={this.props.multiselect}
                        resourceName={this.props.column.colDef.resourceName}
                        onChange={this.onChange}
                        onBlur={this.props.multiselect ? this.dispatchFilterChange : undefined}
                        onClose={this.props.multiselect ? this.dispatchFilterChange : undefined}
                        idsToExclude={this.props.idsToExclude}
                        value={this.state.selectedValue}
                        valueKey={this.props.valueKey ? this.props.valueKey : "id"}
                        valueFormatter={colDef.valueFormatter}
                        prefixFormatter={cellRendererParams.prefixFormatter}
                        titleFormatter={cellRendererParams.titleFormatter}
                        primarySubtitleFormatter={cellRendererParams.primarySubtitleFormatter}
                        secondarySubtitleFormatter={cellRendererParams.secondarySubtitleFormatter}
                        filters={this.getSearchFiltersForCell()}
                    />
                )
            }
        }
        return <div className="select-div">{selectorToRender()}</div>
    }
}
