import { tReferenceableToRelatedFilters, tRelatedFilterInfo } from "./types"

/**
 * A mapping of resource names -> the related filter. When selecting values for
 * a given resource, the options are filtered by the related filters.
 *
 * An example use case: if Group A is selected and the selected project is edited so that
 * it now belongs to Group B, that project should be deselected since it no
 * longer matches the active group filter.
 */
// TODO: To keep the new defaultLabels in sync with the filterDef, filterDefs should start pulling
// these labels. A helper function would be nice for this

export const referenceableToRelatedFilters: tReferenceableToRelatedFilters = new Map([
    [
        "companyAbsenceTypes",
        {
            defaultFilterKey: "companyAbsenceTypesId",
            queryParam: "company_absence_type_id",
            defaultLabel: "Company Absence Types",
            relatedFilters: [],
        },
    ],
    [
        "companyGroups",
        {
            defaultFilterKey: "groupId",
            queryParam: "group_id",
            defaultLabel: "Group",
            relatedFilters: [],
        },
    ],
    [
        "companyStartStopTypes",
        {
            defaultFilterKey: "companyStartStopTypeId",
            queryParam: "company_start_stop_id",
            defaultLabel: "Company Timestamp Type",
            relatedFilters: [],
        },
    ],
    [
        "costCodes",
        {
            defaultFilterKey: "costCodeId",
            queryParam: "cost_code_id",
            defaultLabel: "Cost Codes",
            relatedFilters: [
                { queryParam: "project_id", filterKey: "projectId" },
                { queryParam: "project_status", filterKey: "projectStatus" },
            ],
        },
    ],
    [
        "costItems",
        {
            defaultFilterKey: "costItemId",
            queryParam: "cost_item_id",
            defaultLabel: "PCO #",
            relatedFilters: [{ queryParam: "project_id", filterKey: "projectId" }],
        },
    ],
    [
        "changeOrders",
        {
            defaultFilterKey: "changeOrderId",
            queryParam: "change_order_id",
            defaultLabel: "PCO #",
            relatedFilters: [{ queryParam: "project_id", filterKey: "projectId" }],
        },
    ],
    [
        "employees",
        {
            defaultFilterKey: "employeeId",
            filterKeys: ["employeeId", "timeCardOwnerId"],
            queryParam: "employee_id",
            defaultLabel: "Employee",
            relatedFilters: [
                {
                    queryParam: "employee_trade",
                    filterKey: "employeeTrade",
                },
                // we don't actually want to filter this by projects, but since we
                // don't differentiate between employees and projectEmployees, and
                // we MUST filter project employees by project, we are stuck setting
                // this here for now. It can be removed in the column via
                // `ignoreRelatedFilters: ["projectId"]`.
                { queryParam: "project_id", filterKey: "projectId" },
            ],
        },
    ],
    [
        "equipment",
        {
            defaultFilterKey: "equipmentId",
            queryParam: "equipment_id",
            defaultLabel: "Equipment",
            relatedFilters: [{ queryParam: "project_id", filterKey: "projectId" }],
        },
    ],
    [
        "projectMaterials",
        {
            defaultFilterKey: "materialId",
            queryParam: "material_id",
            defaultLabel: "Project Materials",
            relatedFilters: [{ queryParam: "project_id", filterKey: "projectId" }],
        },
    ],
    [
        "materials",
        {
            defaultFilterKey: "materialId",
            queryParam: "material_id",
            defaultLabel: "Materials",
            relatedFilters: [{ queryParam: "project_id", filterKey: "projectId" }],
        },
    ],
    [
        "projects",
        {
            defaultFilterKey: "projectId",
            queryParam: "project_id",
            defaultLabel: "Projects",
            relatedFilters: [
                { queryParam: "group_id", filterKey: "groupId" },
                { queryParam: "project_status", filterKey: "projectStatus" },
            ],
        },
    ],
    [
        "companies",
        {
            defaultFilterKey: "companyId",
            queryParam: "company_id",
            defaultLabel: "Companies",
            relatedFilters: [{ queryParam: "schema_name", filterKey: "schemaName" }],
        },
    ],
    [
        "projectShares",
        {
            defaultFilterKey: "projectShareId",
            queryParam: "project_share_id",
            defaultLabel: "Project Shares",
            relatedFilters: [],
        },
    ],
    [
        "schemaStatusNames",
        {
            defaultFilterKey: "schemaStatusName",
            queryParam: "schema_status_name",
            defaultLabel: "Schema Status Names",
            relatedFilters: [{ queryParam: "schema_name", filterKey: "schemaName" }],
        },
    ],
    [
        "analyticsDashboards",
        {
            defaultFilterKey: "analyticsDashboardId",
            queryParam: "analytics_dashboard_id",
            defaultLabel: "Analytics Dashboards",
            relatedFilters: [],
        },
    ],
    [
        "companyCrewTypes",
        {
            defaultFilterKey: "companyCrewTypesId",
            queryParam: "company_crew_types_id",
            defaultLabel: "Labor Types",
            relatedFilters: [],
        },
    ],
    [
        "employeeTrades",
        {
            defaultFilterKey: "employeeTrade",
            queryParam: "employee_trade",
            defaultLabel: "Trade",
            relatedFilters: [],
        },
    ],
    [
        "employeeClassifications",
        {
            defaultFilterKey: "employeeClassification",
            queryParam: "employee_classification",
            defaultLabel: "Classification",
            relatedFilters: [],
        },
    ],
    [
        "companyTrades",
        {
            defaultFilterKey: "companyTrade",
            queryParam: "company_trade",
            defaultLabel: "Company Trades",
            relatedFilters: [],
        },
    ],
    [
        "companyClassifications",
        {
            defaultFilterKey: "companyClassification",
            queryParam: "company_classification",
            defaultLabel: "Company Classifications",
            relatedFilters: [],
        },
    ],
    [
        "workShifts",
        {
            defaultFilterKey: "workShiftId",
            queryParam: "work_shift_id",
            defaultLabel: "Time Cards",
            relatedFilters: [
                { queryParam: "foreman_id", filterKey: "timeCardOwnerId" },
                { queryParam: "project", filterKey: "projectId" },
            ],
            extraFilters: [
                { queryParam: "project", field: "/project", resourceName: "projects" },
                { queryParam: "start_date", field: "/date" },
                { queryParam: "foreman_id", field: "/foreman", resourceName: "employees" },
            ],
        },
    ],
    [
        "cohorts",
        {
            defaultFilterKey: "cohortId",
            queryParam: "cohorts",
            defaultLabel: "Cohorts",
            relatedFilters: [],
        },
    ],
    [
        "timekeepingStatuses",
        {
            defaultFilterKey: "timekeepingStatuses",
            queryParam: "status",
            defaultLabel: "Entry Status",
            relatedFilters: [],
        },
    ],
])

//////////////////////////////////////////////////////////////
// Helper functions to handle iterating over the Map

export const getResourceByFilterKey = (filterKey: string): string | undefined => {
    let resource

    for (const [refKey, refValue] of referenceableToRelatedFilters) {
        if ((refValue.defaultFilterKey as string) === (filterKey as string)) {
            resource = refKey
            break
        } else if (refValue.filterKeys && refValue.filterKeys.find(key => key === filterKey)) {
            resource = refKey
            break
        }
    }

    return resource
}

export const getDefaultLabelByFilterKey = (filterKey: string): string | undefined => {
    const value = [...referenceableToRelatedFilters.values()].find(
        (refValue: tRelatedFilterInfo) =>
            (refValue.defaultFilterKey as string) === (filterKey as string) ||
            (refValue.filterKeys && refValue.filterKeys.find(key => key === filterKey))
    )

    // Dynamically generated filter keys will use their filter key as the default label
    // (but the label should really get overriden where used instead of using the default)
    return value?.defaultLabel
}
