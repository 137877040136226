import { getFormattedValueFromGroupNode } from "./ag-grid-utils"

export const defaultComparator = (valueA, valueB) => {
    if (typeof valueA === "string" && typeof valueB === "string") {
        return valueA.localeCompare(valueB)
    }
    if (valueA === null && valueB !== null) {
        return -1
    }
    if (valueA !== null && valueB === null) {
        return 1
    }
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0
}

export const getFormattedValueComparatorForField = field => (valueA, valueB, nodeA) => {
    const columnApi = nodeA.beans.columnApi
    const valueFormatter = columnApi?.getColumn(field)?.colDef?.valueFormatter
    const formattedValueA = valueFormatter ? valueFormatter({ value: valueA }) : valueA
    const formattedValueB = valueFormatter ? valueFormatter({ value: valueB }) : valueB
    return defaultComparator(formattedValueA, formattedValueB)
}

export const compareGroupColumns = (valueA, valueB, nodeA, nodeB) => {
    // this handles the case where we are just given the formatted values, like in filter selection
    // we can probably remove once we get rid of the old filter by foreman functionality
    if (nodeA === undefined && nodeB === undefined) {
        return valueA.localeCompare(valueB)
    }

    if (valueA == undefined || valueB == undefined) return valueA - valueB
    const a = getFormattedValueFromGroupNode(nodeA)
    const b = getFormattedValueFromGroupNode(nodeB)
    return defaultComparator(a, b)
}

export const compareStrings = (valueA, valueB) => {
    // make cases the same for sorting purposes to work correctly
    const aStr = valueA ? valueA.toLowerCase() : ""
    const bStr = valueB ? valueB.toLowerCase() : ""
    return aStr < bStr ? -1 : aStr > bStr ? 1 : 0
}
export const compareNumbersStrings = (valueA, valueB) => {
    // Numbers are given priority over strings
    if (typeof valueA === "number" && typeof valueB === "number") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0
    } else if (typeof valueA === "string" && typeof valueB === "string") {
        return compareStrings(valueA, valueB)
    } else if (typeof valueA === "number" && typeof valueB === "string") {
        return -1
    } else if (typeof valueA === "string" && typeof valueB === "number") {
        return 1
    }
    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0
}

export const compareCostCodeObjects = (valueA, valueB) => compareStrings(valueA.code, valueB.code)

export const compareTimekeepingEntryObjects = (valueA, valueB) => {
    let totalA = 0
    let totalB = 0
    for (const field of ["ST", "OT", "DT"]) {
        if (valueA && field in valueA) {
            totalA += valueA[field]
        }
        if (valueB && field in valueB) {
            totalB += valueB[field]
        }
    }
    return totalA - totalB
}
