import { getAuditHistoryData, getAuditHistoryByIdData } from "../api"
import { loadAllReferenceableData } from "../cached-data/actions"
import { loadingError, referenceableDataSet } from "../dashboard-data/actions"

export const getAuditHistory = (resource, filters) => {
    return dispatch => {
        const promises = []
        resource.forEach(rowResource => {
            promises.push(
                new Promise((resolve, reject) => {
                    getAuditHistoryData(rowResource, filters).then(
                        data => {
                            resolve({ [rowResource]: data })
                        },
                        err => {
                            reject(err.response.data)
                        }
                    )
                })
            )
        })

        dispatch(startAuditHistory())
        Promise.all(promises)
            .then(data => {
                const auditData = {}
                data.forEach(value => {
                    Object.entries(value).forEach(entry => {
                        const [key, val] = entry
                        auditData[key] = val
                    })
                })
                dispatch(setAuditHistory(auditData))
            })
            .catch(e => {
                if (e != "abortundefined") {
                    dispatch(loadingError("Something went wrong, please try again later."))
                }
            })
            .finally(() => {
                dispatch(finishAuditHistory())
            })
    }
}

export const startAuditHistory = () => {
    return { type: "START_AUDIT_HISTORY" }
}

export const setAuditHistory = response => {
    return {
        type: "GET_AUDIT_HISTORY",
        data: {
            response,
        },
    }
}

export const finishAuditHistory = () => {
    return { type: "FINISH_AUDIT_HISTORY" }
}

export const updateAuditFlag = isAudit => {
    return {
        type: "UPDATE_AUDIT_FLAG",
        data: {
            isAudit,
        },
    }
}

export const getAuditHistoryById = (resource, id, queryParams) => async dispatch => {
    const data = await getAuditHistoryByIdData(resource, id, queryParams)
    const referenceableIds = Array.from(data.reduce((set, row) => set.add(row["history_employee"]), new Set()))
    dispatch(loadAllReferenceableData("employees", referenceableIds))
    dispatch(referenceableDataSet({ employees: referenceableIds }))
    dispatch(auditHistoryByIdGet({ [resource]: data }, id))
}

export const auditHistoryByIdGet = (auditHistory, tagId) => {
    return {
        type: "GET_AUDIT_HISTORY_BY_ID",
        data: {
            auditHistory,
            tagId,
        },
    }
}

export const auditHistoryHideModal = () => {
    return {
        type: "HIDE_AUDIT_HISTORY_MODAL",
    }
}
