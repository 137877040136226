import React from "react"
import { HeaderSort } from "./header-sort"
import { getFlagEnabled } from "../../../getFlagValue"

require("pivot-headers.less")

const PivotHeader = props => {
    let classes = props.classes ? props.classes.join(" ") : ""
    if (getFlagEnabled("WA-8239-weekly-view-hour-crowding")) classes += " pivot-header-wide"
    return (
        <div className={`pivot-header ${classes}`}>
            <div className="pivot-header-custom-font">
                <span>
                    {props.column.colDef.headerName} {props.enableSorting ? <HeaderSort {...props} /> : null}
                </span>
            </div>
            <div
                className={
                    getFlagEnabled("WA-8239-weekly-view-hour-crowding")
                        ? props.subtitles.length === 3
                            ? "pivot-header-days-inline-wide"
                            : "pivot-header-days-inline-wide-total"
                        : "pivot-header-days-inline"
                }
            >
                {props.subtitles.map((subtitle, index) => (
                    <div
                        key={index}
                        className={
                            getFlagEnabled("WA-8239-weekly-view-hour-crowding")
                                ? "pivot-header-custom-width-wide"
                                : "pivot-header-custom-width"
                        }
                    >
                        {subtitle}
                    </div>
                ))}
            </div>
        </div>
    )
}
export default PivotHeader
