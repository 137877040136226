import { add, format } from "date-fns"
import {
    dateIsSameOrAfter,
    dateIsSameOrBefore,
    findGroupDataFromChildrenAfterGroup,
    getAggParams,
    isPivotColumn,
    isPlaceholder,
} from "./ag-grid-ts-utils"

import { KeyCodes, defaultWorkHours, statusesByUserRole, STORED_DATE_ONLY_FORMAT } from "./constants"
import { isNumber } from "./validators"
import Rmbx from "../util"
import { getAsDate } from "./ts-utils"
import { getFlagEnabled } from "../getFlagValue"
import { getGroupKeyInfoFromApis } from "./ag-grid-grouping-utils"

export const getStatusesByUserRole = () => {
    return statusesByUserRole
}

export const isPivotRowTotalColumn = colDef => colDef.pivotTotalColumnIds && colDef.pivotTotalColumnIds.length > 0

export const isGroupRow = params => params?.node?.group && !params.node.footer

export const getCellValue = params => {
    const isAutoGroupColumn = params.colDef.colId && params.colDef.colId.startsWith("ag-Grid-AutoColumn")

    if (!isAutoGroupColumn) {
        return params.value
    }
    const field = params.node.field
    const colDef = params.columnApi.getColumn(field).colDef
    const valueGetter = colDef.valueGetter

    let data

    if (getFlagEnabled("WA-7478-fix-blank-group-cell-on-weekly-view")) {
        data = isGroupRow(params) ? findGroupDataFromChildrenAfterGroup(params.node) : params.node.data
    } else {
        data = isGroupRow(params) ? params.node.allLeafChildren[0]?.data : params.node.data
    }

    return valueGetter ? valueGetter({ data: data, colDef: colDef, context: params.context }) : data[field]
}

export const getRowGroupValue = params => {
    const key = params.node.key
    const colDef = params.node.rowGroupColumn.colDef
    const keyToValue = colDef.keyToValue
    const referenceableData = params.context.referenceableData
    const value = keyToValue ? keyToValue({ key, referenceableData, colDef }) : key

    return value
}

export const getFormattedValueFromGroupNode = node => {
    const field = node.field
    const data = getFlagEnabled("WA-7478-fix-blank-group-cell-on-weekly-view")
        ? findGroupDataFromChildrenAfterGroup(node)
        : node.allLeafChildren[0]?.data
    const colDef = node.rowGroupColumn.colDef
    // is there any better way of getting the context?
    const context = node.context
        ? node.context.contextParams.providedBeanInstances.gridOptions.context
        : node.beans.context.contextParams.providedBeanInstances.gridOptions.context
    const valueGetter = colDef.valueGetter
    const value = valueGetter ? valueGetter({ data: data, colDef: colDef, context: context }) : data[field]
    const valueFormatter = colDef.valueFormatter
    const formattedValue = valueFormatter ? valueFormatter({ value, context, node }) : value

    return formattedValue
}

export const flattenData = data => {
    const flatData = { ...data }

    for (const prop in flatData) {
        if (typeof flatData[prop] === "object" && flatData[prop] != null) {
            if (prop === "modifier_active") {
                // Jump into the modifiers object and flatten entities there to IDs.
                // We are checking this case first because there could feasibly be a custom modifier type
                // with an "id" slug, and that would flatten the modifiers object in an undesirable way.
                flatData[prop] = flattenData(flatData[prop])
            } else if (
                prop === "status" &&
                "name" in flatData[prop] &&
                getFlagEnabled("WA-8087-custom-timekeeping-statuses")
            )
                flatData[prop] = flatData[prop].name
            else if ("id" in flatData[prop]) {
                flatData[prop] = flatData[prop].id
            }
        }
    }

    return flatData
}

export const idKeyCreator = params => {
    if (isNumber(params.value)) {
        return String(params.value)
    }

    return params.value ? String(params.value.id) : "undefined"
}

export const defaultKeyCreator = params => (params.value != null ? String(params.value) : "undefined")

export const referenceableKeyToValue = params => params.referenceableData[params.colDef.resourceName][params.key]

// TODO: should we really need to check for params.data? I think this is an issue with
// the react/redux lifecycle or some other deeper issue.
export const errorRowClassRule = params =>
    params.data && params.data.errors ? Object.keys(params.data.errors).length > 0 : null

export const placeholderRowClassRule = params => params.data && isPlaceholder(params.data)

export const alertRowClassRule = params => {
    if (isPlaceholder(params.data)) return null
    if (isGroupRow(params))
        return params.node?.allLeafChildren.some(leaf => leaf.data?.alerts && Object.keys(leaf.data.alerts).length)

    return params.data?.alerts ? Object.keys(params.data.alerts).length > 0 : null
}

export const weeklyTkModalErrorRowClassRule = params => {
    if (isGroupRow(params)) {
        return params.node?.allLeafChildren.some(
            leaf =>
                leaf.data?.errors &&
                leaf.data.errors.serverErrors &&
                Object.keys(leaf.data.errors.serverErrors).length
        )
    }

    return (
        params.data &&
        params.data.errors &&
        params.data.errors.serverErrors &&
        Object.keys(params.data.errors.serverErrors).length
    )
}

export const errorCellClassRule = params => {
    if (isGroupRow(params))
        return params.node?.allLeafChildren.some(
            leaf => leaf.data?.errors && leaf.data.errors[params.colDef.field]?.length
        )

    return params.data && params.data.errors ? params.data.errors[params.colDef.field]?.length : null
}

// Errors is a dictionary like {<field_name>: [list_of_errors]}
// so if there aren't any values at all, there can't be any errors. We also need
// to check for a bunch of empty arrays of errors
const cellHasNoErrors = params =>
    params.data &&
    ((params.data.errors &&
        (!Object.values(params.data.errors).length ||
            !Object.values(params.data.errors).reduce((c, err) => (c += err.length), 0))) ||
        !params.data.errors)

export const alertCellClassRule = params => {
    if (isGroupRow(params)) {
        const childrenHaveNoErrors = params.node?.allLeafChildren.every(n => cellHasNoErrors(n))
        const childHasAlert = params.node?.allLeafChildren.some(
            n => !isPlaceholder(n.data) && n.data.alerts && n.data.alerts[params.colDef.field]
        )

        return childrenHaveNoErrors && childHasAlert
    }
    // We only want to show alert cells if the cell is brand new (i.e. no ID)

    return cellHasNoErrors(params) && !isPlaceholder(params.data) && params.data.alerts
        ? params.data.alerts[params.colDef.field]
        : null
}

export const editableCellClassRule = params => params.colDef.editable

export const shouldUseReadOnlyCellStyle = params => {
    const status = params.value.status
    const userRole = params.context.currentUser.user_role

    return !statusesByUserRole.some(value => {
        const statusMatch = status instanceof Set ? status.has(value.name) : value.name === status

        return statusMatch && value.canEditCell.includes(userRole)
    })
}

export const roleHasWritePermission = (status, user_role) => {
    // user_role has write permission on every status
    // eslint-disable-next-line no-shadow
    return statusesByUserRole.every(value => {
        const statusMatch = status instanceof Set ? status.has(value.name) : value.name === status

        return !statusMatch || value.canEditCell.includes(user_role)
    })
}

export const defaultGetRowNodeId = params => params.data.gridId

// unfortunately, the react-select component won't let us have control of onKeyDown events
export const suppressSelectKeyboardEvent = params =>
    params.data.newRow && params.editing && KEY_CODES_TO_IGNORE.includes(params.event.keyCode)

const KEY_CODES_TO_IGNORE = [
    KeyCodes.KEY_ENTER,
    KeyCodes.KEY_PAGE_UP,
    KeyCodes.KEY_PAGE_DOWN,
    KeyCodes.KEY_PAGE_END,
    KeyCodes.KEY_PAGE_HOME,
    KeyCodes.KEY_LEFT,
    KeyCodes.KEY_UP,
    KeyCodes.KEY_RIGHT,
    KeyCodes.KEY_DOWN,
]

/**
 * UnitRate is quanties / hours where both are positive; if they aren't, return a useful string for the user
 *
 * @param quantities
 * @param hours
 * @returns {*}
 */
export const calculateQuantitiesPerHour = (quantities, hours) => {
    if (hours <= 0 || quantities <= 0) {
        return "" + quantities + " / " + hours
    }

    return quantities / hours
}

// TODO: convert variable names to plural or appeld "List" or "Set on the end?"
const reduceObject = (total, value) => {
    const setProps = ["startStopTypes", "status", "workshiftId"]

    for (const prop of Object.keys(value)) {
        const val = value[prop]

        // sets

        if (setProps.includes(prop)) {
            if (prop in total) {
                if (val instanceof Set) {
                    total[prop] = new Set([...total[prop], ...val])
                } else {
                    total[prop].add(val)
                }
            } else {
                if (val instanceof Set) {
                    total[prop] = val
                } else {
                    total[prop] = new Set([val])
                }
            }

            continue
        }

        // generic handlers

        if (typeof val === "object") {
            if (!(prop in total)) {
                total[prop] = []
            }
            total[prop].push(val)

            continue
        }

        // things like ST, DT, OT, and signature

        if (prop in total) {
            total[prop] += val

            continue
        }
        total[prop] = val
    }

    return total
}

export const aggregateObject = values => getAggParams(values).reduce(reduceObject, { ST: 0 })

export const aggregateAllSameOrMixed = values => (values.every(ele => ele === values[0]) ? values[0] : null)

/**
 * Prase a number to a specified precision or round to the nearest whole number.
 * @param {number} value Number to be parsed.
 * @param {number} figures Precision number.
 * @param {boolean} roundToWhole True to round to the nearest whole number if the number is not between -1 & 1.
 * @returns {string|number} Parsed number.
 */
export const significantFigureMaker = (value, figures, roundToWhole) => {
    if (typeof value !== "number") return value
    const num = Number.parseFloat(value)

    if (roundToWhole && Math.abs(num) >= 1) return Math.round(num)

    return num.toPrecision(figures)
}

export const getValuePivotCellClass = params => {
    let cellClass = ""
    const isValueColumn = isPivotColumn(params.colDef)
    const rowGroupColumns = params.columnApi.getRowGroupColumns()

    if (
        !isValueColumn ||
        params.node.field !== "/employee" ||
        !(rowGroupColumns[0].colId === "/employee" || rowGroupColumns[0].colId === "/trade")
    ) {
        return cellClass
    }

    if (
        params.value.ST > 0 &&
        params.value.ST < defaultWorkHours.DAY_WORK_HOURS &&
        noColumnAbsences(params) &&
        noColumnShiftExtras(params)
    ) {
        cellClass = "cell-color-red"
    }

    if (typeof params.value.workshiftId !== "undefined") {
        const workShiftSet = new Set(params.value.workshiftId.map(ws => (typeof ws === "object" ? ws.id : ws)))
        if (workShiftSet.size > 1) cellClass = "cell-color-red"
    }

    return cellClass
}

export const getTotalPivotCellClass = params => {
    let cellClass = ""
    const isPivotTotalColumn = isPivotRowTotalColumn(params.colDef)
    const rowGroupColumns = params.columnApi.getRowGroupColumns()

    if (!isPivotTotalColumn || params.node.field !== "/employee" || !groupedByEmployeeOrTrade(rowGroupColumns)) {
        return cellClass
    }

    if (params.value.ST > defaultWorkHours.WEEK_WORK_HOURS) {
        cellClass = "cell-color-red"
    } else if (
        params.value.ST < defaultWorkHours.WEEK_WORK_HOURS &&
        noColumnAbsences(params) &&
        noColumnShiftExtras(params)
    ) {
        cellClass = "cell-color-red"
    }

    return cellClass
}

export const noColumnAbsences = params => {
    return params.value.absenceReason == undefined || params.value.absenceReason == ""
}

export const noColumnShiftExtras = params => {
    return params.value.shiftExtra == undefined || params.value.shiftExtra.length == 0
}

export const groupedByEmployeeOrTrade = rowGroupColumns => {
    return rowGroupColumns[0].colId === "/employee" || rowGroupColumns[0].colId === "/trade"
}
export const timekeepingDataIsEditable = params => {
    // If there is no data on params - for example, because this is a grouping row -
    // skip the checks below and just return true, so that the grouping row and its
    // subgroups are effectively marked active and additional checks can fall to the
    // rows underneath them

    if (!params.data) return { canEdit: true, messages: [] }

    const roleCanEditLocked = ["ADMIN", "PAYROLL_ADMIN"].includes(params.context.currentUser.user_role)
    const lockEndDate = params.context.currentUser.company_options.lock_end_date
    const dataDate = params.data.date
    const dataLocked = dateIsSameOrAfter(dataDate, lockEndDate)
    const statusName =
        params.data.status && typeof params.data.status === "object" ? params.data.status.name : params.data.status
    const roleCanEditStatus = getFlagEnabled("WA-8087-custom-timekeeping-statuses")
        ? !params.data.status || params.context.currentUser.employee.allowed_tk_statuses.includes(statusName)
        : !params.data.status || roleHasWritePermission(params.data.status, params.context.currentUser.user_role)

    // Ensure cost code, project, and employee are allowed to be referenced
    // by the user for submission of new rows
    const costCodeRef = params.data.cost_code
        ? params.context.referenceableData.costCodes[params.data.cost_code]
        : undefined
    const canRefCostCode = !costCodeRef || !costCodeRef.permissions || costCodeRef.permissions.includes("REF")

    if (!canRefCostCode) {
        return { canEdit: false, messages: ["You do not have permission to access this Cost Code"] }
    }

    const projectRef = params.data.project
        ? params.context.referenceableData.projects[params.data.project]
        : undefined
    const canRefProject = !projectRef || !projectRef.permissions || projectRef.permissions.includes("REF")

    if (!canRefProject) {
        return { canEdit: false, messages: ["You do not have permission to access this Project"] }
    }

    const employeeRef = params.data.employee
        ? params.context.referenceableData.employees[params.data.employee]
        : undefined
    const canRefEmployee = !employeeRef || !employeeRef.permissions || employeeRef.permissions.includes("REF")

    if (!canRefEmployee) {
        return { canEdit: false, messages: ["You do not have permission to access this Employee"] }
    }

    return {
        canEdit: roleCanEditStatus && (roleCanEditLocked || !dataLocked),
        messages: ["You do not have permission to edit in this status"],
    }
}

export const timekeepingRowIsEditable = rowNode => {
    const context = rowNode.context
        ? rowNode.context.contextParams.providedBeanInstances.gridOptions.context
        : rowNode.beans.context.contextParams.providedBeanInstances.gridOptions.context

    return timekeepingDataIsEditable({ ...rowNode, context }).canEdit
}

export const suppressKeyboardHandler = params => {
    // return true (to suppress) if editing and user hit up/down/enter keys
    const keys = [KeyCodes.KEY_UP, KeyCodes.KEY_DOWN, KeyCodes.KEY_ENTER]

    return params.editing && keys.includes(params.event.keyCode)
}

export const isCellEditableByStatus = gridApi => {
    const cell = gridApi.getFocusedCell()
    const rowNode = gridApi.getDisplayedRowAtIndex(cell.rowIndex)
    if (!rowNode) return false
    const column = cell.column
    const context = gridApi.gridOptionsWrapper.gridOptions.context
    const data = rowNode?.aggData[column.colId]

    if (!data || !data.status) {
        return true
    }

    return column.colDef.editable({ context: context, data: data })
}

export const getAvailableStatusValues = userRole => {
    const statusValues = []
    // eslint-disable-next-line no-shadow
    const statusesByUserRole = getStatusesByUserRole()
    statusesByUserRole.forEach(value => {
        if (value.canChangeToStatus.includes(userRole)) {
            statusValues.push(value.name)
        }
    })

    return statusValues
}

/**
 * Smashes the source data from the API into a single list. For Weekly TK at least,
 * we have source data from multiple resources.
 * @param sourceData - Dictionary of api records in the form of {resource: [rec1, rec2, ...]}
 * i.e. {"timekeepingEntries": [{tk1}, {tk2}, ...]}
 * @returns {*[]}
 */
export const getRowData = sourceData => {
    const rowData = []

    for (const resource in sourceData) {
        if (sourceData[resource]) {
            // We tack on a `dataType` key/value pair because when we use the external filtering
            // feature of AG Grid, we don't know what type of data each node has, it just looks like
            // a dictionary of key/value pairs. Identifying it with a data type allows the fancy
            // search bar to search for different fields for each type of resource
            rowData.push(...sourceData[resource].map(e => ({ ...e, dataType: resource })))
        }
    }

    return rowData
}

export const getDummyData = (startDate, endDate) =>
    enumerateDaysBetweenDates(startDate, endDate).map((day, idx) => ({
        date: day,
        gridId: `dummy${idx}`,
        dummy: true,
    }))

// Returns an array of dates between the two dates
export const enumerateDaysBetweenDates = (startDate, endDate) => {
    const dates = []

    while (dateIsSameOrBefore(endDate, startDate)) {
        startDate = getAsDate(startDate)
        dates.push(format(startDate, STORED_DATE_ONLY_FORMAT))
        startDate = add(startDate, { days: 1 })
    }

    return dates
}

const sumHandleStrings = (total, currentValue) => (Rmbx.util.isNumber(currentValue) ? total + +currentValue : total)

export const aggregateWithStringHandling = values => {
    const aggParams = getAggParams(values)
    const sum = aggParams.reduce(sumHandleStrings, 0)

    return sum ? sum : null
}

export const deletedRowClassRule = params => {
    return params.data && params.data.deleted_on
}

export const belongsToCurrentProject = params => {
    const currentProjectId = params.context.current_project_id
    // current_project_id can be a number or a list of numbers, cover both

    if (Array.isArray(currentProjectId)) {
        return currentProjectId.includes(params.data?.project)
    }

    return params.data?.project === currentProjectId
}

// checks whether a token row in the API Integration Dashboard is active,
// meaning it can be selected, and deactivated/field names can be toggled
// takes in an object containing data from the the given row
// (usually the data attribute from either cell renderers params/rowNode)
export const isTokenRowActive = rowData => {
    return (
        rowData.deactivator === "" &&
        (rowData.deactivation_date === null || rowData.deactivation_date === undefined)
    )
}

export const statusColumnAggFunc = p => {
    if (p.values?.length > 0) {
        return new Set(p.values).size > 1 ? "Multiple Statuses" : p.values[0]
    }

    return ""
}

export const addRowTableAction = featureFlags => ({
    label: "Add Row",
    icon: "add",
    action: "addNewRow",
    disabled: context => {
        if (context.settings.tableName === "Shifts and Breaks" && !featureFlags.shifts_and_breaks) {
            return true
        } else {
            return context.getCurrentGrouping() && context.getCurrentGrouping() !== "None"
        }
    },
    tooltip: context => {
        if (context.settings.tableName === "Shifts and Breaks" && !featureFlags.shifts_and_breaks) {
            return "The Shifts and Breaks feature must be enabled on your account to add new shifts or breaks"
        } else {
            return context.getCurrentGrouping() && context.getCurrentGrouping() !== "None"
                ? "To add a row, select a parent row " + "before clicking this button"
                : "Add an empty row to the table"
        }
    },
})

export const addEmployeeRowsTableAction = {
    label: "Add Employees",
    action: "addEmployeeRows",
    icon: "worker",
    disabled: context => context.groupKeyInfo.find(key => key.colDef.field === "/employee"),
    tooltip: context =>
        context.groupKeyInfo.find(key => key.colDef.field === "/employee")
            ? "The entries you are viewing are for a specific " +
              "employee. New employees cannot be added to this view"
            : undefined,
}

export const getWorkShifts = (context, sourceData) => {
    const resourceName = context.settings.resources[0]
    const reduceFn = (acc, e) => {
        if (e.work_shift_id)
            acc.add(
                isNumber(e.work_shift_id)
                    ? e.work_shift_id
                    : "tempId" in e.work_shift_id
                    ? e.work_shift_id.tempId
                    : e.work_shift_id.id
            )
        return acc
    }
    return context.selectedRows?.length
        ? context.selectedRows.reduce(reduceFn, new Set())
        : sourceData[resourceName]?.reduce(reduceFn, new Set()) || new Set()
}

export const editTimecardDetailsAction = () => [
    {
        label: "Edit Time Card Details",
        action: "editTimeCardDetails",
        icon: "edit",
        disabled: (context, sourceData) => getWorkShifts(context, sourceData).size !== 1,
        tooltip: (context, sourceData) => {
            return getWorkShifts(context, sourceData).size !== 1
                ? "Please select one or more rows representing a single timecard"
                : ""
        },
    },
]

// Determine if a cell belongs to a row that's a child of a timecard, and the cell doesn't have data
export const getIsChildOfTimecardWithoutData = (cellData, row, groupKeyInfo) => {
    let isGroupedByTimecard = false
    for (const groupKey of groupKeyInfo) {
        isGroupedByTimecard ||= groupKey.colDef.field === "/work_shift_id"
    }

    let hasData = false
    Object.values(cellData).forEach(propValue => {
        if (typeof propValue === "object") {
            hasData ||= Object.values(propValue).length
        } else {
            hasData ||= propValue !== undefined
        }
    })

    return isGroupedByTimecard && (row?.level || 0) > 0 && !hasData
}

export const canPasteIntoCell = context => {
    const cell = context.gridApi.getFocusedCell()

    if (!cell) {
        return false
    }

    const colDef = cell.column.getColDef()
    const row = context.gridApi.getDisplayedRowAtIndex(cell.rowIndex)

    let isChildOfTimecardWithoutData = false
    if (getFlagEnabled("WA-8300-tk-conflicting-pivot-paste-lock")) {
        const groupKeyInfo = getGroupKeyInfoFromApis(context.gridApi, context.columnApi, context.referenceableData)
        isChildOfTimecardWithoutData = getIsChildOfTimecardWithoutData(context.selectedCellData, row, groupKeyInfo)
    }

    const canPaste =
        !isChildOfTimecardWithoutData &&
        isPivotColumn(colDef) &&
        !["trade", "status"].includes(row?.field || "") &&
        row?.key !== "" &&
        isCellEditableByStatus(context.gridApi) &&
        !isPivotRowTotalColumn(colDef) &&
        Object.values(context.selectedCellData).reduce(
            (agg, tableData) =>
                agg && tableData.every(r => timekeepingDataIsEditable({ data: r, context }).canEdit),
            true
        )

    if (getFlagEnabled("WA-8300-tk-conflicting-pivot-paste-lock")) {
        return {
            canPaste,
            tooltip: canPaste
                ? ""
                : isChildOfTimecardWithoutData
                ? "This timecard already exists on another date. Cannot paste into the currently selected date."
                : "You do not have permission to overwrite the contents of this cell",
        }
    }
    return canPaste
}
