import React from "react"
import styled from "styled-components"
import DefaultCellRenderer from "./default-cell-renderer"
import { isGroupRow } from "../../../common/ag-grid-utils"
import { ICellRendererParams } from "ag-grid-community"

const GroupRowFormattingDefaultCellRenderer = (props: ICellRendererParams): JSX.Element => {
    return isGroupRow(props) ? (
        <GroupSummaryCell>
            <DefaultCellRenderer {...props} />
        </GroupSummaryCell>
    ) : (
        <DefaultCellRenderer {...props} />
    )
}

const GroupSummaryCell = styled.div`
    font-weight: 700;
`

export default GroupRowFormattingDefaultCellRenderer
