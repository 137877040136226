/** Actions */
import { clearRelatedFilter } from "../../filters/actions"
import {
    lockSourceDataEditing,
    sourceDataBulkUpdated,
    sourceDataRowAdded,
    sourceDataRowDeleted,
    sourceDataRowUpdated,
    SSRMRowAdded,
    SSRMRowDeleted,
    SSRMRowSaved,
} from "./dashboard-data"
import { setSaveStatus } from "./network-status"
/** Utils */
import { deleteRow, updateRow, createRow } from "../../api"
import { flattenData } from "../../common/ag-grid-utils"
import { setCellData } from "../../common/ts-utils"
import { getFlagEnabled } from "../../getFlagValue"
/** Const */
import { referenceableToRelatedFilters } from "../../filters/constants"
/** Types */
import { iRmbxColDef } from "../../components/custom-dashboards/settings-files/types"
import { tFilterResourceName } from "../../filters/types"
import {
    ErrorResponse,
    ErrorResponseData,
    ErrorResponseDataErrors,
    MutableSourceData,
    RowErrorData,
    tGroupKeyInfo,
    tResourceObject,
    tRowAPICallable,
} from "../types"
import { AsyncThunk, Thunk, tJSONValue, tResourceName } from "../../common/types"
import { RowNode } from "ag-grid-community"
import { performDataRefresh } from "../../actions/server-side-row-model"
import { tContext, tTableFilters } from "../../components/custom-dashboards/types"

/**
 * Dispatch actions to add a row of source data to the dashboard's data table
 *
 * @param row  The entity row of data being prepped
 * @param colDefs   The AG Grid colDefs for the columns in the table
 * @param rowLevelValidators a list of validators from the table settings to apply to the data rows
 * @param hiddenColumnDefaults Any default values to apply to hidden columns
 * (like Project if a single project is present in the filters)
 * @param lockedColumns The list of columns (like from the settings file) which are fixed because you can infer them
 *          from the cell you've clicked on. We use this to selectively hide columns in the detail modal
 *          as well as to set default values for new entries that get created
 * @param filters Right rail filters
 * @param context Overall table context
 * @returns
 */
export const prepNewRow = (
    row: tResourceObject,
    colDefs: iRmbxColDef[],
    rowLevelValidators: { (node: tResourceObject): boolean }[] | null,
    hiddenColumnDefaults: Record<string, any>,
    lockedColumns: tGroupKeyInfo[],
    filters: tTableFilters,
    context: tContext | null
): tResourceObject => {
    const newRow: tResourceObject = { ...row }
    // Apply any defaults
    colDefs.forEach((colDef: any) => {
        if (
            (colDef.editable || colDef.setDefault) &&
            colDef.cellEditorParams &&
            typeof colDef.cellEditorParams.default !== "undefined"
        ) {
            const value =
                colDef.valueGetter && getFlagEnabled("WA-7625-merge-default-col-defs")
                    ? colDef.valueGetter({ data: newRow, colDef, context })
                    : newRow[colDef.field]
            if (!value) {
                setCellData(newRow, colDef, colDef.cellEditorParams.default)
            }
        }
    })
    // Apply any values from the locked columns
    if (lockedColumns) {
        lockedColumns.forEach((lockedColumn: any) => {
            setCellData(newRow, lockedColumn.colDef, lockedColumn.value)
        })
    }
    // Apply any hidden column default values
    if (hiddenColumnDefaults) {
        Object.keys(hiddenColumnDefaults).forEach((columnKey: string) => {
            if (typeof hiddenColumnDefaults[columnKey] === "object") {
                if (!newRow[columnKey] && hiddenColumnDefaults[columnKey].value)
                    newRow[columnKey] = hiddenColumnDefaults[columnKey].value
            } else {
                const filterValue = filters[hiddenColumnDefaults[columnKey]]
                if (!newRow[columnKey])
                    newRow[columnKey] =
                        Array.isArray(filterValue) && filterValue.length === 1 ? filterValue[0] : filterValue
            }
        })
    }

    const rowData: tResourceObject = { ...newRow, errors: {}, alerts: {}, newRow: true }
    if (!("isPlaceholder" in rowData)) {
        rowData.isPlaceholder =
            context?.settings?.usePlaceholders !== undefined ? context.settings.usePlaceholders : true
    }

    // Validate the row
    return validateRow(rowData, colDefs, rowLevelValidators, context) as tResourceObject
}

/**
 * Add multiple rows of source data to a table and call an update method if applicable
 *
 * @param {tResourceName} resource The resource type of the rows
 * @param {iRmbxColDef[]} colDefs The AG Grid colDefs for the columns in the table
 * @param {tResourceObject[]} rows The new table rows to be added
 * @param rowLevelValidators a list of validators from the table settings to apply to the data rows
 * @param hiddenColumnDefaults Any default values to apply to hidden columns
 * (like Project if a single project is present in the filters)
 * @param lockedColumns The list of columns (like from the settings file) which are fixed because you can infer them
 *          from the cell you've clicked on. We use this to selectively hide columns in the detail modal
 *          as well as to set default values for new entries that get created
 * @param filters Right rail filters
 * @param updateSourceDataCb A callback method to update source data in the table
 * @param context Overall table context
 * @returns
 */
export const bulkAddSourceData = (
    resource: tResourceName,
    colDefs: iRmbxColDef[],
    rows: tResourceObject[],
    rowLevelValidators: { (node: tResourceObject): boolean }[] | null,
    hiddenColumnDefaults: Record<string, string>,
    lockedColumns: tGroupKeyInfo[],
    filters: tTableFilters,
    updateSourceDataCb: {
        (
            data: MutableSourceData,
            isDelete?: boolean,
            field?: string,
            oldValue?: any,
            newValue?: any,
            initialAdd?: boolean
        ): void
    } | null,
    context: tContext
): tResourceObject[] => {
    const newRows = rows.map(r =>
        prepNewRow(r, colDefs, rowLevelValidators, hiddenColumnDefaults, lockedColumns, filters, context)
    )
    if (updateSourceDataCb)
        updateSourceDataCb({ [resource]: newRows }, undefined, undefined, undefined, undefined, true)

    return newRows
}

/**
 * Dispatch actions to add a row of source data to the dashboard's data table
 *
 * @param resource  The entity resource featured in this table
 * @param colDefs   The AG Grid colDefs for the columns in the table
 * @param rowLevelValidators a list of validators from the table settings to apply to the data rows
 * @param hiddenColumnDefaults Any default values to apply to hidden columns
 * (like Project if a single project is present in the filters)
 * @param lockedColumns The list of columns (like from the settings file) which are fixed because you can infer them
 *          from the cell you've clicked on. We use this to selectively hide columns in the detail modal
 *          as well as to set default values for new entries that get created
 * @param filters Right rail filters
 * @param context Overall table context
 * @returns
 */
export const addSourceDataRow = (
    resource: tResourceName,
    colDefs: iRmbxColDef[],
    rowLevelValidators: { (node: tResourceObject): boolean }[] | null,
    hiddenColumnDefaults: Record<string, string>,
    lockedColumns: tGroupKeyInfo[],
    filters: tTableFilters,
    context: tContext | null
): Thunk => dispatch => {
    const newRow = prepNewRow(
        {},
        colDefs,
        rowLevelValidators,
        hiddenColumnDefaults,
        lockedColumns,
        filters,
        context
    )

    if (context?.settings.gridSettings?.rowModelType === "serverSide") dispatch(SSRMRowAdded(resource, newRow))
    else dispatch(sourceDataRowAdded(resource, newRow))
}

/**
 * Recursively finds error messages, e.g.
 * {
 *   "modifier_active": {
 *      "equipment": ["The equipment 'Equipment Name' is inactive."]
 *   }
 * }
 * becomes
 * {
 *   "/modifier_active/equipment": ["The equipment 'Equipment Name' is inactive."]
 * }
 */
const addNestedErrorFields = (
    errors: RowErrorData,
    rawErrors: string[] | ErrorResponseDataErrors,
    prefix: string
) => {
    // If errors is string[], errors[prefix] = rawErrors
    // If errors is object, call addNestedErrorFields on it, add to prefix
    if (Array.isArray(rawErrors)) {
        // Looks like a list of error messages -- add them to the errors object.
        errors[prefix] = rawErrors
    } else if (typeof rawErrors === "object" && rawErrors != null) {
        // Looks like a nested error object -- check it for error messages.
        for (const key of Object.keys(rawErrors)) {
            addNestedErrorFields(errors, rawErrors[key], `${prefix}/${key}`)
        }
    } // else, unexpected format -- ignore it. No further recursion.
}

/**
 * Convert the field names in an error response from the backend to JSON
 * pointer notation
 */
const convertErrorFieldsToJsonPointer = (rawErrors: ErrorResponseData["errors"]) =>
    Object.keys(rawErrors).reduce<RowErrorData>((errors, key) => {
        if (key === "non_field_errors" || key === "detail" || key[0] === "/") {
            errors[key] = rawErrors[key] as any
        } else {
            addNestedErrorFields(errors, rawErrors[key], `/${key}`)
        }

        return errors
    }, {})

/**
 * Return true if the error response contains at least one cell-level error
 */
const hasCellErrors = (errors: { [key: string]: string[] }): boolean => {
    for (const key of Object.keys(errors)) {
        if (key !== "non_field_errors" && key !== "detail") {
            return true
        }
    }

    return false
}

/**
 * Take an error response from the backend and add the error data to the
 * dashboard's source data.
 */
const handleApiErrorResponse = (
    rowData: tResourceObject,
    responseData: ErrorResponse,
    resource: string,
    defaultMessage: string,
    node?: RowNode,
    context?: any
): Thunk<RowErrorData> => dispatch => {
    const response = responseData.response || {}
    const data = response.data || { errors: {} }
    const errors = { ...convertErrorFieldsToJsonPointer(data.errors) }
    const nonFieldErr = errors.non_field_errors
    if (nonFieldErr?.message) {
        errors.row = nonFieldErr.message
    } else if (nonFieldErr && Array.isArray(nonFieldErr)) {
        errors.row = nonFieldErr.join("\n")
    } else if (errors.detail) {
        errors.row = errors.detail
    } else if (data.detail) {
        errors.row = data.detail
    } else if (!hasCellErrors(errors)) {
        errors.row = defaultMessage
    }

    const rowDataWithErrors = { ...rowData, errors }
    // A bit of a strange conditional here, but the Projects list view does not pass a context
    // back, so we're sort of implicitly suggesting that that means it's server-side role model
    // which is a stretch... :/
    if (!context || context.settings.gridSettings?.rowModelType === "serverSide") {
        // We check for undefined newRow because with SSRM if newRow ever existed on the object at all
        // it was a row added at some point during the loading of this dashboard and hence it is being
        // handled in the store (as opposed to coming from the API)
        if (rowData.newRow !== undefined) dispatch(SSRMRowSaved(resource, rowDataWithErrors))
        // Make sure we update AG Grid with the latest errors so they display properly
        if (node) node.setData(rowDataWithErrors)
    } else {
        dispatch(sourceDataRowUpdated(resource, rowDataWithErrors))
    }

    return errors
}

/**
 * Check if the selected AG Grid rows are currently locked and cannot be modified.
 * @param {number[]|string[]} gridIds Rows to be modified.
 * @param {number[]|string[]} [lockedGridIds] Locked grid ids.
 * @returns True if one of the selected rows cannot be modified.
 */
const isDataLocked = (
    gridIds: Array<string | number | undefined>,
    lockedGridIds: Array<string | number | undefined>
) => {
    /**
     * On slower networks, the user may continue to make changes on a row before the initial request to
     * create the resource has finished. While this is happening, the row is considered "locked" and further
     * attempts to update data via the API will be skipped until the row is unlocked.
     */
    if (!lockedGridIds || !lockedGridIds.length) return false
    const gridIdSet = new Set(gridIds)
    for (const id of lockedGridIds) {
        if (gridIdSet.has(id)) return true
    }
}

/**
 * Dispatch actions to handle POST/PUT requests to create or update source
 * data, then handle the response (including errors)
 */
const writeToApiAndHandleResponse = (
    action: tRowAPICallable,
    resource: string,
    rowData: tResourceObject,
    context: any
): AsyncThunk<void | RowErrorData> => (dispatch, getState) => {
    /**
     * When handling an update, merge response data with current data so that
     * annotation data fetched when the table was initially loaded doesn't get lost
     * during individual updates. Don't keep certain frontend-specific fields around.
     */
    const { gridId, errors: _errors, newRow: _newRow, forceAdd, ...currentRowData } = rowData
    if (isDataLocked([gridId], getState().sourceData.lockedGridIds)) return Promise.resolve()

    dispatch(setSaveStatus("in-progress"))
    return Promise.resolve(action(resource, flattenData(rowData))).then(
        (responseData: any) => {
            dispatch(setSaveStatus("saved"))
            let updatedRowData
            // forceAdd is a special case for duplicating a row. see the `duplicateCohort` row-level action.
            if (gridId == null || forceAdd) {
                // A request has completed, but a row doesn't yet exist for this data. Create a new one!
                dispatch(sourceDataRowAdded(resource, responseData, true))
                updatedRowData = responseData
            } else {
                updatedRowData = {
                    ...currentRowData,
                    ...responseData,
                    gridId,
                }
                dispatch(lockSourceDataEditing({ lock: false, gridIds: [gridId] }))
                dispatch(sourceDataRowUpdated(resource, updatedRowData))
            }
            if (
                getFlagEnabled("WA-8087-custom-timekeeping-statuses") &&
                context?.settings?.otherSettings?.customSaveCallback
            ) {
                context?.settings?.otherSettings?.customSaveCallback(dispatch, context, updatedRowData)
            }
            if (resource !== "companyGroups") {
                /**
                 * For the particular case of creating / updating groups, we won't clear the groups filter
                 * since the page depends on the groups filter for navigation.
                 */
                // Clear filters, if necessary
                const filterResource = resource as tFilterResourceName
                const refToRelated = referenceableToRelatedFilters.get(filterResource)
                if (refToRelated) {
                    const { defaultFilterKey } = refToRelated
                    dispatch(clearRelatedFilter(defaultFilterKey))
                }
            }
        },
        (responseData: any) => {
            dispatch(setSaveStatus("failed"))
            if (gridId != null) dispatch(lockSourceDataEditing({ lock: false, gridIds: [gridId] }))
            return dispatch(
                handleApiErrorResponse(rowData, responseData, resource, "Invalid Input", undefined, context)
            )
        }
    )
}

/**
 * Bulk update data.
 * @param {*} action API action to perform.
 * @param {string} resource Resource name.
 * @param {Object[]} data Data to be updated.
 * @param {boolean} flatten Flatten the data before sending it to the back-end
 * @param {Function} [callback] Callback function after successfully updating the data.
 * @param {Function} [getDataKey] Get each data key, used to add gridId back to each data after the response.
 * @param {string} [sortBy] Field to sort the resulting records by
 */
export const bulkUpdateAndHandleResponse = (
    action: tRowAPICallable,
    resource: string,
    data: Array<any>,
    flatten = true,
    callback?: () => void,
    getDataKey?: (item: any) => string,
    sortBy?: string
): Thunk<Promise<void> | void> => (dispatch, getState) => {
    const gridIds = data.map(item => item.gridId)
    if (isDataLocked(gridIds, getState().sourceData.lockedGridIds)) return

    // Lock the modified rows in AG Grid table, so they cannot be modified again during the request.
    dispatch(lockSourceDataEditing({ lock: true, gridIds }))
    dispatch(setSaveStatus("in-progress"))
    const dataToUpdate = flatten ? data.map(d => flattenData(d)) : data
    return Promise.resolve(action(resource, dataToUpdate))
        .then((responseData: any) => {
            dispatch(setSaveStatus("saved"))
            if (callback) callback()
            if (!responseData || !Object.keys(responseData).length || !getDataKey) return

            const dataKeyToGridId = data.reduce((map, item) => {
                map[getDataKey(item) as any] = item.gridId
                return map
            }, {})
            const newDataWithGridId = responseData.map((item: any) => ({
                ...item,
                gridId: dataKeyToGridId[getDataKey(item)],
            }))
            dispatch(sourceDataBulkUpdated({ [resource]: newDataWithGridId }, sortBy))
        })
        .catch((errors: any) => {
            dispatch(setSaveStatus("failed"))
            /**
             * To display tooltip messages & highlight rows with errors,
             * errors.response.data includes the original data with "errors" prop in each data object.
             * - An example of errors.response.data:
             * [
             *  {id: 1, name: "1", errors: {"/name": ["Name is invalid."]}},
             *  {id: 2, name: "2", errors: {"/id": ["This id already exists."]}}
             * ]
             */
            dispatch(sourceDataBulkUpdated({ [resource]: errors.response.data }))
        })
        .finally(() => {
            // Unlock locked rows in AG Grid.
            dispatch(lockSourceDataEditing({ lock: false, gridIds }))
        })
}

/**
 * Validate columns of source data using their colDefs
 */
const validateColumns = (
    data: tResourceObject,
    colDefs: iRmbxColDef[],
    context: tContext | null,
    allRowData?: tResourceObject[] | null
): tResourceObject => {
    colDefs.forEach((colDef: iRmbxColDef) => {
        if (colDef.editable && colDef.cellEditorParams && colDef.cellEditorParams.validators) {
            const field = colDef.field as string
            const valueGetter = colDef.valueGetter as { (params: any): any }
            const value = valueGetter ? valueGetter({ data, colDef, context }) : data[field]
            colDef.cellEditorParams.validators.forEach((validator: any) => {
                if (!validator(value, allRowData, field, data.gridId, data)) {
                    if (data.alerts[field] === undefined) data.alerts[field] = []
                    if (data.errors[field] === undefined) data.errors[field] = []

                    const headerName = colDef.headerName || ""
                    const friendlyName = headerName.replace(/\s*\*.*/, "")
                    data.alerts[field].push(`${friendlyName} ${validator.error_message}`)
                }
            })
        }
    })
    return data
}

/**
 * Validate a row of source data using its colDefs
 */
export const validateRow = (
    data: tResourceObject,
    colDefs: iRmbxColDef[],
    rowLevelValidators: { (node: tResourceObject, context: tContext): boolean }[] | null,
    context: tContext | null,
    allRowData?: tResourceObject[] | null
): tResourceObject => {
    data = validateColumns(data, colDefs, context, allRowData)
    if (rowLevelValidators) {
        rowLevelValidators.forEach((rowValidator: any) => {
            data = rowValidator(data, context)
        })
    }
    return data
}

/**
 * Dispatch actions to update a row of source data
 */
export const updateSourceDataRow = (
    resource: tResourceName,
    rowData: tResourceObject,
    colDefs: iRmbxColDef[],
    rowLevelValidators: { (node: tResourceObject): boolean }[] | null,
    suppressAutoSync: boolean,
    context: tContext | null,
    allRowData: tResourceObject[] | null,
    skipValidation: boolean,
    oldValue?: tJSONValue,
    newValue?: tJSONValue,
    field?: string
): AsyncThunk<void | RowErrorData> => async dispatch => {
    let data: tResourceObject = { ...rowData, errors: {}, alerts: {} }
    const { gridId } = data
    // Allow dispatches of this action to be awaited,
    // e.g. the UI needs to update after the write to the API has completed.
    let writePromise
    if (!skipValidation) data = validateRow(data, colDefs, rowLevelValidators, context, allRowData)

    if (!suppressAutoSync && data.errors && Object.keys(data.errors).length) {
        dispatch(setSaveStatus("failed"))
        // need to call this to get error highlighting to appear
        if (context?.updateSourceDataCb) {
            context.updateSourceDataCb({ [resource]: [data] }, false, field, oldValue, newValue)
        } else dispatch(sourceDataRowUpdated(resource, data))
    } else {
        if (suppressAutoSync) {
            if (context?.updateSourceDataCb) {
                context.updateSourceDataCb({ [resource]: [data] }, false, field, oldValue, newValue)
            } else {
                dispatch(sourceDataRowUpdated(resource, { ...data }))
            }
        } else if (data.newRow) {
            writePromise = dispatch(writeToApiAndHandleResponse(createRow, resource, data, context))
            if (gridId != null) dispatch(lockSourceDataEditing({ lock: true, gridIds: [gridId] }))
        } else {
            writePromise = dispatch(writeToApiAndHandleResponse(updateRow, resource, data, context))
        }
    }

    return writePromise
}

/**
 * Dispatch actions to delete a row of source data
 */
export const deleteSourceDataRow = (
    resource: string,
    rowToDelete: tResourceObject,
    suppressAutoSync: boolean,
    isSSRM?: boolean
): AsyncThunk => dispatch => {
    if (!rowToDelete.newRow && !suppressAutoSync) {
        dispatch(setSaveStatus("in-progress"))
        return Promise.resolve(deleteRow(resource, rowToDelete)).then(
            () => {
                dispatch(setSaveStatus("saved"))
                if (isSSRM && rowToDelete.newRow !== undefined) {
                    dispatch(SSRMRowDeleted(rowToDelete))
                } else dispatch(sourceDataRowDeleted(resource, rowToDelete))
            },
            responseData => {
                dispatch(setSaveStatus("failed"))
                dispatch(handleApiErrorResponse(rowToDelete, responseData, resource, "Failed to delete row."))
            }
        )
    }

    if (isSSRM)
        return new Promise(() => {
            dispatch(SSRMRowDeleted(rowToDelete))
        })
    else
        return new Promise(() => {
            dispatch(sourceDataRowDeleted(resource, rowToDelete))
        })
}

/**
 * Method to update a row within a Server Side Row Mode AG Grid table. The ideas are similar to a
 * client-side table, but since we're not storing the bulk of the table data in the redux store,
 * the actions to handle it are a bit different. We still go to the store for rows that have been
 * created since the table component was mounted, but for the rest we just make api calls and update
 * the AG Grid node
 * @param resource: Which type of object are we dealing with
 * @param rowData: The Row data from the table
 * @param colDefs: Table column definitions (used for validation)
 * @param rowLevelValidators: A list of validators for the row data
 * @param context: The AG Grid context (for accessing things like settings)
 * @param allRowData: Not entirely sure, but apparently it's all of the row data. This may break if
 * we're expecting the entire table to be loaded and we're validating against that
 * @param skipValidation: Whether to skip validation or not
 * @param node: The node being modified. Used for updating AG Grid
 * @param updateOnSave: Whether to update the ag grid table on save or not
 */
export const updateSSRMDataRow = (
    resource: string,
    rowData: tResourceObject,
    colDefs: iRmbxColDef[],
    rowLevelValidators: { (node: tResourceObject): boolean }[] | null,
    context: tContext | null,
    allRowData: tResourceObject[] | null,
    skipValidation: boolean,
    node?: RowNode | null,
    updateOnSave?: boolean
): AsyncThunk<void | RowErrorData> => async dispatch => {
    const data: tResourceObject = skipValidation
        ? { ...rowData, errors: {} }
        : validateRow({ ...rowData, errors: {} }, colDefs, rowLevelValidators, context, allRowData)

    // Figure out if we're ready to create/update via the API
    if (!(data.errors && Object.keys(data.errors).length)) {
        let writePromise: Promise<void | RowErrorData>
        if (data.newRow) {
            writePromise = dispatch(SSRMWriteToApi(createRow, resource, data, context, node, updateOnSave))
            if (data.gridId != null) {
                dispatch(lockSourceDataEditing({ lock: true, gridIds: [data.gridId] }))
            }
        } else {
            writePromise = dispatch(SSRMWriteToApi(updateRow, resource, data, context, node, updateOnSave))
        }
        return writePromise
    } else {
        if (node) node.setData(data)
        dispatch(setSaveStatus("failed"))
    }
}

/**
 * Dispatch actions to handle POST/PUT requests to create or update source
 * data, then handle the response (including errors)
 * @param action: which action the api should take (create or update)
 * @param resource: which type of entity is being messed with
 * @param rowData: the row data from the table
 * @param context: the AG Grid context (for checking things like settings)
 * @param node: the AG Grid node object that is being updated/added
 * @param updateOnSave: Should the ag grid table be updated
 */
const SSRMWriteToApi = (
    action: tRowAPICallable,
    resource: string,
    rowData: tResourceObject,
    context: any,
    node?: any,
    updateOnSave?: boolean
): AsyncThunk<void | RowErrorData> => (dispatch, getState) => {
    /**
     * When handling an update, merge response data with current data so that
     * annotation data fetched when the table was initially loaded doesn't get lost
     * during individual updates. Don't keep certain frontend-specific fields around.
     */
    const { gridId } = rowData
    if (isDataLocked([gridId], getState().sourceData.lockedGridIds)) return Promise.resolve()

    dispatch(setSaveStatus("in-progress"))
    return Promise.resolve(action(resource, flattenData(rowData))).then(
        (responseData: any) => {
            dispatch(setSaveStatus("saved"))
            const newNodeData = { ...responseData, gridId, newRow: rowData.newRow }
            // Only alter what's in the store if this row has ever been a new row
            if (newNodeData.newRow !== undefined) {
                if (gridId) {
                    dispatch(lockSourceDataEditing({ lock: false, gridIds: [gridId] }))
                }
                newNodeData.newRow = false
                // For the Projects table at least, when a new record is added, it gets added
                // straight to the API via the RR, so we need to trigger the table to refresh in that case
                if (updateOnSave) dispatch(performDataRefresh(true))
                else dispatch(SSRMRowSaved(resource, newNodeData))
            } else {
                if (updateOnSave) dispatch(performDataRefresh(true))
            }

            // Update AG Grid so it's happy
            if (node) node.setData(newNodeData)

            /**
             * For the particular case of creating / updating groups, we won't clear the groups filter
             * since the page depends on the groups filter for navigation.
             */
            if (resource !== "companyGroups") {
                // Clear filters, if necessary
                const filterResource = resource as tFilterResourceName
                const refToRelated = referenceableToRelatedFilters.get(filterResource)
                if (refToRelated) {
                    const { defaultFilterKey } = refToRelated
                    dispatch(clearRelatedFilter(defaultFilterKey))
                }
            }
        },
        (responseData: any) => {
            dispatch(setSaveStatus("failed"))
            // changing the lock state causes the entire table to flash, so we try to
            // only do it when we really need to
            if (gridId != null && rowData.newRow)
                dispatch(lockSourceDataEditing({ lock: false, gridIds: [gridId] }))
            return dispatch(handleApiErrorResponse(rowData, responseData, resource, "Invalid Input", node, context))
        }
    )
}
