/*global FS, google, Rmbx*/
/*eslint no-prototype-builtins: "error"*/

import { LOGGED_IN, logUserAmplitudeEvent, setAmplitudeUser } from "./common/amplitude-event-logging"
import { setIntercomUser, unsetIntercomUser } from "./common/intercom-event-logging"
import { referenceableValueKeyOverrides } from "./common/constants"
import { getCookie } from "./common/ts-utils"

export const getAuthToken = () => getCookie("token") ?? rmbx.store.get("token")
export const getAuthType = () => getCookie("authorization_type") ?? rmbx.store.get("authorization_type")

/**
 * RMBX Util obj
 * Responsible for app wide utilities, helpers and managing client store
 */
const rmbx = {
    autocomplete: buildAutocompleteUtil(),
    util: {
        // Given a JSON response from the API server, return an object with the shape
        // `{ entities: { <resourceName>: {} }, result: [] }`,` where `entities` is an
        // object containing all of the server's response data keyed by resource name
        // and id, and `result` is the list of object IDs in the order the server
        // returned them in.
        formatApiResponse: (response, resourceName) => {
            const result = []
            const entities = {}
            let objs = response

            if (!objs) {
                return { result: [], entities: {} }
            }

            entities[resourceName] = {}
            // we need to handle the case where the response is a single object instead of a list
            if (response.id) {
                objs = [response]
            }
            objs.forEach(obj => {
                const valueKeyField = referenceableValueKeyOverrides[resourceName]
                    ? referenceableValueKeyOverrides[resourceName]
                    : "id"
                const valueKeyValue = obj[valueKeyField]
                result.push(valueKeyValue)
                entities[resourceName][valueKeyValue] = obj
            })
            return { result, entities }
        },
        history: {
            /**
             * Redirect to the given URL. Depends on rmbx.util.setup() having been executed first
             * This _url param looks unused but is needed, we use it to navigate/redirect in the cases of
             * custom forms creation, project creation and wherever we call table.ts > navigateTo
             */
            push: _url => {
                const historyArr = rmbx.store.get("history") || []
                historyArr.push(window.location.pathname)
                rmbx.store.set("history", historyArr)
            },
        },
        // Returns true if the value can be parsed as a finite number
        // https://github.com/jonschlinkert/is-number/
        isNumber: num => {
            if (typeof num === "number") {
                return num - num === 0
            }
            if (typeof num === "string" && num.trim() !== "") {
                return Number.isFinite ? Number.isFinite(+num) : isFinite(+num)
            }
            return false
        },
        setup: function () {
            // setup the current session
            rmbx.util.setupSession()
            rmbx.store.removeItem = rmbx._store.removeItem
        },
        setupSession: function (is_permanent_session) {
            const token = getAuthToken()
            if (is_permanent_session || window.localStorage.getItem("token") || token) {
                // migrate value of URL which unauthorized user requested when session was started to storage in use
                const attempted_url = rmbx.store.get("attempted_url")
                if (attempted_url) {
                    rmbx.store.set("attempted_url", null)
                }
                rmbx._store = window.localStorage
                if (attempted_url) {
                    rmbx.store.set("attempted_url", attempted_url)
                }
            } else {
                if (
                    getCookie("shared_token") &&
                    !token &&
                    window.location.pathname?.startsWith("/rhumbix/custom-form/")
                ) {
                    window.sessionStorage.setItem("token", `"${getCookie("shared_token")}"`)
                    window.sessionStorage.setItem("authorization_type", `"${getCookie("shared_auth_type")}"`)
                }
                rmbx._store = window.sessionStorage
            }
        },
        destroySession: function () {
            rmbx._store.clear()
        },
        serializeQueryParams: function (obj) {
            const params = []
            const parameterValidator = (parameterName, parameterValue) => {
                // don't serialize if the value is an empty string, null or undefined.
                if (
                    (typeof parameterValue === "string" && !parameterValue) ||
                    typeof parameterValue === "undefined" ||
                    parameterValue === null
                ) {
                    return
                }
                if (Array.isArray(parameterValue)) {
                    return parameterValue.forEach(element => parameterValidator(parameterName, element))
                }
                params.push(`${encodeURIComponent(parameterName)}=${encodeURIComponent(parameterValue)}`)
            }

            for (const p in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, p)) {
                    parameterValidator(p, obj[p])
                }
            }

            // Alphabetize the parameters so the exact output is predictable
            return params.sort().join("&")
        },
        isEmailInvalid: function (email) {
            const re = new RegExp(
                [
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@/,
                    /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                ]
                    .map(r => {
                        return r.source
                    })
                    .join("")
            )
            return !email || !re.test(String(email).toLowerCase())
        },
    },
    store: {
        get: function (key) {
            if (rmbx._store && rmbx._store.getItem(key)) {
                return JSON.parse(rmbx._store.getItem(key))
            }
        },
        set: function (key, val) {
            rmbx._store.setItem(key, JSON.stringify(val))
        },
    },
    tracker: buildTrackerUtil(),
}

/**
 * Rmbx autocomplete utility - expects a dom element with the 'autocomplete' id
 * There is a secondary address only input with the id `addrOnlyAutocomplete` if two inputs needed on same page
 *
 * Reference:
 * https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform
 */
function buildAutocompleteUtil() {
    "use strict"

    const ns = {}

    let autocomplete
    let addrOnlyAutocomplete

    const apiKey = "AIzaSyC-KJFWI8J6s1nErQ5y0hzO-Lulwuv9wAM"

    /**
     * Utils namespace on load function - add here any dependencies when app first loads
     */
    ns.load = function (options = {}) {
        // make sure we only load this script once
        if (document.getElementById("google-maps-place-library") !== null) {
            Rmbx.autocomplete.init(options)
            return
        }

        // load the GoogleApi lib and append to the body
        const script = document.createElement("script")
        script.id = "google-maps-place-library"
        script.type = "text/javascript"
        script.src = "https://maps.googleapis.com/maps/api/js?key=" + apiKey + "&libraries=places&callback=initMap"
        script.async = "async"
        script.defer = "defer"

        document.body.appendChild(script)

        window.initMap = () => ns.init(options)
    }

    /**
     * Init the autocomplete through GoogleMaps Places API
     */
    ns.init = function ({
        onAddressChanged,
        onTimezoneChanged,
        onLatitudeChanged,
        onLongitudeChanged,
        secondOnAddressChanged,
    }) {
        // Create the autocomplete object, restricting the search to geographical location types.
        // Additionally, Google Maps Places API now throws an error if the DOM element retrieved is
        //  not an instance of HTML Input - so, we can't use text areas for this....technically - JSG
        try {
            autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"), {
                types: ["geocode"],
                // Added fields parameter to specify which fields to return in autocomplete.getPlace()
                // Currently only formatted_address and geometry is being used, so just use
                // these two to reduce billing costs
                fields: ["formatted_address", "geometry"],
            })
            google.maps.event.addListener(autocomplete, "place_changed", function () {
                const place = autocomplete.getPlace()

                // User may trigger a "place_changed" event with an invalid address by pressing "enter".
                if (!place.geometry) return

                if (onAddressChanged) onAddressChanged(place.formatted_address)

                const lat = place.geometry.location.lat()
                const lng = place.geometry.location.lng()

                ns.getTimezone(lat, lng, function ({ timeZoneId }) {
                    if (onTimezoneChanged) onTimezoneChanged(timeZoneId)
                    else document.getElementById("project-timezone").value = timeZoneId
                })

                if (onLatitudeChanged) onLatitudeChanged(lat)
                else document.getElementById("project-latitude").value = lat

                if (onLongitudeChanged) onLongitudeChanged(lng)
                else document.getElementById("project-longitude").value = lng
            })
        } catch (error) {
            FS.log("Could not connect google autocomplete", error)
        }
        try {
            addrOnlyAutocomplete = new google.maps.places.Autocomplete(
                document.getElementById("addrOnlyAutocomplete"),
                {
                    types: ["geocode"],
                    // Added fields parameter to specify which fields to return in autocomplete.getPlace()
                    // Currently only formatted_address is being used since this is address only
                    fields: ["formatted_address"],
                }
            )
            google.maps.event.addListener(addrOnlyAutocomplete, "place_changed", function () {
                const place = addrOnlyAutocomplete?.getPlace()
                if (secondOnAddressChanged && place?.formatted_address)
                    secondOnAddressChanged(place.formatted_address)
            })
        } catch (error) {
            FS.log("Could not connect google address only autocomplete", error)
        }
    }

    ns.getTimezone = function (lat, lng, successCallback) {
        // default settings prevent us from using the google api.
        // Remove the beforeSend that sets the headers and return it once the request is made
        const timestamp = Math.round(new Date().getTime() / 1000)
        const data = {
            key: apiKey,
            timestamp: timestamp,
            location: lat + "," + lng,
        }
        const url = `https://maps.googleapis.com/maps/api/timezone/json?${rmbx.util.serializeQueryParams(data)}`

        // makeRequest was causing problems related to headers, but vanilla xhr works okay
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest()
            xhr.onload = () => {
                resolve(JSON.parse(xhr.response))
            }
            xhr.onerror = () => {
                reject(xhr.response)
            }
            xhr.open("GET", url, true)
            xhr.send()
        }).then(response => {
            successCallback(response)
        })
    }

    /**
     * Bias the autocomplete object to the user's geographical location
     * as supplied by the browser's 'navigator.geolocation' object.
     */
    ns.geolocate = function () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                }
                const circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy,
                })
                autocomplete.setBounds(circle.getBounds())
            })
        }
    }

    return ns
}

/**
 * Rmbx tracker utility
 */
function buildTrackerUtil() {
    "use strict"

    const ns = {}

    /**
     * Switches the identity of the user from anonymous to an existing tracked user
     * This should only be called whenever a user logs in.
     */
    ns.loginUser = function (user) {
        const rmbx_uid = window.rmbx_env + "." + user.company_id + "." + user.user_id
        // http://help.fullstory.com/develop-js/setuservars.
        FS.identify(rmbx_uid, {
            displayName: user.first_name,
            email: user.email,
            created_date: user.date_joined,
            userid_int: user.user_id,
            env_str: window.rmbx_env,
            userrole_str: user.user_role,
            companyid_int: user.company_id,
            company_str: user.company,
        })
        FS.log("User Logged In")

        // we want to track user activity in amplitude
        setAmplitudeUser(user)
        // add a specific event to monitor log ins
        logUserAmplitudeEvent(LOGGED_IN)

        // set the user in intercom
        setIntercomUser(user)
    }

    ns.logout = function () {
        FS.log("User Logged Out")
        FS.identify(false)

        unsetIntercomUser()
    }

    return ns
}

export default rmbx
