import PivotHeader from "../custom-headers/pivot-header"
import TimecardCellRenderer from "../cell-renderers/timecard-cell-renderer"
import DefaultCellRenderer from "../cell-renderers/default-cell-renderer"
import GroupRowFormattingDefaultCellRenderer from "../cell-renderers/GroupRowFormattingDefaultCellRenderer"
import { pivotValueValueGetter } from "../../../common/ag-grid-value-getters"
import { dateAndDefaultTimeValueSetter } from "../../../common/ag-grid-value-setters"
import {
    isGroupRow,
    timekeepingDataIsEditable,
    timekeepingRowIsEditable,
    getAvailableStatusValues,
    alertCellClassRule,
    addRowTableAction,
    addEmployeeRowsTableAction,
    editTimecardDetailsAction,
    canPasteIntoCell,
} from "../../../common/ag-grid-utils"
import {
    lockedPeriodPivotCellClassRule,
    lockedPeriodCellClassRule,
    findShiftRowData,
    doesShiftEndingNextDayExist,
} from "../../../common/ag-grid-ts-utils"
import { filterFormatter, simpleTimeValueFormatter } from "../../../common/ag-grid-value-formatters"
import { statusesByUserRole, weeklyTkDummyProject } from "../../../common/constants"
import { compareGroupColumns, compareTimekeepingEntryObjects } from "../../../common/ag-grid-comparators"
import {
    pivotTotalColumnCustomTooltipMessageBuilder,
    pivotValueColumnCustomTooltipMessageBuilder,
    timekeepingDisabledTooltip,
    timekeepingEntryGeneralErrorsTooltip,
} from "../../../common/ag-grid-custom-tooltip-formatters"
import {
    checkboxColDefCreator,
    companyAbsenceTypeColDefCreator,
    costCodeColDefCreator,
    dateColDefCreator,
    editableTimeCardColDefCreator,
    employeeColDefCreator,
    foremanColDefCreator,
    projectColDefCreator,
    stringColDefCreator,
    timekeepingStatusColDefCreator,
    timeCardColDefCreator,
    datetimeColDefCreator,
    companyStartStopTypeColDefCreator,
} from "./standard-col-def-creators"
import {
    weekUpdatedNameFilterDef,
    multiProjectFilterDef,
    projectStatusFilterDef,
    multiGroupFilterDef,
    laborTypeFilterDef,
    tradeFilterDef,
    classificationFilterDef,
    employeeFilterDef,
    timeCardOwnerFilterDef,
    tkEntryStatusFilterDef,
    multiCohortFilterDef,
    customTkEntryStatusFilterDef,
} from "../../../filters/filter-defs"
import { getFlagEnabled } from "../../../getFlagValue"
import { loadAllEntities } from "../../../cached-data/actions"
import { selectedCellHasAllSyncedData, selectedCellUnableToTransition } from "../utils"
import { TableIds } from "../../../common/table-identifier-utils"
import { addDays, addMinutes, differenceInMinutes } from "date-fns"
import {
    getSignatureRequestTooltip,
    getSignatureTooltip,
    singleEmployeeIsSelected,
} from "../../../actions/timekeeping-signatures"
import { filterData } from "../../../common/ag-grid-grouping-utils"
import { getAsDate } from "../../../common/ts-utils"
import { getTimekeepingEntryTable } from "./timekeeping-entries"
import { isNumber } from "../../../common/validators"
import { getSelectorActionButtons } from "./action-button-settings"
import { textFieldOptionCreator } from "../../../filters/filter-def-creators"

const getLocalWorkshiftDate = params => {
    let date = new Date(params.data?.date)
    date = addMinutes(date, date.getTimezoneOffset())
    return date
}

const getLocalWorksshiftStartOrStop = params => {
    if (getFlagEnabled("WA-7827-date-picker-default") && params.data?.company_start_stop_type?.is_break === false) {
        if (params.colDef.field === "/start_time") return getAsDate(params.data.shift_start_time)
        else if (params.colDef.field === "/stop_time") return getAsDate(params.data.shift_end_time)
    }
    return null
}

const getFindConfigForCustomField = option => {
    const tkSources = ["timekeepingEntries", "absences", "employeeEntries", "ewsStartStopTimes"]
    const sourceField = option.model === "Employee" ? "/employee" : "/project"
    const resource = option.model === "Employee" ? "employees" : "projects"
    return {
        dropdownDisplayName: `${option.model} ${option.label}`,
        textInputPlaceholder: `Find a ${option.label}`,
        resources: tkSources.map(sourceType => ({
            sourceType,
            sourceField,
            reference: {
                resource,
                fields: [`/custom_fields/${option.name}`],
            },
        })),
    }
}

export function getWeeklyTimeCardSettings(featureFlags, currentProject, user, currentProjectIds, sourceData) {
    const userRole = user ? user.user_role : undefined
    const groupFilter = multiGroupFilterDef

    const textFieldOptions =
        getFlagEnabled("WA-8402-custom-text-fields") && user && user.text_field_options
            ? user.text_field_options
            : []

    const employeeTextFieldOptionFilterDefs = textFieldOptions
        .filter(option => option.model === "Employee")
        .map(option => textFieldOptionCreator(`Employee ${option.label}`, option.model, option.name))

    const projectTextFieldOptionFilterDefs = textFieldOptions
        .filter(option => option.model === "Project")
        .map(option => textFieldOptionCreator(`Project ${option.label}`, option.model, option.name))

    const textFieldFindConfigs = Object.fromEntries(
        textFieldOptions
            .filter(option => ["Employee", "Project"].includes(option.model))
            .map(option => [`${option.model}_${option.name}`, getFindConfigForCustomField(option)])
    )

    const pivotFlex = getFlagEnabled("WA-8428-weekly-view-column-resize") ? { flex: 1 } : {}
    const pivotTotalFlex = getFlagEnabled("WA-8428-weekly-view-column-resize") ? { flex: 1.5 } : {}

    const filters = [
        weekUpdatedNameFilterDef,
        groupFilter,
        projectStatusFilterDef,
        multiProjectFilterDef,
        ...projectTextFieldOptionFilterDefs,
        timeCardOwnerFilterDef,
        tradeFilterDef,
        classificationFilterDef,
        ...(featureFlags.labor_type_filter_visible ? [laborTypeFilterDef] : []),
        employeeFilterDef,
        multiCohortFilterDef,
        ...employeeTextFieldOptionFilterDefs,
        ...(getFlagEnabled("WA-8087-custom-timekeeping-statuses")
            ? [customTkEntryStatusFilterDef]
            : [tkEntryStatusFilterDef]),
    ]

    const showHidePlaceholders = [
        {
            label: "Show Placeholders",
            action: "togglePlaceholders",
            icon: "view",
            customButtonType: "placeholders",
        },
    ]

    const groupByConfiguration = [
        {
            cols: ["/employee", "/cost_code"],
            label: "Employee",
        },
        {
            cols: ["/foreman", "/employee", "/cost_code"],
            label: "Time Card Owner",
        },
        {
            cols: ["/project", "/foreman", "/employee", "/cost_code"],
            label: "Project",
        },
        {
            cols: ["/classification", "/employee", "/cost_code"],
            label: "Classification",
        },
        {
            cols: ["/trade", "/employee", "/cost_code"],
            label: "Trade",
        },
        {
            cols: ["/status", "/foreman", "/employee", "/cost_code"],
            label: "Status",
        },
        {
            cols: ["/cost_code", "/employee"],
            label: "Cost Code",
        },
        {
            cols: ["/foreman", "/work_shift_id", "/employee", "/cost_code"],
            label: "Time Card",
        },
    ]

    // toggle on or shifts_and_breaks data exists
    const shouldShowShiftsAndBreaks =
        featureFlags.shifts_and_breaks || sourceData?.sourceData?.ewsStartStopTimes?.length

    const employeeSearchFields = getFlagEnabled("WA-8310-fancy-search-full-name")
        ? [
              "/first_name",
              "/last_name",
              "/company_supplied_id",
              "concat(/first_name, /last_name)",
              "concat(/last_name, /first_name)",
          ]
        : ["/first_name", "/last_name", "/company_supplied_id"]

    const groupByTableAction = {
        label: "Group By",
        action: "groupBy",
        icon: "group",
    }

    return {
        tableName: "Weekly Time Card Beta",
        navId: "pivot",
        // TODO: rename this to sourceData? row data? rename sourceData to rowData??
        resources: ["timekeepingEntries", "absences", "employeeEntries", "ewsStartStopTimes"],
        filters,
        additionalQueryParams: { for_dashboard: true },
        forceRefIds: { employees: user ? [user.employee_id] : [] },
        referenceablesWithStringIds: ["timekeepingStatuses"],
        relatedNames: {
            timekeepingEntries: {
                companyClassifications: ["/modifier_active/classification"],
                companyTrades: ["/modifier_active/trade"],
                costCodes: ["/cost_code"],
                employees: ["/employee", "/foreman"],
                equipment: ["/modifier_active/equipment"],
                ...(getFlagEnabled("WA-7200-Custom-Modifiers")
                    ? {
                          picklistItems: (user.company_tk_modifiers ?? [])
                              .filter(m => m.standard_tk_modifier_type == null)
                              .map(m => `/modifier_active/${m.slug}`),
                      }
                    : {}),
                projects: ["/project"],
                workShifts: ["/work_shift_id"],
            },
            absences: {
                employees: ["/employee", "/foreman"],
                projects: [],
                companyAbsenceTypes: ["/absence_type"],
                workShifts: ["/work_shift_id"],
            },
            employeeEntries: {
                employees: ["/employee", "/foreman"],
                costCodes: ["/store/code/id"],
                employeeSchemas: ["/schema"],
                equipment: ["/store/equipment/id"],
                projects: ["/project"],
                workShifts: ["/work_shift_id"],
            },
            ewsStartStopTimes: {
                employees: ["/employee", "/foreman"],
                workShifts: ["/work_shift_id"],
                companyStartStopTypes: ["/company_start_stop_type"],
            },
        },
        // TODO:  Potentially have performance issues for companies with a lot of equipment. Needs revisiting.
        initAction: () => dispatch => {
            // Load equipment so that we can load them for the timekeeping modal header
            if (featureFlags.cost_code_level_modifiers && !getFlagEnabled("WA-7952-weekly-view-no-preload"))
                dispatch(loadAllEntities("equipment"))
        },
        colDefs: [
            {
                ...dateColDefCreator(),
                valueSetter: dateAndDefaultTimeValueSetter,
                pivot: true,
            },
            timeCardColDefCreator(),
            {
                headerName: "Time Card",
                field: "/work_shift_id",
                referenceableMap: {
                    referenceableKey: "workShifts",
                    rowKey: "workShiftId",
                },
                cellRendererParams: {
                    titleFormatter: params => (params.value ? params.value : "-"),
                },
                cellRenderer: DefaultCellRenderer,
            },
            foremanColDefCreator({
                headerName: "Time Card Owner",
                moreDetail: true,
            }),
            {
                ...employeeColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            stringColDefCreator({
                headerName: "Classification",
                field: "/classification",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
            }),
            projectColDefCreator({ moreDetail: true }),
            {
                ...costCodeColDefCreator({ moreDetail: true }),
                rowGroup: true,
            },
            {
                headerName: "Trade",
                field: "/trade",
                referenceableMap: {
                    referenceableKey: "employees",
                    rowKey: "employee",
                },
                cellRendererParams: {
                    titleFormatter: params => (params.value ? params.value : "-"),
                },
                // TODO: there are other similar features in the pipeline, figure out the best way to
                // handle this as we build those
                filterColumnOf: "employee",
                cellRenderer: DefaultCellRenderer,
                editableValueGetter: params => params.context.referenceableData.employees[params.data.employee],
                filterQueryParam: "employee_trade",
            },
            timekeepingStatusColDefCreator({
                enum: getAvailableStatusValues(userRole),
            }),
            {
                headerName: "Value",
                minWidth: 107,
                aggFunc: "aggregateObject",
                cellRenderer: TimecardCellRenderer,
                valueGetter: pivotValueValueGetter,
            },
        ],
        gridSettings: {
            pivotHeaderHeight: 60,
            rowHeight: 80,
            pivotMode: true,
            pivotRowTotals: "after",
            autoGroupColumnDef: {
                headerName: "Employee", // might need an OtherSetting to make this based off of vars in the grid
                suppressMenu: true, // this too
                sort: "asc",
                unSortIcon: true,
                cellRendererParams: {
                    suppressCount: true,
                },
                comparator: compareGroupColumns,
                minWidth: 250,
                storeWidth: getFlagEnabled("WA-8428-weekly-view-column-resize"),
            },
            defaultColDef: {
                getQuickFilterText: filterFormatter,
            },
            rowSelection: "multiple",
            // hide the group header, we don't like it
            pivotGroupHeaderHeight: 0,
            rowBuffer: 40,
        },
        otherSettings: {
            forceWeeklyPivotColumns: true,
            flexColumns: getFlagEnabled("WA-8428-weekly-view-column-resize"),
            pivotColDef: {
                headerComponent: PivotHeader,
                headerComponentParams: {
                    subtitles: ["ST", "OT", "DT"],
                },
                minWidth: 107,
                customTooltip: pivotValueColumnCustomTooltipMessageBuilder,
                comparator: compareTimekeepingEntryObjects,
                dayOfWeekHeaderName: true,
                cellClassRules: {
                    "period-locked": lockedPeriodPivotCellClassRule,
                    readonly: false,
                },
                editable: () => true,
                ...pivotFlex,
            },
            pivotRowTotalColDef: {
                minWidth: 200,
                customTooltip: pivotTotalColumnCustomTooltipMessageBuilder,
                headerComponent: PivotHeader,
                headerComponentParams: {
                    subtitles: ["All", "ST", "OT", "DT"],
                    classes: ["total-header"],
                },
                headerName: "Total",
                excludeFromFind: true,
                ...pivotTotalFlex,
            },
            enableClipboard: true,
            skipLoadOnMount: getFlagEnabled("WA-7952-weekly-view-no-preload"),
            findConfig: {
                // The first set of keys correspond to the dropdown options next to the search bar
                employee: {
                    // We provide a display name for the dropdown option
                    dropdownDisplayName: "Employee",
                    textInputPlaceholder: "Find name or ID#",
                    // list resources which are to be searched in order to find a match
                    // on this type of object.
                    resources: [
                        {
                            // Which type of entity are we looking a?
                            sourceType: "timekeepingEntries",
                            // Which field on this entity type do we look at
                            sourceField: "/employee",
                            // Optionally, we use this field to reference another object (i.e. if the api contains
                            // only the object ID, but we also pulled that related object into the store).
                            // The section below describes which entity type we reference and which fields
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                    ],
                },
                // The first set of keys correspond to the dropdown options next to the search bar
                workshift: {
                    // We provide a display name for the dropdown option
                    dropdownDisplayName: "Time Card ID",
                    textInputPlaceholder: "Find ID#",
                    // list resources which are to be searched in order to find a match
                    // on this type of object.
                    resources: [
                        {
                            // Which type of entity are we looking a?
                            sourceType: "timekeepingEntries",
                            // Which field on this entity type do we look at
                            sourceField: "/work_shift_id",
                            // Optionally, we use this field to reference another object (i.e. if the api contains
                            // only the object ID, but we also pulled that related object into the store).
                            // The section below describes which entity type we reference and which fields
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/work_shift_id",
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/work_shift_id",
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/work_shift_id",
                            reference: {
                                resource: "workShifts",
                                fields: ["/id"],
                            },
                        },
                    ],
                },
                foreman: {
                    dropdownDisplayName: "Time Card Owner",
                    textInputPlaceholder: "Find name or ID#",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: employeeSearchFields,
                            },
                        },
                    ],
                },
                project: {
                    dropdownDisplayName: "Project",
                    textInputPlaceholder: "Find name or job#",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/project",
                            reference: {
                                resource: "projects",
                                fields: ["/job_number", "/name"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/project",
                            reference: {
                                resource: "projects",
                                fields: ["/job_number", "/name"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/project",
                            reference: {
                                resource: "projects",
                                fields: ["/job_number", "/name"],
                            },
                        },
                    ],
                },
                classification: {
                    dropdownDisplayName: "Classification",
                    textInputPlaceholder: "Find a classification",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/modifier_active/classification",
                            reference: {
                                resource: "companyClassifications",
                                fields: ["/code", "/name"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/classification"],
                            },
                        },
                    ],
                },
                trade: {
                    dropdownDisplayName: "Trade",
                    textInputPlaceholder: "Find a trade",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/modifier_active/trade",
                            reference: {
                                resource: "companyTrades",
                                fields: ["/name", "/code"],
                            },
                        },
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/employee",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/foreman",
                            reference: {
                                resource: "employees",
                                fields: ["/trade"],
                            },
                        },
                    ],
                },
                status: {
                    dropdownDisplayName: "Status",
                    textInputPlaceholder: "Find a status",
                    enum: statusesByUserRole.map(status => ({ label: status.label, value: status.name })),
                    resources: [
                        // Without the "reference" section, we only search through the sourceField
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/status",
                        },
                        {
                            sourceType: "absences",
                            sourceField: "/status",
                        },
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/status",
                        },
                        {
                            sourceType: "ewsStartStopTimes",
                            sourceField: "/status",
                        },
                    ],
                },
                costCode: {
                    dropdownDisplayName: "Cost Code",
                    textInputPlaceholder: "Find a cost code name or description",
                    resources: [
                        {
                            sourceType: "timekeepingEntries",
                            sourceField: "/cost_code",
                            reference: {
                                resource: "costCodes",
                                fields: ["/code", "/description"],
                            },
                        },
                    ],
                },
                absence: {
                    dropdownDisplayName: "Absences",
                    textInputPlaceholder: "Any absence",
                    enumResource: "companyAbsenceTypes",
                    enumField: "/name",
                    // When the category is selected, we automatically filter
                    // out empty values
                    autoFilterEmptyValues: true,
                    resources: [
                        {
                            sourceType: "absences",
                            sourceField: "/absence_type",
                            reference: {
                                resource: "companyAbsenceTypes",
                                fields: ["/name"],
                            },
                        },
                    ],
                },
                shiftExtra: {
                    dropdownDisplayName: "Shift Extras",
                    textInputPlaceholder: "Any shift extra",
                    enumResource: "employeeSchemas",
                    enumField: "/name",
                    // When the category is selected, we automatically filter
                    // out empty values
                    autoFilterEmptyValues: true,
                    resources: [
                        {
                            sourceType: "employeeEntries",
                            sourceField: "/schema",
                            reference: {
                                resource: "employeeSchemas",
                                fields: ["/name"],
                            },
                        },
                    ],
                },
                ...(shouldShowShiftsAndBreaks
                    ? {
                          ewsStartStopTime: {
                              dropdownDisplayName: "Shifts and Breaks",
                              textInputPlaceholder: "Worker/Time Card Owner name, Shift, Break, Meal, etc.",
                              autoFilterEmptyValues: true,
                              resources: [
                                  {
                                      sourceType: "ewsStartStopTimes",
                                      sourceField: "/start_stop_type",
                                  },
                                  {
                                      sourceType: "ewsStartStopTimes",
                                      sourceField: "/foreman",
                                      reference: {
                                          resource: "employees",
                                          fields: ["/first_name", "/last_name", "/company_supplied_id"],
                                      },
                                  },
                                  {
                                      sourceType: "ewsStartStopTimes",
                                      sourceField: "/employee",
                                      reference: {
                                          resource: "employees",
                                          fields: ["/first_name", "/last_name", "/company_supplied_id"],
                                      },
                                  },
                              ],
                          },
                      }
                    : {}),
                ...(featureFlags.cost_code_level_modifiers
                    ? {
                          modifier: {
                              dropdownDisplayName: "Modifiers",
                              textInputPlaceholder: "Any modifiers...",
                              textInputDisabled: true,
                              // When the category is selected, we automatically filter
                              // out empty values
                              autoFilterEmptyValues: true,
                              resources: [
                                  {
                                      sourceType: "timekeepingEntries",
                                      sourceField: "/modifier_active",
                                  },
                              ],
                          },
                      }
                    : {}),
                ...(featureFlags.timekeeping_comments
                    ? {
                          comment: {
                              dropdownDisplayName: "Comment",
                              textInputPlaceholder: "Any comment",
                              // When the category is selected, we automatically filter
                              // out empty values
                              autoFilterEmptyValues: true,
                              resources: [
                                  {
                                      sourceType: "timekeepingEntries",
                                      sourceField: "/comment",
                                  },
                              ],
                          },
                      }
                    : {}),
                ...textFieldFindConfigs,
            },
            groupBy: groupByConfiguration,
            buttons: {
                cell: [
                    {
                        label: "Update Status ▾",
                        icon: "status",
                        action: "updateTimekeepingStatusForFocusedPivotCell",
                        // If all TK entries have Synced status, disable the action and show a tooltip on hover.
                        disabled: context =>
                            selectedCellHasAllSyncedData(context) ||
                            (getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                                selectedCellUnableToTransition(context)),
                        tooltip: context =>
                            selectedCellHasAllSyncedData(context)
                                ? "Entries with a Synced status may only be updated through the Public API."
                                : getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                                  selectedCellUnableToTransition(context)
                                ? "No transitions available for current selection."
                                : undefined,
                    },
                    {
                        label: "Edit",
                        icon: "edit",
                        action: "editFocusedPivotCell",
                    },
                    {
                        label: "Copy",
                        icon: "copy",
                        action: "copyFocusedPivotCell",
                    },
                    {
                        label: "Paste",
                        icon: "paste",
                        action: "pasteIntoFocusedPivotCell",
                        disabled: context =>
                            getFlagEnabled("WA-8300-tk-conflicting-pivot-paste-lock")
                                ? !canPasteIntoCell(context).canPaste
                                : !canPasteIntoCell(context),
                        tooltip: context =>
                            getFlagEnabled("WA-8300-tk-conflicting-pivot-paste-lock")
                                ? canPasteIntoCell(context).tooltip
                                : canPasteIntoCell(context)
                                ? ""
                                : "You do not have permission to overwrite the contents of this cell",
                    },
                    {
                        label: "Delete",
                        icon: "delete",
                        action: "deleteFocusedPivotCell",
                    },
                    {
                        label: "History",
                        icon: "history",
                        action: "openHistoryModalForFocusedPivotCell",
                    },
                    {
                        label: "Duplicate",
                        icon: "copy",
                        action: "duplicateWeeklyViewCell",
                        disabled: context =>
                            Object.values(context.selectedCellData).reduce((acc, el) => acc + el.length, 0) === 0,
                        tooltip: context =>
                            Object.values(context.selectedCellData).reduce((acc, el) => acc + el.length, 0) === 0
                                ? "Selected cell has no data to duplicate"
                                : "Duplicate selected cell's data to a new date",
                    },
                    ...(getFlagEnabled("WA-5644-tk-signatures") && !featureFlags.disable_web_signature_flow
                        ? [
                              {
                                  label: "Sign",
                                  icon: "signature",
                                  action: "getSignatureOptions",
                                  disabled: !singleEmployeeIsSelected,
                                  tooltip: getSignatureTooltip,
                              },
                          ]
                        : []),
                    ...(getFlagEnabled("WA-5644-tk-signatures") &&
                    featureFlags.text_for_signature &&
                    !featureFlags.disable_web_signature_flow
                        ? [
                              {
                                  label: "Request Signature",
                                  icon: "share",
                                  action: "getSignatureRequestOptions",
                                  tooltip: getSignatureRequestTooltip,
                              },
                          ]
                        : []),
                ],
                row: [],
                table: [
                    {
                        label: "Expand/Collapse All",
                        action: "toggleRowExpansion",
                        icon: "expand",
                    },
                    groupByTableAction,
                    {
                        label: "Find",
                        action: "toggleFancySearchBar",
                        icon: "find",
                        customButtonType: "find",
                        activeClass: "fancySearchBarEnabled",
                    },
                    {
                        separator: true,
                    },
                    {
                        label: "Create Time Card",
                        action: "openCreateTimeCardModal",
                        icon: "add",
                    },
                    {
                        label: "Add Entry",
                        action: "openAddTimekeepingEntryModal",
                        icon: "add",
                    },
                    {
                        label: "Update All Statuses ▾",
                        icon: "status",
                        action: "updateTimekeepingStatusForTable",
                        disabled: context =>
                            getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                            selectedCellUnableToTransition({
                                selectedCellData: filterData(sourceData.sourceData, context),
                                currentUser: user,
                            }),
                        tooltip: context =>
                            getFlagEnabled("WA-7677-handle-multiple-tk-statuses") &&
                            selectedCellUnableToTransition({
                                selectedCellData: filterData(sourceData.sourceData, context),
                                currentUser: user,
                            })
                                ? "No transitions available for current selection."
                                : undefined,
                    },
                    {
                        separator: true,
                    },
                    {
                        label: "Export",
                        icon: "export",
                        action: "navigateTo",
                        args: {
                            url: "/rhumbix/export/csv/",
                        },
                    },
                ],
            },
            editablePivotSourceModal: {
                includeTablesForAllSchemas: true,
                tableList: [
                    getTimekeepingEntryTable(featureFlags, currentProject, user, currentProjectIds, sourceData),
                    {
                        tableName: "Absences",
                        resources: ["absences"],
                        colDefs: [
                            dateColDefCreator(),
                            checkboxColDefCreator(),
                            {
                                ...employeeColDefCreator(),
                                ignoreRelatedFilters: ["projectId"],
                            },
                            {
                                ...editableTimeCardColDefCreator({
                                    headerName: "Time Card",
                                    required: true,
                                    width: 300,
                                }),
                                ignoreRelatedFilters: ["projectId"],
                                extraFilterQueryParams: ["start_date", "foreman_id"],
                                extraSearchFilters: { allow_empty: true },
                                actionOptions: [getSelectorActionButtons().createNewTimecardButton],
                            },
                            companyAbsenceTypeColDefCreator({
                                editable: true,
                            }),
                            timekeepingStatusColDefCreator({
                                enum: getAvailableStatusValues(userRole),
                            }),
                        ],
                        gridSettings: {
                            defaultColDef: {
                                cellClassRules: {
                                    "period-locked": lockedPeriodCellClassRule,
                                    readonly: params => !timekeepingDataIsEditable(params).canEdit,
                                    "alert-cell": alertCellClassRule,
                                },
                                editable: params => timekeepingDataIsEditable(params).canEdit,
                                filter: false,
                                customTooltip: timekeepingDisabledTooltip,
                            },
                            enableRangeSelection: true,
                            rowSelection: "multiple",
                            suppressRowClickSelection: true,
                            suppressMultiRangeSelection: true,
                            isRowSelectable: rowNode => timekeepingRowIsEditable(rowNode),
                        },
                        otherSettings: {
                            pasteRangeEnabled: true,
                            enableClipboard: true,
                            filterByLockedColumns: true,
                            hideLockedColumns: true,
                            hideCheckboxInHistory: true,
                            validationErrorTriggersAlert: true,
                            highlightErrorRows: true,
                            sizeColumnsToFitSize: 915,
                            isInModalTab: true,
                            rowLevelErrorDisplay: true,
                            suppressExportButton: true,
                            hiddenColumnDefaults: {
                                project: {
                                    value: weeklyTkDummyProject,
                                },
                            },
                            buttons: {
                                cell: [],
                                row: [
                                    {
                                        label: "Delete",
                                        icon: "delete",
                                        action: "deleteSelectedRows",
                                        args: {
                                            local_state_update: true,
                                        },
                                    },
                                    ...editTimecardDetailsAction(),
                                ],
                                table: [
                                    {
                                        label: "Add Row",
                                        icon: "add",
                                        action: "addNewRow",
                                    },
                                    ...showHidePlaceholders,
                                    ...editTimecardDetailsAction(),
                                ],
                            },
                        },
                    },
                    ...(shouldShowShiftsAndBreaks
                        ? [
                              {
                                  tableName: "Shifts and Breaks",
                                  id: TableIds.ShiftsAndBreaksModal,
                                  resources: ["ewsStartStopTimes"],
                                  colDefs: [
                                      dateColDefCreator(),
                                      {
                                          ...checkboxColDefCreator(),
                                          checkboxSelection: p => !isGroupRow(p),
                                      },
                                      {
                                          ...employeeColDefCreator({
                                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                                          }),
                                          ignoreRelatedFilters: ["projectId"],
                                      },
                                      {
                                          ...editableTimeCardColDefCreator({
                                              headerName: "Time Card",
                                              required: true,
                                              width: 300,
                                          }),
                                          ignoreRelatedFilters: ["projectId"],
                                          extraFilterQueryParams: ["start_date", "foreman_id"],
                                          extraSearchFilters: { allow_empty: true },
                                          actionOptions: [getSelectorActionButtons().createNewTimecardButton],
                                      },
                                      companyStartStopTypeColDefCreator({
                                          required: true,
                                          cellRenderer: GroupRowFormattingDefaultCellRenderer,
                                      }),
                                      {
                                          ...datetimeColDefCreator({
                                              cellEditorParams: {
                                                  minuteInterval: 1,
                                                  getOpenToDate: getLocalWorkshiftDate,
                                                  getMinDate: getLocalWorkshiftDate,
                                                  getMaxDate: params => {
                                                      const localWorkshiftDate = getLocalWorkshiftDate(params)
                                                      const employeeId = isNumber(params.data?.employee)
                                                          ? params.data?.employee
                                                          : params.data?.employee?.id

                                                      if (getFlagEnabled("WA-7464-no-next-day-date-picker")) {
                                                          // Be more restrictive, not allowing selection of the next
                                                          // day unless we're sure it's a break and there's a shift
                                                          // for the same person which ends on the next day
                                                          if (
                                                              params.data?.company_start_stop_type?.is_break ===
                                                                  true &&
                                                              doesShiftEndingNextDayExist(
                                                                  params.api,
                                                                  localWorkshiftDate,
                                                                  employeeId
                                                              )
                                                          ) {
                                                              return addDays(localWorkshiftDate, 1)
                                                          } else return localWorkshiftDate
                                                      } else {
                                                          if (params.data?.is_break === false) {
                                                              // Limit Shift start to the workshift date.
                                                              return localWorkshiftDate
                                                          }
                                                          return addDays(localWorkshiftDate, 1)
                                                      }
                                                  },
                                                  getInitialValue: getLocalWorksshiftStartOrStop,
                                                  stopEditAfterSelectTime: true,
                                              },
                                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                                              field: "/start_time",
                                              headerName: "Start Time",
                                              required: true,
                                              valueFormatter: params => {
                                                  if (isGroupRow(params)) {
                                                      const shift = findShiftRowData(params.node)
                                                      if (!shift) return ""
                                                      return simpleTimeValueFormatter({
                                                          value: shift.data.start_time,
                                                      })
                                                  }
                                                  return simpleTimeValueFormatter(params)
                                              },
                                          }),
                                          minWidth: 320,
                                      },
                                      {
                                          ...datetimeColDefCreator({
                                              cellEditorParams: {
                                                  minuteInterval: 1,
                                                  getOpenToDate: getLocalWorkshiftDate,
                                                  getMinDate: getLocalWorkshiftDate,
                                                  getMaxDate: params => {
                                                      const workShiftDate = getLocalWorkshiftDate(params)
                                                      const nextDay = addDays(workShiftDate, 1)
                                                      const isBreak = params.data?.company_start_stop_type?.is_break
                                                      const employeeId = isNumber(params.data?.employee)
                                                          ? params.data?.employee
                                                          : params.data?.employee?.id

                                                      if (getFlagEnabled("WA-7464-no-next-day-date-picker")) {
                                                          // Early out for shifts. We explicitly check for false
                                                          // so that undefined stuff doesn't allow for next day
                                                          if (isBreak === false) return nextDay

                                                          // Allow the next day if we can find a shift with an end
                                                          // date as the next day
                                                          const nextDayShiftEnd = doesShiftEndingNextDayExist(
                                                              params.api,
                                                              workShiftDate,
                                                              employeeId
                                                          )
                                                          return nextDayShiftEnd ? nextDay : workShiftDate
                                                      }
                                                      return nextDay
                                                  },
                                                  getInitialValue: getLocalWorksshiftStartOrStop,
                                                  stopEditAfterSelectTime: true,
                                              },
                                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                                              field: "/stop_time",
                                              headerName: "Stop Time",
                                              required: true,
                                              valueFormatter: params => {
                                                  if (isGroupRow(params)) {
                                                      const shift = findShiftRowData(params.node)
                                                      if (!shift) return ""
                                                      return simpleTimeValueFormatter({
                                                          value: shift.data.stop_time,
                                                      })
                                                  }
                                                  return simpleTimeValueFormatter(params)
                                              },
                                          }),
                                          minWidth: 320,
                                      },
                                      {
                                          ...stringColDefCreator({
                                              cellRenderer: GroupRowFormattingDefaultCellRenderer,
                                              editable: false,
                                              headerName: "Duration",
                                              valueGetter: params => {
                                                  let { data } = params
                                                  if (isGroupRow(params)) {
                                                      const shift = findShiftRowData(params.node)
                                                      data = shift?.data
                                                  }
                                                  if (!data) return

                                                  const { is_break, start_time, stop_time } = data
                                                  if (!start_time || !stop_time) {
                                                      return ""
                                                  }

                                                  const durationInMinutes = differenceInMinutes(
                                                      new Date(stop_time),
                                                      new Date(start_time)
                                                  )

                                                  if (is_break) {
                                                      return `${durationInMinutes} minutes`
                                                  }

                                                  const shiftHours = Math.floor(durationInMinutes / 60)
                                                  const shiftMinutes = durationInMinutes % 60
                                                  let shiftDurationText = ""
                                                  if (shiftHours) {
                                                      shiftDurationText += `${shiftHours} hours`
                                                  }
                                                  if (shiftMinutes) {
                                                      shiftDurationText += `${
                                                          shiftHours ? ", " : ""
                                                      }${shiftMinutes} minutes`
                                                  }

                                                  return shiftDurationText
                                              },
                                          }),
                                      },
                                      {
                                          ...timekeepingStatusColDefCreator({
                                              enum: getAvailableStatusValues(userRole),
                                          }),
                                          cellRenderer: GroupRowFormattingDefaultCellRenderer,
                                          aggFunc: "statusColumnAggFunc",
                                      },
                                  ],
                                  gridSettings: {
                                      autoGroupColumnDef: {
                                          headerName: "Type",
                                          suppressMenu: true,
                                          sort: "asc",
                                          unSortIcon: true,
                                          cellRendererParams: {
                                              suppressCount: true,
                                          },
                                          comparator: compareGroupColumns,
                                          checkboxSelection: p => isGroupRow(p),
                                          groupSelectsChildren: false,
                                          minWidth: 250,
                                      },
                                      defaultColDef: {
                                          cellClassRules: {
                                              "alert-cell": alertCellClassRule,
                                              "period-locked": lockedPeriodCellClassRule,
                                              readonly: params => !timekeepingDataIsEditable(params).canEdit,
                                          },
                                          editable: params => timekeepingDataIsEditable(params).canEdit,
                                          filter: false,
                                          customTooltip: timekeepingEntryGeneralErrorsTooltip,
                                      },
                                      enableRangeSelection: true,
                                      rowSelection: "multiple",
                                      suppressRowClickSelection: true,
                                      suppressMultiRangeSelection: true,
                                      isRowSelectable: rowNode => timekeepingRowIsEditable(rowNode),
                                  },
                                  otherSettings: {
                                      pasteRangeEnabled: true,
                                      enableClipboard: true,
                                      filterByLockedColumns: true,
                                      hideLockedColumns: true,
                                      highlightErrorRows: true,
                                      hideCheckboxInHistory: true,
                                      isInModalTab: true,
                                      sizeColumnsToFitSize: 915,
                                      rowLevelErrorDisplay: true,
                                      suppressExportButton: true,
                                      hiddenColumnDefaults: {
                                          project: {
                                              value: weeklyTkDummyProject,
                                          },
                                      },
                                      groupBy: [
                                          {
                                              cols: [],
                                              label: "None",
                                          },
                                          {
                                              cols: ["/employee"],
                                              label: "Employee",
                                          },
                                      ],
                                      buttons: {
                                          cell: [],
                                          row: [
                                              {
                                                  label: "Delete",
                                                  icon: "delete",
                                                  action: "deleteSelectedRows",
                                                  args: {
                                                      local_state_update: true,
                                                  },
                                              },
                                              ...editTimecardDetailsAction(),
                                          ],
                                          groupRow: [
                                              {
                                                  label: "Add Row",
                                                  icon: "add",
                                                  action: "addNewRowToGroup",
                                              },
                                          ],
                                          table: [
                                              groupByTableAction,
                                              addRowTableAction(featureFlags),
                                              addEmployeeRowsTableAction,
                                              ...showHidePlaceholders,
                                              ...editTimecardDetailsAction(),
                                          ],
                                      },
                                  },
                              },
                          ]
                        : []),
                ],
            },
            enableSearchBar: false,
            enableFancySearchBar: true,
            enableAuditHistory: true,
        },
    }
}
