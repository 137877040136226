"use strict"

const initialState = {
    equipment: {},
}

export default function equipment(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_EQUIPMENT":
            return Object.assign({}, state, { equipment: action.data })
        default:
            return state
    }
}
