import { SIDE_RAIL_SOURCE_DATA_SET, SOURCE_DATA_BULK_UPDATED } from "./actionTypes"

import {
    SSRMClearDataAction,
    SSRMDeleteNewRowAction,
    SSRMUpdateDataAction,
    tSourceData,
    tBaseSourceDataAction,
    tReferenceableIdsState,
    tResourceObject,
    tLockSourceDataEditingAction,
    tReferenceableIdsAction,
    SSRMClearNewRowsAction,
} from "../types"
import { Thunk } from "../../common/types"

export const sourceDataSet = (payload: tSourceData): tBaseSourceDataAction => {
    return {
        type: "SOURCE_DATA_SET",
        payload,
    }
}

export const sideRailSourceDataSet = (payload: tSourceData): tBaseSourceDataAction => {
    return {
        type: SIDE_RAIL_SOURCE_DATA_SET,
        payload,
    }
}

export const referenceableDataSet = (referenceableIds: tReferenceableIdsState): tReferenceableIdsAction => {
    return {
        type: "REFERENCEABLE_DATA_SET",
        payload: referenceableIds,
    }
}

export const sourceDataPartialSet = (payload: tSourceData): tBaseSourceDataAction => {
    return {
        type: "SOURCE_DATA_PARTIAL_SET",
        payload,
    }
}

export const sourceDataAdded = (payload: tSourceData): tBaseSourceDataAction => {
    return {
        type: "SOURCE_DATA_ADDED",
        payload,
    }
}

export const sourceDataUpdated = (payload: tSourceData, sortBy?: string): tBaseSourceDataAction => {
    return {
        type: "SOURCE_DATA_UPDATED",
        payload,
        sortBy,
    }
}

export const sourceDataDeleted = (payload: tSourceData): tBaseSourceDataAction => {
    return {
        type: "SOURCE_DATA_DELETED",
        payload,
    }
}

/** Bulk update sourceData in Redux. */
export const sourceDataBulkUpdated = (payload: tSourceData, sortBy?: string): tBaseSourceDataAction => {
    return {
        type: SOURCE_DATA_BULK_UPDATED,
        payload,
        sortBy,
    }
}

export const lockSourceDataEditing = (
    payload: tLockSourceDataEditingAction["payload"]
): tLockSourceDataEditingAction => {
    return {
        type: "LOCK_SOURCE_DATA_EDITING",
        payload,
    }
}

export const SSRMAddRow = (payload: tSourceData): SSRMUpdateDataAction => {
    return {
        type: "SSRM_DATA_ADDED",
        payload,
    }
}

export const SSRMSaveRow = (payload: tSourceData): SSRMUpdateDataAction => {
    return {
        type: "SSRM_DATA_SAVED",
        payload,
    }
}

export const SSRMClearCommittedNewRows = (): SSRMClearDataAction => {
    return {
        type: "SSRM_CLEAR_COMMITTED_NEW_ROWS",
    }
}

export const SSRMClearAllNewRows = (): SSRMClearNewRowsAction => {
    return {
        type: "SSRM_CLEAR_ALL_NEW_ROWS",
    }
}

export const SSRMRowDeleted = (payload: tResourceObject): SSRMDeleteNewRowAction => {
    return {
        type: "SSRM_DELETE_NEW_ROWS",
        payload,
    }
}

export const sourceDataRowAdded = (
    resource: string,
    newRow: tResourceObject,
    alreadySavedToDB = false
): Thunk<tBaseSourceDataAction> => dispatch =>
    dispatch(sourceDataAdded({ [resource]: [{ ...newRow, newRow: !alreadySavedToDB }] }))

export const SSRMRowAdded = (
    resource: string,
    newRow: tResourceObject,
    alreadySavedToDB = false
): Thunk<SSRMUpdateDataAction> => dispatch =>
    dispatch(SSRMAddRow({ [resource]: [{ ...newRow, newRow: !alreadySavedToDB }] }))

export const SSRMRowSaved = (resource: string, newRow: tResourceObject): Thunk<SSRMUpdateDataAction> => dispatch =>
    dispatch(SSRMSaveRow({ [resource]: newRow }))

export const sourceDataRowUpdated = (
    resource: string,
    newData: tResourceObject
): Thunk<tBaseSourceDataAction> => dispatch => dispatch(sourceDataUpdated({ [resource]: [newData] }))

export const sourceDataRowDeleted = (
    resource: string,
    rowToDelete: tResourceObject
): Thunk<tBaseSourceDataAction> => dispatch => dispatch(sourceDataDeleted({ [resource]: [rowToDelete] }))
