import { AnyAction as iAnyAction } from "redux"

import {
    tUserRole,
    tHttpVerb,
    iJSONObject,
    TimekeepingEntry,
    tCompanyProjectOptions,
    tCurrentUserEmployee,
} from "../common/types"
import { Markup, MarkupCategory, MarkupFormData } from "../markup/types"

export interface iCohort {
    id: number
    company: number
    name: string
    description: string
    employees: number[]
}

export interface iCohortEmployee {
    cohort_id: number
    employee_id: number
}

export interface iCompanyAbsenceTypes {
    id: number
    company: number
    name: string
    code: string
    deleted_on: string
}

interface iCompanyStartStopType {
    id: number
    is_break: boolean
    name: string
}

export interface iEmployeeWorkShiftFormSchema {
    id: number
    projects: Array<number>
    created_on: string
    name: string
    schema: iJSONObject
    ui_schema: iJSONObject
    min_app_version: string
    create_placeholder?: string
}

export interface iEmployeeWorkShiftFormStore {
    id: number | null
    last_updated: Date
    schema: number
    store: iJSONObject | null
    created_on: Date | null
    file_version: string | null
    employee_work_shift: number
    project: number
}

export type SchemaVariant = {
    id: number
    name: string
    description: string
    schema_id: number
    schema_name: string
    sort_order: number
    template: string
}

export type SchemaType = "BUNDLE" | null

export interface iCompanyFormSchema {
    id: number
    projects: Array<number>
    company_id: number
    created_on: string
    name: string
    schema: iJSONObject
    ui_schema: iJSONObject
    template: string
    permissions: { [key: string]: Record<string, any> }
    status_transitions: { [key: string]: string[] }
    available_statuses: { [key: string]: string[] }
    initial_status: string
    min_app_version: string
    schema_type: SchemaType
    nav_folder_path?: string
    variant?: Record<string, any>
}

// most of the ? marked types are for field that don't exist until the form has been created
export interface iCompanyFormStore {
    id?: number
    company_logo?: string
    created_on?: string
    deleted_on?: string
    employee: iEmployee | tCurrentUserEmployee
    employee_id: number
    project?: number
    form_num?: number
    store: iJSONObject
    submitted_on?: string
    project_name?: string
    job_number?: string
    status?: string
    schema: number
    project_tz?: string
    file_version?: string
    history_id?: number
    task_id?: string
    last_updated?: string
    variant_name?: string // only currently used when viewing specific variant names from bundles
}

export type SchemaDashboardRowData = {
    id: number
    name: string
    description: string
    projects: number[]
    number_projects: number
    total_projects: number
    number_groups_with_auto_add: number
    total_groups: number
    number_generated: number
    last_used: string
}

export type GPTreeDashboardRowData = {
    id: number
    name: string
    number_assigned_projects: number
    total_projects: number
    number_groups_with_auto_add: number
    total_groups: number
}

export interface iCompanyGroup {
    id: number
    name: string
    description: string
    company_id: number
    parent_id: number
    parent_name: string
    grants_employee_access: boolean
    project_ids: Array<number>
    children_ids: Array<number>
    employee_ids: Array<number>
}

export interface iCostCode {
    id: number
    name?: string
    code: string
    description: string
    units: string
    group: string
    is_active: boolean
    project: { id: number; job_number: string } | tProject
}

export interface iCostItem {
    id: number
    change_order_id: number
    change_order_name: string
    change_order_number: string
    change_order_integration_id: string
    name: string
    number: string
    sub_company_id: number
    sub_company_name: string
    is_active: boolean
}

export interface iChangeOrder {
    id: number
    name: string
    number: string
    description: string
    is_active: boolean
    created_by?: number
    not_to_exceed_amount: number
    classification: string
    project: number
    integration_id?: number
    cost_items: iCostItem[]
}

export interface iCompanyCrewType {
    id: number
    name: string
    is_active: boolean
    company_id: number
    cost_code_control_ids: Array<number>
}

export interface iCostCodeControl {
    id: number
    name: string
    is_active: boolean
    company_id: number
}

export interface iEmployee {
    id: number
    company_supplied_id: string
    last_name: string
    first_name: string
    classification: string
    trade: string
    user_role: tUserRole
    is_active: boolean
    thumbnail: string | null
    fullsize: string | null
    phone: string | null
    email: string
    member?: number
    text_alerts_ok: boolean
    text_opted_out_on?: string
    name?: string
}

export interface iEmployeeTrade {
    trade: string
}

export interface iEmployeeClassification {
    classification: string
}
export interface iMaterial {
    id: number
    name: string
    description: string
    group: string
    units: string
    part_number: string
}

export interface iProjectMaterials {
    id: number
    project_id: number
    material_id: number
    unit_price: number
}

export interface iAnalyticsDashboard {
    id: number
    name: string
    description: string
    url: string
    url_slug: string
    category: string
}

export interface iProjectEmployees {
    id: number
    standard_time: number
    over_time: number
    double_time: number
    premium_double_time: number
    premium_over_time: number
    employee: number
    project_id: number
}

export interface iEmployeeSchema {
    id: number
    projects: Array<number>
    created_on: string
    name: string
    schema: any
    ui_schema: any
}

export interface iEquipment {
    id: number
    equipment_id: string
    description: string
    category: string
    caltrans_id: string
    is_active: string
    status: string
    projects: Array<number>
    name?: string
    custom_fields?: Record<string, string>
}

export interface iProjectEquipment {
    id: number
    equipment: number
    project: number
    running_time_price: number
    idle_time_price: number
    over_time_price: number
}

export interface iCompanyTrade {
    id: number
    name: string
    code: string
    is_active: boolean
}

export interface iCompanyClassification {
    id: number
    name: string
    code: string
    is_active: boolean
    company_trade?: Array<number>
}

export interface iPicklistItem {
    id: number
    is_active: boolean
    code: string
    name: string
    picklist: number
}

export interface iWorkShift {
    id: number
    start_time: string
    end_time: string
    foreman_id: number
    foreman: iEmployee
    created_on: string
    updated_on: string
    timezone?: string
    is_deleted: boolean
    date: Date
    tempId?: number
}

export type tEmployeeWorkShiftStartStopTime = {
    company_start_stop_type: number
    date: string
    duration_minutes: number
    id: number
    is_break: boolean
    num_placeholders?: number
    start_stop_type: string
    start_time: string
    status: string
    stop_time: string
}

export type tCompanyStartStopType = {
    name: string
    id: number
    is_active: boolean
    is_break: boolean
}

export interface iEmployeeWorkShift {
    breaks: boolean | null
    created_on: string
    employee: number
    end_time: string
    foreman?: iEmployee
    id: number | null
    injured: boolean | null
    forms?: iEmployeeWorkShiftFormStore[]
    shift_date: string
    start_time: string
    state: "UNKNOWN" | "FOREMAN_ENDED" | "WORKER_ADJUSTED" | "CLOSED"
    stretch: boolean | null
    signature?: string
    signature_language: string
    start_stop_times?: tEmployeeWorkShiftStartStopTime[]
    timezone?: string
    updated_on: string
    use_webber_eod_questions?: boolean // WA-8296-webber-eod-questions -- this can be removed with that flag
    work_components: TimekeepingEntry[]
    work_shift: number
    worker_profile?: iEmployee
}

export interface iTkModifier {
    can_field_add_items?: string[]
    id: number
    is_active: boolean
    name: string
    public_api_field_name: string
    slug: string
    standard_tk_modifier_type: number | null
    order: number
}

export interface iPicklist {
    id: number
    is_active: boolean
    name: string
    public_api_field_name: string
}

type MutableProject = {
    id?: number
    name?: string
    job_number?: string
    description?: string
    status?: string
    options?: tCompanyProjectOptions
    markups?: Partial<Record<MarkupCategory, Markup>>
    address?: string
    timezone?: string
    latitude?: string | number
    longitude?: string | number
    group?: number
    group_name?: string
    has_project_share?: boolean
    company_id?: number
    cost_codes_count?: number
    employees_count?: number
    expected_start_date?: string
    expected_completion_date?: string
    client_name?: string
    client_contact_name?: string
    client_contact_email?: string
    client_contact_address?: string
    logo?: string
    logo_absolute_url?: string
    custom_fields?: { [key: string]: string }
}

export interface iTimekeepingStatus {
    is_custom: boolean
    id: number
    built_in_status_id: number
    can_change_to_status: tUserRole[]
    can_edit_cell: tUserRole[]
    is_hidden: boolean
    is_outline: boolean
    is_traffic_outline: boolean
    label: string
    name: string
    object_count: number
    selected_color: string
    sort_order: number
    traffic_color: string
}

export type ProjectFormData = Omit<MutableProject, "markups"> & {
    markups?: MarkupFormData[]
    newLogo?: File
}

export type ApiIntegrationTokenFormData = {
    name?: string
    show_new_field_names?: boolean
}

export type tProject = Readonly<MutableProject>

export interface iCompany {
    id: number
    company_name: string
}

export interface iGuestUser {
    id: number
    email: string
    company_name: string
    job_title: string
    first_name: string
    last_name: string
    created_on: string
}

export interface iGuestFormShare {
    id: number
    form: number
    guest: number
    permissions: string
    created_by: number
    created_on: string
    view_count: number
}

export interface iSchemaStatusName {
    name: string
    id?: number
}

export interface iResourceEntityState {
    objects: {
        [key: number | string]: any
    }
    invalidate: boolean
}

export interface iKeyedResourcePaginationState {
    error: string | null
    invalidate: boolean
    isFetching: boolean
    nextPageUrl?: string
    pageCount: number
}

export interface iResourcePaginationState {
    [key: string]: iKeyedResourcePaginationState
}

export interface iCohortEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCohort
    }
}

export interface iCohortEmployeeEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCohortEmployee
    }
}

export interface iCompanyAbsenceTypesEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyAbsenceTypes
    }
}

export interface iCompanyFormSchemaEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyFormSchema
    }
}

export interface iCompanyFormStoreEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyFormStore
    }
}

export interface iCompanyGroupEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyGroup
    }
}

export interface iCompanyStartStopTypeEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyStartStopType
    }
}

export interface iCostCodeEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCostCode
    }
}

export interface iCostItemEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCostItem
    }
}

export interface iChangeOrderEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iChangeOrder
    }
}

export interface iEmployeeEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iEmployee
    }
}

export interface iEmployeeTradeEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iEmployeeTrade
    }
}

export interface iEmployeeClassificationEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iEmployeeClassification
    }
}
export interface iProjectEmployeesEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iProjectEmployees
    }
}

export interface iEmployeeSchemaEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iEmployeeSchema
    }
}

export interface iEquipmentEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iEquipment
    }
}

export interface iMaterialEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iMaterial
    }
}

export interface iProjectMaterialsEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iProjectMaterials
    }
}

export interface iAnalyticsDashboardEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iAnalyticsDashboard
    }
}

export interface iProjectEquipmentEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iProjectEquipment
    }
}

export interface iProjectEntityState extends iResourceEntityState {
    objects: {
        [key: number]: tProject
    }
}

export interface iCompanyEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompany
    }
}

export interface iCompanyCrewTypeEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyCrewType
    }
}

export interface iCostCodeControlEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCostCodeControl
    }
}

export interface iSchemaStatusNameEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iSchemaStatusName
    }
}

export interface iGuestUserEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iGuestUser
    }
}

export interface iGuestFormShareEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iGuestFormShare
    }
}

export interface iCompanyTradeEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyTrade
    }
}

export interface iCompanyClassificationEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iCompanyClassification
    }
}

export interface iPicklistItemEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iPicklistItem
    }
}

export interface iTimekeepingStatusEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iTimekeepingStatus
    }
}

export interface iWorkShiftEntityState extends iResourceEntityState {
    objects: {
        [key: number]: iWorkShift
    }
}

export interface iMutableEntityState {
    cohorts: iCohortEntityState
    cohortEmployees: iCohortEmployeeEntityState
    companyAbsenceTypes: iCompanyAbsenceTypesEntityState
    companyFormSchemas: iCompanyFormSchemaEntityState
    companyFormStores: iCompanyFormStoreEntityState
    companyGroups: iCompanyGroupEntityState
    companyStartStopTypes: iCompanyStartStopTypeEntityState
    costCodes: iCostCodeEntityState
    costItems: iCostItemEntityState
    changeOrders: iChangeOrderEntityState
    costCodeControls: iCostCodeControlEntityState
    companyCrewTypes: iCompanyCrewTypeEntityState
    employees: iEmployeeEntityState
    employeeTrades: iEmployeeTradeEntityState
    employeeClassifications: iEmployeeClassificationEntityState
    guestFormShares: iGuestFormShareEntityState
    projectEmployees: iProjectEmployeesEntityState
    employeeSchemas: iEmployeeSchemaEntityState
    equipment: iEquipmentEntityState
    materials: iMaterialEntityState
    projectMaterials: iProjectMaterialsEntityState
    projectEquipment: iProjectEquipmentEntityState
    projects: iProjectEntityState
    companies: iCompanyEntityState
    schemaStatusNames: iSchemaStatusNameEntityState
    analyticsDashboards: iAnalyticsDashboardEntityState
    companyTrades: iCompanyTradeEntityState
    companyClassifications: iCompanyClassificationEntityState
    picklistItems: iPicklistItemEntityState
    timekeepingStatuses: iTimekeepingStatusEntityState
    workShifts: iWorkShiftEntityState
}

export type tEntityState = Readonly<iMutableEntityState>

export type tCachedResourceName = keyof tEntityState

export interface iMutablePaginationState {
    cohorts: iResourcePaginationState
    cohortEmployees: iResourcePaginationState
    companyAbsenceTypes: iResourcePaginationState
    companyFormSchemas: iResourcePaginationState
    companyFormStores: iResourcePaginationState
    companyGroups: iResourcePaginationState
    companyStartStopTypes: iResourcePaginationState
    costCodes: iResourcePaginationState
    costItems: iResourcePaginationState
    changeOrders: iResourcePaginationState
    costCodeControls: iResourcePaginationState
    companyCrewTypes: iResourcePaginationState
    employees: iResourcePaginationState
    employeeTrades: iResourcePaginationState
    employeeClassifications: iResourcePaginationState
    projectEmployees: iResourcePaginationState
    guestFormShares: iResourcePaginationState
    employeeSchemas: iResourcePaginationState
    equipment: iResourcePaginationState
    materials: iResourcePaginationState
    projectMaterials: iResourcePaginationState
    projectEquipment: iResourcePaginationState
    projects: iResourcePaginationState
    companies: iResourcePaginationState
    schemaStatusNames: iResourcePaginationState
    analyticsDashboards: iResourcePaginationState
    companyTrades: iResourcePaginationState
    companyClassifications: iResourcePaginationState
    picklistItems: iResourcePaginationState
    timekeepingStatuses: iResourcePaginationState
    workShifts: iResourcePaginationState
}

export type tPaginationState = Readonly<iMutablePaginationState>

// The selector for referenceable data drops the `objects` sub-key from redux
export interface iMutableEntitySelection {
    cohorts: { [key: number]: iCohort }
    cohortEmployees: { [key: number]: iCohortEmployee }
    companyAbsenceTypes: { [key: number]: iCompanyAbsenceTypes }
    companyFormSchemas: { [key: number]: iCompanyFormSchema }
    companyFormStores: { [key: number]: iCompanyFormStore }
    companyGroups: { [key: number]: iCompanyGroup }
    companyStartStopTypes: { [key: number]: iCompanyStartStopType }
    costCodes: { [key: number]: iCostCode }
    costItems: { [key: number]: iCostItem }
    changeOrders: { [key: number]: iChangeOrder }
    costCodeControls: { [key: number]: iCostCodeControl }
    companyCrewTypes: { [key: number]: iCompanyCrewType }
    employees: { [key: number]: iEmployee }
    employeeTrades: { [key: number]: iEmployeeTrade }
    employeeClassifications: { [key: number]: iEmployeeClassification }
    projectEmployees: { [key: number]: iProjectEmployees }
    guestFormShares: { [key: number]: iGuestFormShare }
    employeeSchemas: { [key: number]: iEmployeeSchema }
    equipment: { [key: number]: iEquipment }
    materials: { [key: number]: iMaterial }
    projectMaterials: { [key: number]: iProjectMaterials }
    projectEquipment: { [key: number]: iProjectEquipment }
    projects: { [key: number]: tProject }
    companies: { [key: number]: iCompany }
    schemaStatusNames: { [key: number]: iSchemaStatusName }
    analyticsDashboards: { [key: number]: iAnalyticsDashboard }
    companyTrades: { [key: number]: iCompanyTrade }
    companyClassifications: { [key: number]: iCompanyClassification }
    picklistItems: { [key: number]: iPicklistItem }
    timekeepingStatuses: { [key: number]: iTimekeepingStatus }
    workShifts: { [key: number]: iWorkShift }
}

export type tEntitySelection = Readonly<iMutableEntitySelection>

export type tEntitySelectionReturn =
    | iCohort
    | iCohortEmployee
    | iCompanyAbsenceTypes
    | iCompanyFormSchema
    | iCompanyFormStore
    | iCompanyGroup
    | iCompanyStartStopType
    | iCostCode
    | iChangeOrder
    | iCostItem
    | iCostCodeControl
    | iCompanyCrewType
    | iEmployee
    | iEmployeeTrade
    | iEmployeeClassification
    | iGuestUser
    | iProjectEmployees
    | iEmployeeSchema
    | iEquipment
    | iMaterial
    | iProjectMaterials
    | iProjectEquipment
    | tProject
    | iCompany
    | iSchemaStatusName
    | iAnalyticsDashboard
    | iCompanyTrade
    | iCompanyClassification
    | iPicklistItem
    | iTimekeepingStatus
    | iWorkShift

export interface iResourceLoadingState {
    isLoading: boolean
    error: null | string
}

export interface iMutableLoadingState {
    cohorts: iResourceLoadingState
    cohortEmployees: iResourceLoadingState
    companyAbsenceTypes: iResourceLoadingState
    companyFormSchemas: iResourceLoadingState
    companyFormStores: iResourceLoadingState
    companyGroups: iResourceLoadingState
    companyStartStopTypes: iResourceLoadingState
    costCodes: iResourceLoadingState
    costItems: iResourceLoadingState
    changeOrders: iResourceLoadingState
    costCodeControls: iResourceLoadingState
    companyCrewTypes: iResourceLoadingState
    employees: iResourceLoadingState
    employeeTrades: iResourceLoadingState
    employeeClassifications: iResourceLoadingState
    projectEmployees: iResourceLoadingState
    guestFormShares: iResourceLoadingState
    employeeSchemas: iResourceLoadingState
    equipment: iResourceLoadingState
    materials: iResourceLoadingState
    projectMaterials: iResourceLoadingState
    projectEquipment: iResourceLoadingState
    projects: iResourceLoadingState
    companies: iResourceLoadingState
    schemaStatusNames: iResourceLoadingState
    analyticsDashboards: iResourceLoadingState
    companyTrades: iResourceLoadingState
    companyClassifications: iResourceLoadingState
    picklistItems: iResourceLoadingState
    timekeepingStatuses: iResourceLoadingState
    workShifts: iResourceLoadingState
}

export type tLoadingState = Readonly<iMutableLoadingState>

export type tNormalizedData = {
    result: Array<number>
    entities: tEntityState
}

export enum eEntityActionType {
    FETCH_COHORTS = "FETCH_COHORTS",
    FETCH_COHORTS_SUCCESS = "FETCH_COHORTS_SUCCESS",
    FETCH_COHORTS_FINISHED = "FETCH_COHORTS_FINISHED",
    FETCH_COHORTS_FAILURE = "FETCH_COHORTS_FAILURE",
    FETCH_COHORT_EMPLOYEES = "FETCH_COHORT_EMPLOYEES",
    FETCH_COHORT_EMPLOYEES_SUCCESS = "FETCH_COHORT_EMPLOYEES_SUCCESS",
    FETCH_COHORT_EMPLOYEES_FINISHED = "FETCH_COHORT_EMPLOYEES_FINISHED",
    FETCH_COHORT_EMPLOYEES_FAILURE = "FETCH_COHORT_EMPLOYEES_FAILURE",
    FETCH_COMPANY_ABSENCE_TYPES = "FETCH_COMPANY_ABSENCE_TYPES",
    FETCH_COMPANY_ABSENCE_TYPES_SUCCESS = "FETCH_COMPANY_ABSENCE_TYPES_SUCCESS",
    FETCH_COMPANY_ABSENCE_TYPES_FINISHED = "FETCH_COMPANY_ABSENCE_TYPES_FINISHED",
    FETCH_COMPANY_ABSENCE_TYPES_FAILURE = "FETCH_COMPANY_ABSENCE_TYPES_FAILURE",
    FETCH_COMPANY_FORM_SCHEMAS = "FETCH_COMPANY_FORM_SCHEMAS",
    FETCH_COMPANY_FORM_SCHEMAS_SUCCESS = "FETCH_COMPANY_FORM_SCHEMAS_SUCCESS",
    FETCH_COMPANY_FORM_SCHEMAS_FINISHED = "FETCH_COMPANY_FORM_SCHEMAS_FINISHED",
    FETCH_COMPANY_FORM_SCHEMAS_FAILURE = "FETCH_COMPANY_FORM_SCHEMAS_FAILURE",
    FETCH_COMPANY_FORM_STORES = "FETCH_COMPANY_FORM_STORES",
    FETCH_COMPANY_FORM_STORES_SUCCESS = "FETCH_COMPANY_FORM_STORES_SUCCESS",
    FETCH_COMPANY_FORM_STORES_FINISHED = "FETCH_COMPANY_FORM_STORES_FINISHED",
    FETCH_COMPANY_FORM_STORES_FAILURE = "FETCH_COMPANY_FORM_STORES_FAILURE",
    FETCH_COMPANY_GROUPS = "FETCH_COMPANY_GROUPS",
    FETCH_COMPANY_GROUPS_SUCCESS = "FETCH_COMPANY_GROUPS_SUCCESS",
    FETCH_COMPANY_GROUPS_FINISHED = "FETCH_COMPANY_GROUPS_FINISHED",
    FETCH_COMPANY_GROUPS_FAILURE = "FETCH_COMPANY_GROUPS_FAILURE",
    FETCH_COMPANY_START_STOP_TYPES = "FETCH_COMPANY_START_STOP_TYPES",
    FETCH_COMPANY_START_STOP_TYPES_SUCCESS = "FETCH_COMPANY_START_STOP_TYPES_SUCCESS",
    FETCH_COMPANY_START_STOP_TYPES_FINISHED = "FETCH_COMPANY_START_STOP_TYPES_FINISHED",
    FETCH_COMPANY_START_STOP_TYPES_FAILURE = "FETCH_COMPANY_START_STOP_TYPES_FAILURE ",
    FETCH_COST_CODES = "FETCH_COST_CODES",
    FETCH_COST_CODES_SUCCESS = "FETCH_COST_CODES_SUCCESS",
    FETCH_COST_CODES_FINISHED = "FETCH_COST_CODES_FINISHED",
    FETCH_COST_CODES_FAILURE = "FETCH_COST_CODES_FAILURE",
    FETCH_COST_CODE_CONTROLS = "FETCH_COST_CODE_CONTROLS",
    FETCH_COST_CODE_CONTROLS_SUCCESS = "FETCH_COST_CODE_CONTROLS_SUCCESS",
    FETCH_COST_CODE_CONTROLS_FINISHED = "FETCH_COST_CODE_CONTROLS_FINISHED",
    FETCH_COST_CODE_CONTROLS_FAILURE = "FETCH_COST_CODE_CONTROLS_FAILURE",
    FETCH_COST_ITEMS = "FETCH_COST_ITEMS",
    FETCH_COST_ITEMS_SUCCESS = "FETCH_COST_ITEMS_SUCCESS",
    FETCH_COST_ITEMS_FINISHED = "FETCH_COST_ITEMS_FINISHED",
    FETCH_COST_ITEMS_FAILURE = "FETCH_COST_ITEMS_FAILURE",
    FETCH_CHANGE_ORDERS = "FETCH_CHANGE_ORDERS",
    FETCH_CHANGE_ORDERS_SUCCESS = "FETCH_CHANGE_ORDERS_SUCCESS",
    FETCH_CHANGE_ORDERS_FINISHED = "FETCH_CHANGE_ORDERS_FINISHED",
    FETCH_CHANGE_ORDERS_FAILURE = "FETCH_CHANGE_ORDERS_FAILURE",
    FETCH_COMPANY_CREW_TYPES = "FETCH_COMPANY_CREW_TYPES",
    FETCH_COMPANY_CREW_TYPES_SUCCESS = "FETCH_COMPANY_CREW_TYPES_SUCCESS",
    FETCH_COMPANY_CREW_TYPES_FINISHED = "FETCH_COMPANY_CREW_TYPES_FINISHED",
    FETCH_COMPANY_CREW_TYPES_FAILURE = "FETCH_COMPANY_CREW_TYPES_FAILURE",
    FETCH_EMPLOYEES = "FETCH_EMPLOYEES",
    FETCH_EMPLOYEES_SUCCESS = "FETCH_EMPLOYEES_SUCCESS",
    FETCH_EMPLOYEES_FINISHED = "FETCH_EMPLOYEES_FINISHED",
    FETCH_EMPLOYEES_FAILURE = "FETCH_EMPLOYEES_FAILURE",
    FETCH_EMPLOYEE_TRADES = "FETCH_EMPLOYEE_TRADES",
    FETCH_EMPLOYEE_TRADES_SUCCESS = "FETCH_EMPLOYEE_TRADES_SUCCESS",
    FETCH_EMPLOYEE_TRADES_FINISHED = "FETCH_EMPLOYEE_TRADES_FINISHED",
    FETCH_EMPLOYEE_TRADES_FAILURE = "FETCH_EMPLOYEE_TRADES_FAILURE",
    FETCH_EMPLOYEE_CLASSIFICATIONS = "FETCH_EMPLOYEE_CLASSIFICATIONS",
    FETCH_EMPLOYEE_CLASSIFICATIONS_SUCCESS = "FETCH_EMPLOYEE_CLASSIFICATIONS_SUCCESS",
    FETCH_EMPLOYEE_CLASSIFICATIONS_FINISHED = "FETCH_EMPLOYEE_CLASSIFICATIONS_FINISHED",
    FETCH_EMPLOYEE_CLASSIFICATIONS_FAILURE = "FETCH_EMPLOYEE_CLASSIFICATIONS_FAILURE",
    FETCH_GUEST_FORM_SHARES = "FETCH_GUEST_FORM_SHARES",
    FETCH_GUEST_FORM_SHARES_SUCCESS = "FETCH_GUEST_FORM_SHARES_SUCCESS",
    FETCH_GUEST_FORM_SHARES_FINISHED = "FETCH_GUEST_FORM_SHARES_FINISHED",
    FETCH_GUEST_FORM_SHARES_FAILURE = "FETCH_GUEST_FORM_SHARES_FAILURE",
    FETCH_PROJECT_EMPLOYEES = "FETCH_PROJECT_EMPLOYEES",
    FETCH_PROJECT_EMPLOYEES_SUCCESS = "FETCH_PROJECT_EMPLOYEES_SUCCESS",
    FETCH_PROJECT_EMPLOYEES_FINISHED = "FETCH_PROJECT_EMPLOYEES_FINISHED",
    FETCH_PROJECT_EMPLOYEES_FAILURE = "FETCH_PROJECT_EMPLOYEES_FAILURE",
    FETCH_EMPLOYEE_SCHEMAS = "FETCH_EMPLOYEE_SCHEMAS",
    FETCH_EMPLOYEE_SCHEMAS_SUCCESS = "FETCH_EMPLOYEE_SCHEMAS_SUCCESS",
    FETCH_EMPLOYEE_SCHEMAS_FINISHED = "FETCH_EMPLOYEE_SCHEMAS_FINISHED",
    FETCH_EMPLOYEE_SCHEMAS_FAILURE = "FETCH_EMPLOYEE_SCHEMAS_FAILURE",
    FETCH_EQUIPMENT = "FETCH_EQUIPMENT",
    FETCH_EQUIPMENT_SUCCESS = "FETCH_EQUIPMENT_SUCCESS",
    FETCH_EQUIPMENT_FINISHED = "FETCH_EQUIPMENT_FINISHED",
    FETCH_EQUIPMENT_FAILURE = "FETCH_EQUIPMENT_FAILURE",
    FETCH_PROJECT_EQUIPMENT = "FETCH_PROJECT_EQUIPMENT",
    FETCH_PROJECT_EQUIPMENT_SUCCESS = "FETCH_PROJECT_EQUIPMENT_SUCCESS",
    FETCH_PROJECT_EQUIPMENT_FINISHED = "FETCH_PROJECT_EQUIPMENT_FINISHED",
    FETCH_PROJECT_EQUIPMENT_FAILURE = "FETCH_PROJECT_EQUIPMENT_FAILURE",
    FETCH_PROJECTS = "FETCH_PROJECTS",
    FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS",
    FETCH_PROJECTS_FINISHED = "FETCH_PROJECTS_FINISHED",
    FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE",
    FETCH_COMPANIES = "FETCH_COMPANIES",
    FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS",
    FETCH_COMPANIES_FINISHED = "FETCH_COMPANIES_FINISHED",
    FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE",
    FETCH_MATERIALS = "FETCH_MATERIALS",
    FETCH_MATERIALS_SUCCESS = "FETCH_MATERIALS_SUCCESS",
    FETCH_MATERIALS_FINISHED = "FETCH_MATERIALS_FINISHED",
    FETCH_MATERIALS_FAILURE = "FETCH_MATERIALS_FAILURE",
    FETCH_PROJECT_MATERIALS = "FETCH_PROJECT_MATERIALS",
    FETCH_PROJECT_MATERIALS_SUCCESS = "FETCH_PROJECT_MATERIALS_SUCCESS",
    FETCH_PROJECT_MATERIALS_FINISHED = "FETCH_PROJECT_MATERIALS_FINISHED",
    FETCH_PROJECT_MATERIALS_FAILURE = "FETCH_PROJECT_MATERIALS_FAILURE",
    FETCH_SCHEMA_STATUS_NAMES = "FETCH_SCHEMA_STATUS_NAMES",
    FETCH_SCHEMA_STATUS_NAMES_SUCCESS = "FETCH_SCHEMA_STATUS_NAMES_SUCCESS",
    FETCH_SCHEMA_STATUS_NAMES_FINISHED = "FETCH_SCHEMA_STATUS_NAMES_FINISHED",
    FETCH_SCHEMA_STATUS_NAMES_FAILURE = "FETCH_SCHEMA_STATUS_NAMES_FAILURE",
    FETCH_ANALYTICS_DASHBOARDS = "FETCH_ANALYTICS_DASHBOARDS",
    FETCH_ANALYTICS_DASHBOARDS_SUCCESS = "FETCH_ANALYTICS_DASHBOARDS_SUCCESS",
    FETCH_ANALYTICS_DASHBOARDS_FINISHED = "FETCH_ANALYTICS_DASHBOARDS_FINISHED",
    FETCH_ANALYTICS_DASHBOARDS_FAILURE = "FETCH_ANALYTICS_DASHBOARDS_FAILURE",
    FETCH_COMPANY_TRADES = "FETCH_COMPANY_TRADES",
    FETCH_COMPANY_TRADES_SUCCESS = "FETCH_COMPANY_TRADES_SUCCESS",
    FETCH_COMPANY_TRADES_FINISHED = "FETCH_COMPANY_TRADES_FINISHED",
    FETCH_COMPANY_TRADES_FAILURE = "FETCH_COMPANY_TRADES_FAILURE",
    FETCH_COMPANY_CLASSIFICATIONS = "FETCH_COMPANY_CLASSIFICATIONS",
    FETCH_COMPANY_CLASSIFICATIONS_SUCCESS = "FETCH_COMPANY_CLASSIFICATIONS_SUCCESS",
    FETCH_COMPANY_CLASSIFICATIONS_FINISHED = "FETCH_COMPANY_CLASSIFICATIONS_FINISHED",
    FETCH_COMPANY_CLASSIFICATIONS_FAILURE = "FETCH_COMPANY_CLASSIFICATIONS_FAILURE",
    FETCH_PICKLIST_ITEMS = "FETCH_PICKLIST_ITEMS",
    FETCH_PICKLIST_ITEMS_SUCCESS = "FETCH_PICKLIST_ITEMS_SUCCESS",
    FETCH_PICKLIST_ITEMS_FINISHED = "FETCH_PICKLIST_ITEMS_FINISHED",
    FETCH_PICKLIST_ITEMS_FAILURE = "FETCH_PICKLIST_ITEMS_FAILURE",
    FETCH_TIMEKEEPING_STATUSES_SUCCESS = "FETCH_TIMEKEEPING_STATUSES_SUCCESS",
    FETCH_TIMEKEEPING_STATUSES_FAILURE = "FETCH_TIMEKEEPING_STATUSES_FAILURE",
    FETCH_TIMEKEEPING_STATUSES_FINISHED = "FETCH_TIMEKEEPING_STATUSES_FINISHED",
    FETCH_TIMEKEEPING_STATUSES = "FETCH_TIMEKEEPING_STATUSES",
    FETCH_WORK_SHIFTS = "FETCH_WORK_SHIFTS",
    FETCH_WORK_SHIFTS_SUCCESS = "FETCH_WORK_SHIFTS_SUCCESS",
    FETCH_WORK_SHIFTS_FINISHED = "FETCH_WORK_SHIFTS_FINISHED",
    FETCH_WORK_SHIFTS_FAILURE = "FETCH_WORK_SHIFTS_FAILURE",
}

export interface iResetErrorMessageAction {
    type: "RESET_ERROR_MESSAGE"
    payload: Record<string, never>
}

export interface iNoOpAction {
    type: "@__NO_OP__"
}

export interface iFetchSingleProjectAction extends iNoOpAction {
    key: "page_size=1"
    FETCH_FROM_API: {
        all: false
        types: [eEntityActionType, eEntityActionType, eEntityActionType, eEntityActionType]
        endpoint: string
        resourceName: "projects"
    }
}

export interface iFetchAllReferenceableDataAction extends iNoOpAction {
    key: string
    FETCH_FROM_API: {
        all: true
        types: [eEntityActionType, eEntityActionType, eEntityActionType, eEntityActionType]
        body: any
        method: tHttpVerb
        endpoint: string
        resourceName: tCachedResourceName
    }
}

export interface iUpdateCacheAction {
    type: string
    payload: Record<string, never>
}

export interface iUpdateCacheSuccessAction {
    type: string
    payload: {
        response: tNormalizedData
    }
}

export interface iUpdateCacheFailureAction {
    type: string
    payload: {
        error: string
    }
    error: true
}

export interface iInvalidateCacheAction {
    type: "INVALIDATE_CACHE"
    payload: {
        entityType: tCachedResourceName
    }
}

export type tPaginateArgs = {
    types: [eEntityActionType, eEntityActionType, eEntityActionType, eEntityActionType]
    mapActionToKey: {
        (args: iAnyAction): string
    }
}
