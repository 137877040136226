import React, { useState } from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { CSSTransition } from "react-transition-group"
import SideRail from "."
import { iJSONObject, tResourceName } from "../../common/types"
import { SchemaAccessManagerMode } from "../custom-dashboards/schemas/types"
import { tTableFilters } from "../custom-dashboards/types"
import { SideRailProps } from "./types"
import { tProject } from "../../cached-data/types"
import { BulkViewMode } from "../../forms/BulkReviewForm/types"
import { tExtraButtonParams } from "../../toolbar/types"
import { BulkActionAmplitudeMetrics } from "../../forms/BulkReviewForm"
import { StyledOverlay, StyledSideRail } from "./style"
import { FieldFormMode } from "../../forms/FieldFormsForm/types"
import { GridApi } from "ag-grid-community"
import { PicklistAccessItemType } from "../custom-dashboards/picklists/types"
import { tResourceObject } from "../../dashboard-data/types"

export type SideRailConfig =
    | {
          flow: "DATA_TABLE"
          filters: tTableFilters
          config: iJSONObject
          resource: tResourceName
          title: string
          primarySubtitle?: string
          secondarySubtitle?: string
          gridApi?: GridApi
          rowData?: tResourceObject
          openRemapModal?: (params: Record<string, any>) => void
      }
    | { flow: "FILTERS" }
    | { flow: "PROJECT_CREATE" }
    | { flow: "API_INTEGRATION_TOKEN_CREATE" }
    | {
          flow: "PROJECT_MODIFY"
          projectData: tProject
      }
    | {
          flow: "WORKFLOW"
          schemaId: number
      }
    | {
          flow: "MANAGE_PICKLIST_ACCESS"
          type: PicklistAccessItemType
          ids: number[]
          previousConfig: any
          itemTitle: string
          openRemapModal: (params: Record<string, any>) => void
          remapEnabled: boolean
      }
    | {
          flow: "MANAGE_SCHEMA_ACCESS"
          mode: SchemaAccessManagerMode
          schemaId: number
          itemTitle: string
      }
    | {
          flow: "BULK_REVIEW"
          schemaId: number
          gridApi: GridApi
          selectedStoreIds: number[]
          listViewName: string
          mode: BulkViewMode
          extraBtnParams: tExtraButtonParams
          modifyButtonDisabled: boolean
          amplitudeMetrics: BulkActionAmplitudeMetrics
          selectedProject: tProject
      }
    | {
          flow: "FIELD_FORMS"
          schemaId?: number
          selectedRowIds?: number[]
          schemaName?: string
          mode: FieldFormMode
          isNew?: boolean
          formId?: number
          folderPath?: string
          extraBtnParams?: tExtraButtonParams
          modifyButtonDisabled?: boolean
          amplitudeMetrics?: BulkActionAmplitudeMetrics
          projectId?: number
          gridApi?: GridApi
          isBundle?: boolean
          isTransform?: boolean
          relatedCopyToTransform?: Record<string, any>
          listViewFields?: string[]
          listViewContext?: any
          gridId?: string
          setSelectedTab?: (tabId: string) => void
          enableSideRail?: (config: SideRailConfig) => void
          reinitialize?: boolean
      }
    | {
          flow: "BUNDLE"
          selectedRowIds: number[]
          transformId: number
          bundleSchemaId: number
          projectId: number
          schemaId?: number
          extraBtnParams?: tExtraButtonParams
          setSelectedTab?: (tabId: string) => void
          enableSideRail?: (config: SideRailConfig) => void
          reinitialize?: boolean
          bundleVariantName?: string
          listViewFields?: string[]
      }
    | { flow: null }

type SideRailContextData = {
    enableSideRail: (config: SideRailConfig) => void
    disableSideRail: () => void
    sideRailEnabled: boolean
    sideRailConfig: SideRailConfig
    SideRailPortal: React.FC<SideRailProps>
}

export type SideRailContextInstance = React.ContextType<typeof SideRailContext>

const RUNNING_TESTS = process.env.BUILD_ENV === "test"
const sideRailPortalId = "side-rail-portal"
const defaultSideRailConfig: SideRailConfig = { flow: null }
const animationDuration = 400 // ms
const sideRailTransitionClass = "side-rail-transition"

const warn = () => console.warn("Using SideRailContext outside of Provider")
const defaultSideRailContextData: SideRailContextData = {
    enableSideRail: warn,
    disableSideRail: warn,
    sideRailEnabled: false,
    sideRailConfig: defaultSideRailConfig,
    SideRailPortal: (_: SideRailProps) => {
        warn()
        return <></>
    },
}

const SideRailPortal: React.FC<SideRailProps> = props =>
    ReactDOM.createPortal(<SideRail {...props} />, document.getElementById(sideRailPortalId) as HTMLElement)

export const SideRailContext = React.createContext<SideRailContextData>(defaultSideRailContextData)

export const SideRailContextProvider: React.FC = ({ children }) => {
    const [config, setSideRailConfig] = useState<SideRailConfig>(defaultSideRailConfig)
    // We distinguish between 'visible' and 'enabled' state to allow for an animation
    // to complete before removing the component from the DOM.
    // Calling `disableSideRail()` will kick off the animation to hide the UI (visible -> false),
    // and once the animation finishes, the config will be cleared (enabled -> false).
    const sideRailEnabled = config.flow !== null
    const [sideRailVisible, setSideRailVisible] = useState<boolean>(sideRailEnabled)

    const contextData: SideRailContextData = {
        enableSideRail: config => {
            setSideRailConfig(config)
            setSideRailVisible(true)
        },
        disableSideRail: () => {
            setSideRailVisible(false)
            if (RUNNING_TESTS) {
                setSideRailConfig({ flow: null })
            }
        },
        sideRailEnabled,
        sideRailConfig: config,
        SideRailPortal,
    }

    // Selenium tests have a really hard time finding elements with the CSS Transition.
    // To get around that, just skip the animation while running tests.
    // Also skip the animation if the flag is off
    const sideRailPortalElement = RUNNING_TESTS ? (
        <div id={sideRailPortalId} />
    ) : (
        <CSSTransition
            in={sideRailVisible}
            classNames={sideRailTransitionClass}
            timeout={animationDuration}
            onExited={() => setSideRailConfig({ flow: null })}
        >
            <AnimatedSideRailContainer id={sideRailPortalId} />
        </CSSTransition>
    )

    return (
        <SideRailContext.Provider value={contextData}>
            {children}
            {sideRailPortalElement}
        </SideRailContext.Provider>
    )
}

const AnimatedSideRailContainer = styled.div`
    ${StyledSideRail} {
        transition: transform ${animationDuration}ms;
        transform: translateX(100%);
    }

    ${StyledOverlay} {
        transition: opacity ${animationDuration}ms;
        opacity: 0;
    }

    &.${sideRailTransitionClass}-enter {
        ${StyledSideRail} {
            transform: translateX(100%);
        }
        ${StyledOverlay} {
            opacity: 0;
        }
    }

    &.${sideRailTransitionClass}-enter-active, &.${sideRailTransitionClass}-enter-done {
        ${StyledSideRail} {
            transform: translateX(0);
        }
        ${StyledOverlay} {
            opacity: 1;
        }
    }

    &.${sideRailTransitionClass}-exit {
        ${StyledSideRail} {
            transform: translateX(0);
        }
        ${StyledOverlay} {
            opacity: 1;
        }
    }

    &.${sideRailTransitionClass}-exit-active, &.${sideRailTransitionClass}-exit-done {
        ${StyledSideRail} {
            transform: translateX(100%);
        }
        ${StyledOverlay} {
            opacity: 0;
        }
    }
`
