import memoize from "memoize-one"
import { createSelector } from "reselect"
import { getRowData } from "../common/ag-grid-utils"

import { projectsSelector } from "./projects"
import { costCodesSelector } from "./cost-codes"

const getRelevantSourceData = (allSourceData, resources) =>
    resources.reduce((acc, resource) => {
        acc[resource] = allSourceData[resource]
        return acc
    }, {})

const memoizedGetRelevantSourceData = memoize(getRelevantSourceData)

const memoizedGetRowData = memoize(getRowData)

export const sourceDataSelector = (state, resources = []) => {
    const allSourceData = state.sourceData?.sourceData
    if (!allSourceData) return null
    return memoizedGetRelevantSourceData(allSourceData, resources)
}

/**
 * This is used to ensure that when rowDataSelector returns an empty array, the
 * Array object reference doesn't change, thus preventing unnecessary re-renders when rowData is empty.
 */
const EMPTY_ROW_DATA_REFERENCE = []

export const rowDataSelector = (state, resources = []) => {
    const sourceData = sourceDataSelector(state, resources)
    return sourceData ? memoizedGetRowData(sourceData) : EMPTY_ROW_DATA_REFERENCE
}

/**
 * These selectors are used to return the referenceable resources associated
 * with a custom table's row data as an array of objects.
 */
export const referenceableIdsSelector = state => state.referenceableIds

export const companyAbsenceTypesSelector = state => state.entities.companyAbsenceTypes?.objects || {}

export const companyClassificationsSelector = state => state.entities.companyClassifications?.objects || {}

export const companyCrewTypesSelector = state => state.entities.companyCrewTypes?.objects || {}

export const companyFormSchemasSelector = state => {
    const relatedSchemas = {}
    // we store bundle schemas in a diff place in the store, add them to the referencable schemas
    // so we can still check them for available status updates
    state.relatedSchemas?.relatedSchemas?.forEach(schema => {
        relatedSchemas[schema.id] = schema
    })
    return { ...state.entities.companyFormSchemas?.objects, ...relatedSchemas } || {}
}

export const companyFormStoresSelector = state => state.entities.companyFormStores?.objects || {}

export const companyGroupsSelector = state => state.entities.companyGroups?.objects || {}

export const companyStartStopTypesSelector = state => state.entities.companyStartStopTypes?.objects || {}

export const companyTradesSelector = state => state.entities.companyTrades?.objects || {}

export const costItemsSelector = state => state.entities.costItems?.objects || {}

export const changeOrderSelector = state => state.entities.changeOrders?.objects || {}

export const employeesSelector = state => state.entities.employees?.objects || {}

export const employeeTradesSelector = state => state.entities.employeeTrades?.objects || {}

export const employeeClassificationsSelector = state => state.entities.employeeClassifications?.objects || {}

export const guestFormSharesSelector = state => state.entities.guestFormShares?.objects || {}

export const employeeSchemasSelector = state => state.entities.employeeSchemas?.objects || {}

export const equipmentSelector = state => state.entities.equipment?.objects || {}

export const companiesSelector = state => state.entities.companies?.objects || {}

export const analyticsDashboardsSelector = state => state.entities.analyticsDashboards?.objects || {}

export const schemaStatusNamesSelector = state => state.entities.schemaStatusNames?.objects || {}

export const materialsSelector = state => state.entities.materials?.objects || {}

export const projectMaterialsSelector = state => state.entities.projectMaterials?.objects || {}

export const projectEmployeesSelector = state => state.entities.projectEmployees?.objects || {}

export const projectEquipmentSelector = state => state.entities.projectEquipment?.objects || {}

export const picklistItemsSelector = state => state.entities.picklistItems?.objects || {}

export const timekeepingStatusesSelector = state => state.entities.timekeepingStatuses?.objects || {}

export const workShiftsSelector = state => state.entities.workShifts?.objects || {}

export const cohortSelector = state => state.entities.cohorts?.objects || {}

export const referenceableDataSelector = createSelector(
    cohortSelector,
    companyAbsenceTypesSelector,
    companyClassificationsSelector,
    companyCrewTypesSelector,
    companyFormSchemasSelector,
    companyFormStoresSelector,
    companyGroupsSelector,
    companyStartStopTypesSelector,
    companyTradesSelector,
    costCodesSelector,
    costItemsSelector,
    changeOrderSelector,
    employeesSelector,
    employeeTradesSelector,
    employeeClassificationsSelector,
    guestFormSharesSelector,
    picklistItemsSelector,
    projectEmployeesSelector,
    employeeSchemasSelector,
    equipmentSelector,
    projectEquipmentSelector,
    projectsSelector,
    materialsSelector,
    projectMaterialsSelector,
    companiesSelector,
    schemaStatusNamesSelector,
    analyticsDashboardsSelector,
    timekeepingStatusesSelector,
    workShiftsSelector,
    (
        cohorts,
        companyAbsenceTypes,
        companyClassifications,
        companyCrewTypes,
        companyFormSchemas,
        companyFormStores,
        companyGroups,
        companyStartStopTypes,
        companyTrades,
        costCodes,
        costItems,
        changeOrders,
        employees,
        employeeTrades,
        employeeClassifications,
        guestFormShares,
        picklistItems,
        projectEmployees,
        employeeSchemas,
        equipment,
        projectEquipment,
        projects,
        materials,
        projectMaterials,
        companies,
        schemaStatusNames,
        analyticsDashboards,
        timekeepingStatuses,
        workShifts
    ) => ({
        cohorts,
        companyAbsenceTypes,
        companyClassifications,
        companyCrewTypes,
        companyFormSchemas,
        companyFormStores,
        companyGroups,
        companyStartStopTypes,
        companyTrades,
        costCodes,
        costItems,
        changeOrders,
        employees,
        employeeTrades,
        employeeClassifications,
        guestFormShares,
        picklistItems,
        projectEmployees,
        employeeSchemas,
        equipment,
        projectEquipment,
        projects,
        materials,
        projectMaterials,
        companies,
        schemaStatusNames,
        analyticsDashboards,
        timekeepingStatuses,
        workShifts,
    })
)

// Determine whether all the referenceable data for a custom dashboard has
// finished loading
export const referenceableDataForRowDataLoaded = state => {
    const referenceablesLoading = state.referenceablesLoading
    const referenceableResourceIds = state.referenceableIds || {}
    const referenceableResources = Object.keys(referenceableResourceIds)
    return !referenceableResources.find(referenceableResource => {
        return referenceablesLoading[referenceableResource].isLoading === true
    })
}

// Determine whether an error occurred while loading referencable data
export const referenceableDataLoadingError = state => {
    const referenceablesLoading = state.referenceablesLoading
    const referenceableResourceIds = state.referenceableIds || {}
    const referenceableResources = Object.keys(referenceableResourceIds)

    const hasError = referenceableResources.find(referenceableResource => {
        return !!referenceablesLoading[referenceableResource].error
    })

    return hasError ? referenceablesLoading[hasError].error : null
}

// Selector function for the copy To transforms. Placing the logic here to prevent a new array reference
// from being created every time you hover over a cell, or make any changes to the list view
const getRelatedTransforms = state => state.relatedTransforms?.relatedTransforms
// I couldn't figure out how to get createSelector to work with a flag so this first one can be removed
// when we clean up WA-7878-copy-to-import-cross-schema
export const selectRelatedCopyToTransforms = createSelector(getRelatedTransforms, relatedTransforms =>
    relatedTransforms?.filter(
        (transform => transform.from_schemas?.includes(transform.to_schema) && !transform.is_bundle) || []
    )
)
export const selectRelatedCopyToTransformsBySchemaIds = createSelector(
    [getRelatedTransforms, (state, schemaIds) => schemaIds],
    (relatedTransforms, schemaIds) => {
        return relatedTransforms?.filter(
            (transform =>
                transform.from_schemas?.filter(schema => schemaIds.includes(schema.id)) && !transform.is_bundle) ||
                []
        )
    }
)

// Added a selector for the transformsForImport field mostly for consistency -
// no filtering is needed at this point
export const selectTransformsForImport = state => state.transformsForImport?.transformsForImport

// selector to filter for bundle transforms
export const selectBundleTransforms = createSelector(getRelatedTransforms, relatedTransforms =>
    relatedTransforms?.filter((transform => transform?.is_bundle) || [])
)

// Retrieve the audit history from state, and make sure that
// each row has a gridId
export const auditHistorySelector = state => {
    const updatedAuditHistory = {}

    // Ensure that each row for each resource in the auditHistory has a gridId
    if (state.auditHistory?.response) {
        Object.entries(state.auditHistory.response).forEach(([key, value]) => {
            updatedAuditHistory[key] = value.map((row, index) => {
                if (!row.gridId) {
                    // The entity's own history ID is the best choice for the gridId.
                    // id won't work, since it will be repeated by historical versions of the same record.
                    // If history ID is absent, fall back to using the index
                    const gridId = row.history_id ? row.history_id : index

                    return { ...row, gridId }
                } else return row
            })
        })
    }

    return updatedAuditHistory
}
