import { SearchBarAction } from "../actions/search-bar"
import { SearchBarState } from "../common/types"

const initialState: SearchBarState = {
    searchTerm: "",
    fancySearchTerm: { term: "", category: "", doFilter: true },
    isFancySearchVisible: false,
    placeholdersVisible: true,
}

const searchBar = (state = initialState, action: SearchBarAction): SearchBarState => {
    switch (action.type) {
        case "SET_SEARCH_TERM":
            return { ...state, searchTerm: action.searchTerm }
        case "SET_FANCY_SEARCH_TERM":
            return { ...state, fancySearchTerm: action.searchTerm }
        case "TOGGLE_FANCY_SEARCH_VISIBLE":
            return { ...state, isFancySearchVisible: !state.isFancySearchVisible }
        case "TOGGLE_PLACEHOLDERS_VISIBLE":
            return { ...state, placeholdersVisible: !state.placeholdersVisible }
        default:
            return state
    }
}

export default searchBar
