const canParseAsNumber = value => isFinite(value) && isFinite(parseFloat(value))

export const numberValueParser = params =>
    canParseAsNumber(params.newValue) ? Number(params.newValue) : params.newValue

export const numberOrNullValueParser = params => (params.newValue === "" ? null : numberValueParser(params))

export const hoursToMinutesValueParser = params =>
    canParseAsNumber(params.newValue) ? Math.round(params.newValue * 60) : params.newValue

export const USDValueParser = params =>
    // currency fields should be integers, if the value cannot be parsed as a number, replace the value with zero
    canParseAsNumber(params.newValue) ? Math.round(params.newValue * 100) : 0
