import React, { useContext } from "react"

/** Components */
import { SideRailContext } from "../../SideRail/SideRailContext"
/** Types */
import { tProject } from "../../../cached-data/types"
import { ResourceObject } from "../../../dashboard-data/types"

type Props = {
    data: ResourceObject<tProject>
}

export const ModifyButtonCellRenderer: React.FC<Props> = ({ data }) => {
    const { enableSideRail } = useContext(SideRailContext)
    const startModifyFlow = () => {
        const projectId = data.id
        if (projectId) enableSideRail({ flow: "PROJECT_MODIFY", projectData: data })
    }

    return (
        <button onClick={startModifyFlow} type="button" className="action-button">
            <div>Modify</div>
        </button>
    )
}

export default ModifyButtonCellRenderer
