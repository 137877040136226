import React from "react"
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from "react-datepicker"
import { MINUTE_INTERVAL } from "../../../common/constants"
import { getAsDate, getDateTimeFormats } from "../../../common/ts-utils"
import { getFlagEnabled } from "../../../getFlagValue"

import "react-datepicker/dist/react-datepicker.css"

export default class TimeCellEditor extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedValue:
                getFlagEnabled("WA-7827-date-picker-default") &&
                // If we have an initial value getter, we didn't have a value already, and the initial value
                // getter returns a value (it won't if it's a break-type start/stop time), then we use that.
                this.props.getInitialValue &&
                !this.props.value &&
                this.props.getInitialValue(props)
                    ? this.props.getInitialValue(props)
                    : this.props.value ?? null,
        }
    }

    handleChange = (value, e) => {
        this.setState({ selectedValue: value ? value.toISOString() : null }, () => {
            if (this.props.stopEditAfterSelect) this.props.api.stopEditing()
            // The event is undefined when a time was selected (only defined when a date is selected).
            // This is not a good way to determine when a time was selected (versus a date),
            // but there doesn't seem to be a better way surfaced in the DatePicker API.
            if (this.props.stopEditAfterSelectTime && !e) this.props.api.stopEditing()
        })
    }

    getValue() {
        return this.state.selectedValue ? this.state.selectedValue : this.props.default
    }

    componentDidMount() {
        this._calendar.deferFocusInput()
    }

    render() {
        const {
            context,
            colDef,
            getOpenToDate,
            getMaxDate,
            getMinDate,
            maxDate: maxDateProp,
            minDate: minDateProp,
            mode,
            minuteInterval,
            militaryTime,
        } = this.props
        const datetimeFormat = getDateTimeFormats(mode, militaryTime)
        const selectedValue = this.state.selectedValue ? getAsDate(this.state.selectedValue) : null
        const minInterval = minuteInterval ? minuteInterval : MINUTE_INTERVAL
        const tableColumns = context.settings.colDefs
        const popperClass =
            tableColumns[tableColumns.length - 1].headerName === colDef.headerName
                ? "most-right-column-datepicker"
                : ""
        const openToDate = getOpenToDate ? getOpenToDate(this.props) : null
        const minDate = getMinDate ? getMinDate(this.props) : minDateProp
        const maxDate = getMaxDate ? getMaxDate(this.props) : maxDateProp
        return (
            <div className="datepicker-div">
                <DatePicker
                    className="form-control"
                    dateFormat={datetimeFormat.dateFormat}
                    maxDate={maxDate}
                    minDate={minDate}
                    openToDate={openToDate}
                    onChange={this.handleChange}
                    popperProps={{ positionFixed: true }}
                    popperClassName={popperClass}
                    ref={c => (this._calendar = c)}
                    selected={selectedValue}
                    showTimeSelect={mode != "date"}
                    showTimeSelectOnly={mode == "time"}
                    timeFormat={datetimeFormat.timeFormat}
                    timeIntervals={minInterval}
                />
            </div>
        )
    }
}
