interface iSetSearchTerm {
    type: "SET_SEARCH_TERM"
    searchTerm: string
}

interface iSetFancySearchTerm {
    type: "SET_FANCY_SEARCH_TERM"
    searchTerm: { term: string[] | string; category: string; doFilter: boolean }
}

interface iToggleFancySearchVisible {
    type: "TOGGLE_FANCY_SEARCH_VISIBLE"
}

interface iTogglePlaceholdersVisible {
    type: "TOGGLE_PLACEHOLDERS_VISIBLE"
}

export type SearchBarAction =
    | iSetSearchTerm
    | iSetFancySearchTerm
    | iToggleFancySearchVisible
    | iTogglePlaceholdersVisible

export const setSearchTerm = (searchTerm: string): iSetSearchTerm => {
    return {
        type: "SET_SEARCH_TERM",
        searchTerm,
    }
}

export const setFancySearchTerm = (searchTerm: {
    term: string[] | string
    category: string
    doFilter: boolean
}): iSetFancySearchTerm => {
    return {
        type: "SET_FANCY_SEARCH_TERM",
        searchTerm,
    }
}

export const toggleFancySearchVisible = (): iToggleFancySearchVisible => {
    return {
        type: "TOGGLE_FANCY_SEARCH_VISIBLE",
    }
}
