import makeRequest from "../networkClient"
import Rmbx from "../util"
import { urlForResource } from "../common/constants"
import { projectUpdateFailed, projectCreateFailed } from "../actions"
import { showNotificationWithTimeout } from "../notifications/actions"
import { setFilterValue, setFilterState } from "../filters/actions"
import { updateMarkupsData } from "../markup/actions"
import { actionTypesForResource } from "./constants"
import { AsyncThunk, EmployeeFormStore, tHttpVerb, Thunk } from "../common/types"
import {
    iFetchAllReferenceableDataAction,
    iFetchSingleProjectAction,
    iInvalidateCacheAction,
    ProjectFormData,
    iResetErrorMessageAction,
    iResourceEntityState,
    iResourcePaginationState,
    iUpdateCacheAction,
    iUpdateCacheFailureAction,
    iUpdateCacheSuccessAction,
    tCachedResourceName,
    tNormalizedData,
} from "./types"

// Pagination Settings --------------------------------------------------------

export const DEFAULT_PAGE_SIZE = 10000
export const PROJECT_PAGE_SIZE = 250

// Utility Functions ----------------------------------------------------------

/**
 * Determine which IDs need to be fetched from the server
 */
export const getIdsToFetch = (
    entityState: iResourceEntityState,
    ids: Array<number | string> = []
): Array<number | string> => {
    const cachedObjects = entityState.objects || {}
    return ids.filter(id => !cachedObjects[id])
}

/**
 * Construct the query string for a request for referenceable resources
 */
export const constructReferenceableQueryString = (resourceName: tCachedResourceName): string => {
    const queryParams = { page_size: DEFAULT_PAGE_SIZE } as any

    // If this is a request for cost codes, we need an extra query param
    if (resourceName === "costCodes") {
        queryParams["minimal_project_detail"] = true
        queryParams["include_inactive"] = "TRUE"
        queryParams["ignore_controls"] = "TRUE"
    } else if (
        // The following resources default to returning only the active entities -
        // add the include_inactive param here
        resourceName === "companyAbsenceTypes" ||
        resourceName === "companyTrades" ||
        resourceName === "companyClassifications" ||
        resourceName === "picklistItems"
    ) {
        queryParams["include_inactive"] = "true"
    } else if (resourceName === "companyFormSchemas") {
        // we want to fetch all types of schemas (bundle and normal)
        queryParams["all_types"] = "true"
    }

    return Rmbx.util.serializeQueryParams(queryParams)
}

// Error Handling -------------------------------------------------------------

/**
 * Clears the error message
 */
export const resetErrorMessage = (): iResetErrorMessageAction => ({
    type: "RESET_ERROR_MESSAGE",
    payload: {},
})

// Actions: Not Exported ------------------------------------------------------

/**
 * Fetches a single project.
 * Depends on the API middleware defined in ../middleware/api.js
 */
const _fetchSingleProject = (projectId?: number): iFetchSingleProjectAction => {
    const endpointUrl = projectId
        ? `/api/v4/projects/?page_size=1&project_id=${projectId}`
        : "/api/v4/projects/?page_size=1"

    return {
        type: "@__NO_OP__", // all actions must have a type
        key: "page_size=1",
        FETCH_FROM_API: {
            all: false,
            types: actionTypesForResource["projects"],
            endpoint: endpointUrl,
            resourceName: "projects",
        },
    }
}

/**
 * Fetches the entire set of data for the given referenceable resource type.
 * Depends on the API middleware defined in ../middleware/api.js
 */
const _fetchAllReferenceableData = (
    resourceName: tCachedResourceName,
    queryString: any,
    key: string,
    body: any = {},
    method: tHttpVerb = "GET",
    searchByName = false
): iFetchAllReferenceableDataAction => {
    const url = urlForResource[resourceName]
    const types = actionTypesForResource[resourceName]
    const endpoint =
        method === "POST"
            ? searchByName
                ? `${url}name_filter/?${queryString}`
                : `${url}id_filter/?${queryString}`
            : `${url}?${queryString}`

    return {
        type: "@__NO_OP__", // all actions must have a type
        key,
        FETCH_FROM_API: {
            all: true,
            types,
            resourceName,
            endpoint,
            body,
            method,
        },
    }
}

// Actions: Cache Mutations ---------------------------------------------------

/**
 * Dispatched when a network request that will result in a mutation is initiated
 */
export const updateCache = (actionType: string): iUpdateCacheAction => ({
    type: actionType,
    payload: {},
})

/**
 * Dispatched when a network request that results in a mutation succeeded
 */
export const updateCacheSuccess = (
    normalizedData: tNormalizedData,
    actionType: string
): iUpdateCacheSuccessAction => ({
    type: actionType,
    payload: {
        response: normalizedData,
    },
})

/**
 * Dispatched when a network request that would have resulted in a mutation
 * failed.
 */
export const updateCacheFailure = (error: Error, actionType: string): iUpdateCacheFailureAction => ({
    type: actionType,
    payload: {
        error: error.toString(),
    },
    error: true,
})

/**
 * Dispatched to toggle cache invalidation for a given entity type
 */
export const invalidateCache = (entityType: tCachedResourceName): iInvalidateCacheAction => ({
    type: "INVALIDATE_CACHE",
    payload: {
        entityType,
    },
})

// Actions: Cache-First Data Loading ------------------------------------------

/**
 * Loads the entire set of entities of provided type, fetching additional items
 * into the cache if not already present.
 *
 * Each page of entities will be made available in the redux store as soon as
 * it's been fetched. New pages will be fetched sequentially until `nextPageUrl`
 * is `null`. Bails out if we've already fetched at least one page and
 * `nextPageUrl` is null, if we have requests for entities already in-flight,
 * or if the cache has been invalidated.
 *
 * Depends on the redux-thunk middleware
 */
export const loadAllEntities = (
    resourceType: tCachedResourceName,
    options?: { forceBigPageSize?: boolean }
): Thunk => (dispatch, getState) => {
    const paginationState = getState().pagination[resourceType]
    const key = "all"
    const { isFetching = false, invalidate = false, pageCount = 0, nextPageUrl = null } = paginationState[key] || {}
    const { forceBigPageSize } = { forceBigPageSize: false, ...options }

    const queryParams = {
        // TODO: (huge) FF and SE dashboards currently only support loading one page of up to 50k projects.
        // A company with more than 50k projects won't completely load,
        // but the Manage Access RR likely can't support that many projects anyways.
        // Manage Access RR needs to be re-designed with large companies in mind.
        page_size: forceBigPageSize ? 50000 : resourceType === "projects" ? PROJECT_PAGE_SIZE : DEFAULT_PAGE_SIZE,
    } as any
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resourceType === "costCodes" ? (queryParams["minimal_project_detail"] = true) : () => {}
    const queryString = Rmbx.util.serializeQueryParams(queryParams)

    // Make a network request in the following circumstances:
    //   - We invalidated the cache, OR
    //   - We haven't finished downloading all the pages of this data from the
    //     server, and we aren't in the process of doing that
    if (invalidate || (!isFetching && !(pageCount > 0 && !nextPageUrl))) {
        dispatch(_fetchAllReferenceableData(resourceType, queryString, key))
    }
}

/**
 * Loads a single project, fetching it into cache if not already present.
 * If projectId is present, then load that project otherwise just load the first project.
 * This is a temporary (?) solution that allows us to use WTC as a landing
 * page without having All Projects selected.
 *
 * Depends on the redux-thunk middleware
 */
export const loadSingleProject = (projectId?: number): Thunk => (dispatch, getState) => {
    const paginationState = getState().pagination.projects as iResourcePaginationState
    const { isFetching = false, invalidate = false, pageCount = 0 } = paginationState["page_size=1"] || {}

    // Make a network request if we invalidated the cache or if we don't have a
    // page yet and aren't in the process of downloading one
    if (invalidate || (!isFetching && pageCount < 1) || projectId) {
        dispatch(_fetchSingleProject(projectId))
    }
}

export const loadSchemaStatusNames = (schemaStatusNames: Array<string>, schemaNames: Array<string>): Thunk => (
    dispatch,
    getState
) => {
    const paginationState = getState().pagination.schemaStatusNames as iResourcePaginationState
    const queryParams = {
        schema_status_names: schemaStatusNames,
        schema_names: schemaNames,
        page_size: DEFAULT_PAGE_SIZE,
    } as any

    const queryString = Rmbx.util.serializeQueryParams(queryParams)
    const key = queryString
    const { isFetching = false, invalidate = false, nextPageUrl = null, pageCount = 0 } = paginationState[key] || {}

    if (invalidate || (!isFetching && !(pageCount > 0 && !nextPageUrl))) {
        dispatch(_fetchAllReferenceableData("schemaStatusNames", queryString, key))
    }
}

/**
 * Loads the entire set of cost codes for a given project, fetching additional
 * items into the cache if not already present.
 *
 * Each page of cost codes will be made available in the redux store as soon as
 * it's been fetched. New pages will be fetched sequentially until `nextPageUrl`
 * is `null`. Bails out if we've already fetched at least one page and
 * `nextPageUrl` is null, if we have requests for these cost codes already
 * in-flight, or if the cache has been invalidated.
 *
 * Depends on the redux-thunk middleware
 */
export const loadAllCostCodesForProjectId = (projectId: number | Array<number> | "all" = "all"): Thunk => (
    dispatch,
    getState
) => {
    const paginationState = getState().pagination.costCodes as iResourcePaginationState
    const queryParams = {
        minimal_project_detail: true,
        page_size: DEFAULT_PAGE_SIZE,
    } as any

    if (projectId && projectId !== "all") {
        queryParams.project_id = projectId
    }

    const queryString = Rmbx.util.serializeQueryParams(queryParams)
    const key = queryString
    const { isFetching = false, invalidate = false, nextPageUrl = null, pageCount = 0 } = paginationState[key] || {}

    // Make a network request in the following circumstances:
    //   - We invalidated the cache, OR
    //   - We haven't finished downloading all the pages of this data from the
    //     server, and we aren't in the process of doing that
    if (invalidate || (!isFetching && !(pageCount > 0 && !nextPageUrl))) {
        dispatch(_fetchAllReferenceableData("costCodes", queryString, key))
    }
}

/**
 * Loads the entire set of shift extra ("employee") schemas for a given project,
 * fetching additional items into the cache if not already present.
 *
 * Each page of schemas will be made available in the redux store as soon as
 * it's been fetched. New pages will be fetched sequentially until `nextPageUrl`
 * is `null`. Bails out if we've already fetched at least one page and
 * `nextPageUrl` is null, if we have requests for these schemas already
 * in-flight, or if the cache has been invalidated.
 *
 * Depends on the redux-thunk middleware
 */
export const loadAllEmployeeSchemasForProjectId = (projectId: number | Array<number> | "all" = "all"): Thunk => (
    dispatch,
    getState
) => {
    const paginationState = getState().pagination.employeeSchemas
    const queryParams = { page_size: DEFAULT_PAGE_SIZE } as any

    if (projectId && projectId !== "all") {
        queryParams.project_id = projectId
    }

    // Discover SE schema IDs that are referenced by downloaded SE form stores.
    let shiftExtraSchemas: number[] =
        (getState().sourceData.sourceData.employeeEntries as EmployeeFormStore[] | undefined)?.map(
            (store: EmployeeFormStore) => store.schema,
            []
        ) ?? []
    // Remove duplicate SE schema IDs.
    shiftExtraSchemas = [...new Set(shiftExtraSchemas)]

    queryParams.include_ids = shiftExtraSchemas

    const queryString = Rmbx.util.serializeQueryParams(queryParams)
    const key = queryString
    const { isFetching = false, invalidate = false, nextPageUrl = null, pageCount = 0 } = paginationState[key] || {}

    // Make a network request in the following circumstances:
    //   - We invalidated the cache
    //   - We aren't currently fetching this data, but we haven't downloaded
    //     all of the pages from the API server
    if (invalidate || (!isFetching && !(pageCount > 0 && !nextPageUrl))) {
        dispatch(_fetchAllReferenceableData("employeeSchemas", queryString, key))
    }
}

/**
 * Loads the entire set of data for the given referenceable resource by ID,
 * fetching additional items into the cache if not already present.
 *
 * If an item in the `ids` list already appears in the cache, it will not be
 * re-fetched unless the cache has been invalidated in either the entity or
 * pagination state for this resource type. If the `ids` list is empty, nothing
 * is fetched at all. If the paginated endpoint returns more than one page of
 * results, each page will be fetched sequentially until `nextPageUrl` is `null`.
 * Bails out if a request for these ids is already in-flight.
 *
 * Depends on the redux-thunk middleware
 */
export const loadAllReferenceableData = (
    resourceName: tCachedResourceName,
    ids: Array<number | string> = [],
    searchByName = false
): Thunk => (dispatch, getState) => {
    if (!ids) {
        return
    }

    const state = getState()
    const paginationState = state.pagination[resourceName] as iResourcePaginationState
    const entityState = state.entities[resourceName] as iResourceEntityState
    const invalidateEntities = entityState.invalidate
    const queryString = constructReferenceableQueryString(resourceName)

    let idsToFetch = null
    const body = {} as any

    // Unless we need to invalidate the cache, only fetch items that we
    // don't already have
    if (invalidateEntities) {
        idsToFetch = ids
    } else {
        idsToFetch = getIdsToFetch(entityState, ids)
    }

    // If all requested items are already in the cache, we can just bail out.
    // Otherwise, we need to add a query param to filter for the IDs we want
    // from the server.
    if (Array.isArray(idsToFetch) && !idsToFetch.length) {
        return
    } else if (searchByName) body["names"] = idsToFetch
    else {
        body["ids"] = idsToFetch
    }

    const key = queryString ? queryString : "all"
    const { isFetching = false } = paginationState[key] || {}

    if (!isFetching) {
        dispatch(_fetchAllReferenceableData(resourceName, queryString, key, body, "POST", searchByName))
    }
}

/**
 * Populates the entities state with the entities data in the action.
 * Useful for loading entities from the session storage cache.
 *
 * This works because the cachedData reducer skims ALL dispatched actions
 * looking for this particular action payload structure.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const loadEntities = (entities: Record<string, any>) => ({
    type: "LOAD_ENTITIES",
    payload: {
        response: {
            entities,
        },
    },
})

export const loadEntitiesFromStorage = (): Thunk => dispatch => {
    const cachedEntities = {
        companyGroups: Rmbx.store.get("groupId"),
        projects: Rmbx.store.get("projectId"),
    }
    dispatch(loadEntities(cachedEntities))
}

// Actions: Mutations ---------------------------------------------------------

/**
 * Creates a new project, updating the projects list and entity cache
 *
 * Depends on the redux-thunk middleware
 */
// TODO: Remove this action with old CreateProjectForm
export const createProject = (project: ProjectFormData): AsyncThunk => async dispatch => {
    const url = "/api/v4/projects/"

    dispatch(updateCache("CREATE_PROJECT"))

    try {
        const response = await makeRequest(
            {
                url,
                method: "POST",
                body: JSON.stringify(project),
            },
            201
        )

        const normalizedResult = Rmbx.util.formatApiResponse(response, "projects") as tNormalizedData

        dispatch(updateCacheSuccess(normalizedResult, "CREATE_PROJECT_SUCCESS"))
        dispatch(showNotificationWithTimeout("Project created!", 4000))

        const newProjectId = normalizedResult.result[0]

        dispatch(setFilterValue("projectId", newProjectId))
        dispatch(setFilterState({ projectId: newProjectId }))

        Rmbx.util.history.push("/rhumbix/projects/edit/")
    } catch (e) {
        const error = e as any
        if (error.response && error.response.data) {
            dispatch(projectCreateFailed(error.response.data))
        }
        dispatch(updateCacheFailure(error, "CREATE_PROJECT_FAILURE"))
    }
}

/**
 * Updates an existing project, updating the projects list and entity cache
 *
 * Depends on the redux-thunk middleware
 */
// TODO: Remove this action with old RegisterProjectForm
export const updateProject = (id: number, project: ProjectFormData): AsyncThunk => async dispatch => {
    const url = `/api/v4/projects/${id}/`

    dispatch(updateCache("UPDATE_PROJECT"))

    try {
        const response = await makeRequest(
            {
                url,
                method: "PUT",
                body: JSON.stringify(project),
            },
            200
        )

        const normalizedResult = Rmbx.util.formatApiResponse(response, "projects") as tNormalizedData

        dispatch(updateCacheSuccess(normalizedResult, "UPDATE_PROJECT_SUCCESS"))
        dispatch(showNotificationWithTimeout("Successfully saved.", 4000))

        if (project && project.markups) {
            const markups = new Map()
            project.markups.forEach(markup => {
                markups.set(markup.markup_type, markup)
            })
            dispatch(updateMarkupsData(markups))
        }

        const updatedProjectId = normalizedResult.result[0]

        dispatch(setFilterValue("projectId", updatedProjectId))
        dispatch(setFilterState({ projectId: updatedProjectId }))
    } catch (e) {
        const error = e as any
        if (error.response && error.response.data) {
            dispatch(projectUpdateFailed(error.response.data))
        }
        dispatch(updateCacheFailure(error, "UPDATE_PROJECT_FAILURE"))
    }
}
