import * as API from "../api"
import { getSourceDataForGroupKeyInfo } from "../common/ag-grid-grouping-utils"
import { sourceDataUpdated, sourceDataDeleted, actionError, setSaveStatus } from "../dashboard-data/actions"
import { getFlagEnabled } from "../getFlagValue"
import { setNotificationMessage } from "../websockets/actions"
import { PDF_CREATING } from "../websockets/types"
import { statusesByUserRole } from "../common/constants"
import { timekeepingDataIsEditable } from "../common/ag-grid-utils"

const getBulkActionParams = (sourceData, filters, initialActionParams) => {
    const actionParams = Object.assign({}, initialActionParams)
    // an ugly way to allow button settings to reference filters on the dashboard (ex: projectId)
    for (const key in initialActionParams) {
        if (filters[key] != null) {
            actionParams[key] = filters[key]
        }
    }

    actionParams.resourceToId = {}
    // convert dictionary of resources to rows to dictionary of resources to ids
    for (const resource in sourceData) {
        actionParams.resourceToId[resource] = {
            gridIds: sourceData[resource].map(obj => obj.gridId),
            // rows that have not been synced to the server (ex: an empty row) do not have ids yet,
            // filter them out, we don't want to send undefined ids to the server
            object_ids: sourceData[resource].map(obj => obj.id).filter(id => id),
        }
    }
    return actionParams
}

const getSourceDataWithUpdatedValues = (sourceData, field, value) => {
    const updatedSourceData = {}
    for (const resource in sourceData) {
        updatedSourceData[resource] = sourceData[resource].map(row => {
            return { ...row, [field]: value }
        })
    }
    return updatedSourceData
}

export const bulkWorkflowAction = (sourceData, filters, actionParams) => dispatch => {
    const promises = []
    const apiActionParams = getBulkActionParams(sourceData, filters, actionParams)
    dispatch(setSaveStatus("in-progress"))

    for (const resourceName in apiActionParams.resourceToId) {
        promises.push(
            new Promise((resolve, reject) => {
                API.workflowActionById(
                    resourceName,
                    apiActionParams.resourceToId[resourceName].object_ids,
                    apiActionParams.action,
                    apiActionParams.queryParams
                )
                    .then(result => {
                        if (getFlagEnabled("WA-7925-ff-status-update-pdf"))
                            dispatch(
                                setNotificationMessage(PDF_CREATING, "info", {
                                    ignoreIds: {
                                        companyFormStores: apiActionParams.resourceToId[resourceName].object_ids,
                                    },
                                })
                            )
                        resolve({
                            resourceName: resourceName,
                            data: result,
                        })
                    })
                    .catch(result =>
                        reject({
                            resource: resourceName,
                            response: result.response.data,
                        })
                    )
            })
        )
    }
    return Promise.all(promises).then(
        response => {
            const updatedSourceData = {}
            // we need to append the correct gridIds to the rows in the server response
            response.forEach(resourceResponse => {
                updatedSourceData[resourceResponse.resourceName] = resourceResponse.data.map(responseRow => {
                    const gridRow = sourceData[resourceResponse.resourceName].find(
                        gridRow => gridRow.id === responseRow.id
                    )
                    return { ...responseRow, gridId: gridRow.gridId }
                })
            })
            dispatch(setSaveStatus("saved"))
            return dispatch(sourceDataUpdated(updatedSourceData))
        },
        reason => {
            dispatch(setSaveStatus("failed"))
            errorResponseHandler(
                dispatch,
                sourceData,
                reason,
                "These rows cannot be updated.",
                actionParams.showErrorModal
            )
        }
    )
}

export const bulkUpdateSourceDataField = (sourceData, filters, actionParams) => dispatch => {
    const promises = []
    const apiActionParams = getBulkActionParams(sourceData, filters, actionParams)
    dispatch(setSaveStatus("in-progress"))
    for (const resourceName in apiActionParams.resourceToId) {
        promises.push(
            new Promise((resolve, reject) => {
                API.updateItemsValueById(
                    resourceName,
                    apiActionParams.resourceToId[resourceName].object_ids,
                    apiActionParams.field,
                    apiActionParams.value
                )
                    .then(_ => resolve())
                    .catch(result =>
                        reject({
                            resource: resourceName,
                            response: result.response.data,
                        })
                    )
            })
        )
    }

    return Promise.all(promises).then(
        () => {
            const updatedSourceData = getSourceDataWithUpdatedValues(
                sourceData,
                actionParams.field,
                actionParams.value
            )
            dispatch(setSaveStatus("saved"))
            return dispatch(sourceDataUpdated(updatedSourceData))
        },
        reason => {
            dispatch(setSaveStatus("failed"))
            errorResponseHandler(
                dispatch,
                sourceData,
                reason,
                "These rows cannot be updated.",
                actionParams.showErrorModal
            )
        }
    )
}

export const bulkUpdateSourceDataFieldByGroupKeyInfo = (groupKeyInfo, field, value, context) => (
    dispatch,
    getState
) => {
    const sourceDataToUpdate = getSourceDataForGroupKeyInfo(getState().sourceData.sourceData, groupKeyInfo, context)
    if (getFlagEnabled("WA-8101-bulk-status-update-fix")) {
        const cannotUpdateComponents = {}
        const canUpdateComponents = {}
        const tkStatuses = getFlagEnabled("WA-8087-custom-timekeeping-statuses")
            ? Object.values(getState().entities.timekeepingStatuses.objects)
            : statusesByUserRole

        Object.entries(sourceDataToUpdate).forEach(([componentType, tkComponentList]) => {
            tkComponentList.forEach(component => {
                if (!timekeepingDataIsEditable({ data: component, context }).canEdit) {
                    if (!(component.status in cannotUpdateComponents)) cannotUpdateComponents[component.status] = 0
                    cannotUpdateComponents[component.status] += 1
                } else {
                    if (!(componentType in canUpdateComponents)) canUpdateComponents[componentType] = []
                    canUpdateComponents[componentType].push(component)
                }
            })
        })
        if (Object.keys(cannotUpdateComponents).length) {
            const numProblems = Object.values(cannotUpdateComponents).reduce((agg, entry) => agg + entry, 0)
            let descriptionDetail = ""
            Object.keys(cannotUpdateComponents).forEach(status => {
                const statusInfo = tkStatuses.find(s => s.name === status)
                const items = cannotUpdateComponents[status].length > 1 ? "items" : "item"
                descriptionDetail += `<b>${statusInfo.label} - ${cannotUpdateComponents[status]} ${items}</b><br>`
            })
            context.createModalAction({
                title: "Issue While Updating Statuses",
                description: `You do not have permission to update ${numProblems} of the selected
                items:<br>${descriptionDetail}`,
                action: () => {
                    dispatch(bulkUpdateSourceDataField(canUpdateComponents, {}, { field: field, value: value }))
                },
                buttonClass: "blueContinueButton",
                confirmButtonText: "Update Remaining Items",
                cancelButtonText: "Cancel All Updates",
                backgroundCloseEnabled: true,
                close: () => context.createModalAction(null),
            })
        } else return dispatch(bulkUpdateSourceDataField(canUpdateComponents, {}, { field: field, value: value }))
    } else return dispatch(bulkUpdateSourceDataField(sourceDataToUpdate, {}, { field: field, value: value }))
}

export const deleteSourceData = (sourceData, filters, actionParams) => dispatch => {
    const promises = []
    const apiActionParams = getBulkActionParams(sourceData, filters, actionParams)
    dispatch(setSaveStatus("in-progress"))

    for (const resource in apiActionParams.resourceToId) {
        const args = [
            resource,
            apiActionParams.resourceToId[resource].object_ids,
            apiActionParams["current_project_id"],
        ]
        promises.push(
            new Promise((resolve, reject) => {
                API.deleteItemsById(...args)
                    .then(() => resolve())
                    .catch(result =>
                        reject({
                            resource: resource,
                            response: result.response.data,
                        })
                    )
            })
        )
    }
    return Promise.all(promises).then(
        () => {
            dispatch(setSaveStatus("saved"))
            return dispatch(sourceDataDeleted(sourceData))
        },
        reason => {
            dispatch(setSaveStatus("failed"))
            errorResponseHandler(
                dispatch,
                sourceData,
                reason,
                "These objects cannot be deleted",
                actionParams.showErrorModal
            )
        }
    )
}

const errorResponseHandler = (dispatch, sourceData, reason, message, showErrorModal) => {
    if (showErrorModal) {
        return dispatch(actionError(true, "You do not have permission to perform this action."))
    }
    const { response, resource } = reason
    const key = Object.keys(response)
    if (response && key.length && response[key[0]] && response[key[0]].length > 0) {
        if (key[0] === "detail") {
            return dispatch(actionError(true, response[key[0]]))
        }
        setSourceDataErrors(dispatch, sourceData, resource, response, key[0], message)
    } else {
        dispatch(actionError(true, null))
    }
}

const setSourceDataErrors = (dispatch, sourceData, resource, response, key, message) => {
    const sourceDataThatShouldHaveErrors = {
        [resource]: sourceData[resource].filter(row => response[key].findIndex(id => id === row.id) !== -1),
    }
    const sourceDataWithErrors = getSourceDataWithUpdatedValues(sourceDataThatShouldHaveErrors, "errors", {
        row: message,
    })
    dispatch(sourceDataUpdated(sourceDataWithErrors))
}
